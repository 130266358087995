// @ts-nocheck
import { TableActions } from '@components/Table/TableActions';
import { ColumnDef } from '@tanstack/react-table';
import React from 'react';

import { Projects } from './ProjectsTable';

export const projectsTableSetupSidebar = (
    handleDelete: any,
    handleEdit: any
): ColumnDef<Projects>[] => {
    return [
        {
            accessorKey: 'name',
            header: "Project's name",
            footer: props => props.column.id,
            filterTitle: "Project's name",
        },
        {
            accessorKey: 'status',
            header: 'Status',
            footer: props => props.column.id,
            filterTitle: 'Status',
        },
        {
            allowAsProps: true,
            accessorKey: 'actions',
            header: 'Actions',
            filterTitle: 'Actions',
            cell: data => {
                return (
                    <TableActions
                        handleDelete={() =>
                            handleDelete(data.row.getValue('id'))
                        }
                        handleEdit={() => handleEdit(data)}
                    />
                );
            },
        },
    ];
};
