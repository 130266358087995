import { DefaultBaseURL } from '@queries/urlHelpers';
import axios from 'axios';

export default async function (url: string) {
    const refreshToken = localStorage.getItem('refreshToken');
    const cognitoSub = localStorage.getItem('cognitoSub');

    const res = await fetch(url, {
        headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
    });

    // Refresh token when user get error 401 and has refresh token
    if (res.status === 401) {
        if (refreshToken) {
            axios
                .post(`${DefaultBaseURL}/auth/refresh-token`, {
                    refreshToken,
                    cognitoSub,
                })
                .then(res => {
                    localStorage.removeItem('token');
                    localStorage.removeItem('refreshToken');

                    localStorage.setItem('token', res.data.idToken);

                    window.location.reload();
                });
        } else {
            localStorage.removeItem('token');
            localStorage.removeItem('userGroup');
            localStorage.removeItem('refreshToken');
            localStorage.removeItem('cognitoSub');

            window.location.href = '/';
        }
    }

    return await res.json();
}
