import { ButtonGBI } from '@components/ButtonGBI/ButtonGBI';
import { useEditDeliverable } from '@queries/deliverables.query';
import React from 'react';
import { toast } from 'react-toastify';

type FileStatusProps = {
    fileId: string;
    status?: string;
};

export const FileStatus = ({ fileId, status = 'active' }: FileStatusProps) => {
    const isActive = status === 'active';
    const statusMutation = useEditDeliverable(fileId);

    const updateDeliverableStatus = () => {
        const newStatus = isActive ? 'old' : 'active';
        return statusMutation.mutate(
            { status: newStatus },
            {
                onSuccess: () => {
                    toast.success('Status changed successfully!');
                },
                onError: error => {
                    console.error('error', error);
                },
            }
        );
    };

    return (
        <>
            <ButtonGBI
                disabled
                variant={isActive ? 'primary' : 'inactive'}
                className="revert-disabled-styles"
            >
                {status}
            </ButtonGBI>
            <span
                className="file-status-toggle"
                onClick={() => updateDeliverableStatus()}
            >
                Set as {isActive ? 'old' : 'active'}
            </span>
        </>
    );
};
