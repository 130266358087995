import { ButtonGBI } from '@components/ButtonGBI/ButtonGBI';
import { SubmitGBI } from '@components/ButtonGBI/SubmitGBI';
import { FormGenerator } from '@components/Forms/FormGenerator';
import { verificationFormConfig } from '@pages/verification/formConfig';
import {
    useGetVerificationCode,
    useResendVerificationCode,
} from '@queries/login.query';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getElementsToValidate, isInvalidField } from 'src/validations/helpers';

import './Verification.scss';

export const EmailCodeForm = () => {
    const config = verificationFormConfig();
    const navigate = useNavigate();
    const createMutation = useGetVerificationCode();
    const resendMutation = useResendVerificationCode();
    const [searchParams] = useSearchParams();

    const searchParamsData = new URLSearchParams(searchParams);

    const email = searchParamsData.get('email')?.replace(/\s/g, '+');
    const code = searchParamsData.get('code');
    const emailCode = { email, code };

    const [isLoading, setIsLoading] = React.useState(false);
    const [showResendCode, setShowResendCode] = React.useState(false);

    const onSubmit = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
        setIsLoading(true);

        const { fieldsToValidate, inputsToValidate } =
            getElementsToValidate(config);

        if (isInvalidField(fieldsToValidate, inputsToValidate, emailCode)) {
            setIsLoading(false);
        } else {
            return createMutation.mutate(
                { email, code } as { email: string; code: string },
                {
                    onSuccess: () => {
                        toast.success('User verified successfully');
                        navigate('/');
                    },
                    onError: () => {
                        setIsLoading(false);
                    },
                }
            );
        }
    };

    const resendCode = () => {
        setIsLoading(true);
        if (email) {
            return resendMutation.mutate(email, {
                onSuccess: () => {
                    setIsLoading(false);
                    setShowResendCode(false);
                    toast.success(
                        'Code resent successfully. Please check your email'
                    );
                },
                onError: () => {
                    setIsLoading(false);
                    toast.error('Error resending code');
                },
            });
        }
    };

    useEffect(() => {
        if (!email || !code) {
            return;
        }

        if (email && code) {
            setIsLoading(true);

            return createMutation.mutate(
                { email, code } as { email: string; code: string },
                {
                    onSuccess: () => {
                        toast.success('User verified successfully');
                        navigate('/');
                    },
                    onError: () => {
                        setIsLoading(false);
                        setShowResendCode(true);
                    },
                }
            );
        }
    }, [email, code]);

    return (
        <div className="verification-code container">
            <form onSubmit={e => onSubmit(e)} noValidate>
                <FormGenerator settings={config} defaultData={emailCode} />

                <SubmitGBI
                    isDisabled={isLoading}
                    isLoading={isLoading}
                    title="Verify user"
                />

                {showResendCode && (
                    <>
                        <ButtonGBI
                            onClick={() => resendCode()}
                            disabled={isLoading}
                        >
                            Resend code
                        </ButtonGBI>
                    </>
                )}
            </form>
        </div>
    );
};
