import { ReactComponent as PauseIcon } from '@assets/pauseIcon.svg';
import { ReactComponent as PencilIcon } from '@assets/pencilIcon.svg';
import { ReactComponent as TrashIcon } from '@assets/trashIcon.svg';
import { ReactComponent as UnlockIcon } from '@assets/unlockIcon.svg';
import { CompanyStatus } from '@queries/companies.query';
import React from 'react';

type TableActionsProps = {
    handleDelete?: () => void;
    handleEdit?: () => void;
    handlePause?: () => void;
    status?: CompanyStatus;
};
export const TableActions = ({
    handleDelete,
    handleEdit,
    handlePause,
    status,
}: TableActionsProps) => {
    const getStatusToggle = () => {
        if (handlePause) {
            if (status === CompanyStatus.ACTIVE) {
                return <PauseIcon onClick={handlePause} />;
            } else {
                return <UnlockIcon onClick={handlePause} />;
            }
        }

        return null;
    };

    return (
        <div className="table-actions">
            {handleEdit && <PencilIcon onClick={handleEdit} />}

            {handleDelete && <TrashIcon onClick={handleDelete} />}

            {getStatusToggle()}
        </div>
    );
};
