import useUrlState from '@ahooksjs/use-url-state';
import { ReactComponent as CrossXIcon } from '@assets/crossX.svg';
import { ButtonGBI } from '@components/ButtonGBI/ButtonGBI';
import { SubmitGBI } from '@components/ButtonGBI/SubmitGBI';
import { getGroup } from '@utils/getGroup';
import React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router';

export const CancelSubmit = ({
    submitButtonTitle = 'Save new',
    location = 'companies',
    isLoading = false,
    isDisabled = false,
    isLastStep = true,
}: {
    submitButtonTitle?: string;
    location?: string;
    isLoading?: boolean;
    isDisabled?: boolean;
    isLastStep?: boolean;
}) => {
    const [urlState] = useUrlState();
    const location2 = useLocation();
    const params = useParams();
    const navigation = useNavigate();

    const { step } = params;

    return (
        <>
            <div
                className="cancel"
                onClick={() =>
                    navigation(`/${getGroup()}/${location}/${params.id ?? ''}`)
                }
            >
                <CrossXIcon />
            </div>

            {isLastStep ? (
                <SubmitGBI
                    isDisabled={isDisabled}
                    isLoading={isLoading}
                    title={submitButtonTitle}
                />
            ) : (
                <ButtonGBI
                    onClick={() =>
                        navigation(`/admin/projects/${Number(step) + 1}/create`)
                    }
                >
                    Next step
                </ButtonGBI>
            )}
        </>
    );
};
