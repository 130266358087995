// @ts-nocheck
import { ReactComponent as SortingArrowsIcon } from '@assets/sortingArrows.svg';
import { TableActions } from '@components/Table/TableActions';
import { UserStatus } from '@queries/users.query';
import { ColumnDef } from '@tanstack/react-table';
import { handleSort } from '@utils/sorting';
import React from 'react';

import { userTypes } from './UsersCreate';
import { Users } from './UsersTable';


export const usersTableSetup = (
    handleDelete: any,
    handleEdit: any,
    handlePause: any,
    urlState?: any,
    setUrlState?: any
): ColumnDef<Users>[] => {
    return [
        {
            accessorKey: 'id',
            header: 'ID',
            footer: props => props.column.id,
            filterTitle: 'ID',
        },
        {
            accessorKey: 'name',
            header: () => (
                <button
                    onClick={() =>
                        handleSort('firstName', urlState, setUrlState)
                    }
                >
                    Name
                    <SortingArrowsIcon />
                </button>
            ),
            cell: data => {
                const firstName = data.row.original.firstName ?? '';
                const lastName = data.row.original.lastName ?? '';
                if (firstName && lastName) {
                    return `${firstName} ${lastName}`;
                } else if (firstName) {
                    return firstName;
                } else if (lastName) {
                    return lastName;
                } else {
                    return 'Not Defined';
                }
            },
            footer: props => props.column.id,
            filterTitle: 'Name',
        },

        {
            accessorKey: 'email',
            header: 'E-mail',
            footer: props => props.column.id,
            filterTitle: 'E-mail',
        },
        {
            accessorKey: 'company',
            header: 'Company',
            cell: data => {
                const employees = data.row.original?.employees;

                if (employees?.length > 0) {
                    return employees[0].company?.name;
                }

                return null;
            },
            footer: props => props.column.id,
            filterTitle: 'Company',
        },
        {
            accessorKey: 'group',
            header: () => (
                <button
                    onClick={() => handleSort('group', urlState, setUrlState)}
                >
                    Role
                    <SortingArrowsIcon />
                </button>
            ),
            cell: data => {
                const group = data.row.original.group;
                return userTypes.find(userType => userType.id === group)
                    ?.valueToShow;
            },
            footer: props => props.column.id,
            filterTitle: 'Role',
        },
        {
            accessorKey: 'status',
            header: () => (
                <button
                    onClick={() => handleSort('status', urlState, setUrlState)}
                >
                    Status
                    <SortingArrowsIcon />
                </button>
            ),
            footer: props => props.column.id,
            filterTitle: 'Status',
        },
        {
            allowAsProps: true,
            accessorKey: 'actions',
            header: 'Actions',
            filterTitle: 'Actions',
            cell: data => {
                const status = data.row.original.status;
                let newStatus = status;

                switch (status) {
                    case UserStatus.ACTIVE:
                        newStatus = UserStatus.DISABLED;
                        break;

                    case UserStatus.DISABLED:
                        newStatus = UserStatus.ACTIVE;
                        break;

                    default:
                        newStatus = UserStatus.ACTIVE;
                }

                return (
                    <TableActions
                        handleDelete={() =>
                            handleDelete(data.row.getValue('id'))
                        }
                        handleEdit={() => handleEdit(data)}
                        handlePause={() =>
                            handlePause?.(data.row.getValue('id'), newStatus)
                        }
                        status={status}
                    />
                );
            },
        },
    ];
};
