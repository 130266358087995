import { FieldType, Type, settingsType } from '@components/Forms/FormGenerator';

export const otherFormConfig = (): settingsType[] => [
    {
        config: {
            fieldType: FieldType.Input,
            type: Type.Text,
            name: 'name',
            title: 'Name',
        },
        validation: {
            required: true,
            minLength: 3,
            errorMessage: 'Name must be at least 3 characters long',
        },
        global: {
            viewMode: {
                boldLabel: true,
                featuredData: true,
            },
        },
    },
    {
        config: {
            fieldType: FieldType.Textarea,
            type: Type.Textarea,
            name: 'additionalComment',
            title: 'Additional comments',
        },
        global: {
            viewMode: {
                inlineLabel: true,
                inlineData: true,
                boldLabel: true,
                boldData: false,
            },
        },
    },
];
