import { SubmitGBI } from '@components/ButtonGBI/SubmitGBI';
import React from 'react';

interface AddNewCommentProps {
    commentText: string;
    setCommentText: (text: string) => void;
    isLoading: boolean;
    addComment: () => void;
}

export const AddNewComment = ({
    commentText,
    setCommentText,
    isLoading,
    addComment,
}: AddNewCommentProps) => {
    return (
        <div className="add-new-comment">
            <h5>Add new comment:</h5>
            <textarea
                value={commentText}
                onChange={e => setCommentText(e.target.value)}
                name="comment"
                id="comment"
                cols={3}
                rows={3}
            />
            <div className="submit-wrapper">
                <SubmitGBI
                    isDisabled={isLoading}
                    isLoading={isLoading}
                    onClick={() => addComment()}
                    title="Add comment"
                ></SubmitGBI>
            </div>
        </div>
    );
};
