import { FieldType, Type, settingsType } from '@components/Forms/FormGenerator';

export const utilityFormConfig = (): settingsType[] => [
    {
        config: {
            fieldType: FieldType.Input,
            type: Type.Text,
            name: 'name',
            title: 'Name',
        },
        validation: {
            required: true,
            minLength: 3,
            errorMessage: 'Name must be at least 3 characters long',
        },
        global: {
            viewMode: {
                boldLabel: true,
                featuredData: true,
            },
        },
    },
    {
        config: {
            fieldType: FieldType.Input,
            type: Type.Text,
            name: 'meter',
            title: 'Meter',
        },
        global: {
            viewMode: {
                inlineLabel: true,
                inlineData: true,
                boldLabel: true,
                boldData: false,
            },
        },
    },
    {
        config: {
            fieldType: FieldType.Input,
            type: Type.Text,
            name: 'disconnect',
            title: 'Disconnect',
        },
        global: {
            viewMode: {
                inlineLabel: true,
                inlineData: true,
                boldLabel: true,
                boldData: false,
            },
        },
    },
    {
        config: {
            fieldType: FieldType.Input,
            type: Type.Text,
            name: 'disconnectNearServiceEntrance',
            title: 'Disconnect near service entrance',
        },
        global: {
            viewMode: {
                inlineLabel: true,
                inlineData: true,
                boldLabel: true,
                boldData: false,
            },
        },
    },
    {
        config: {
            fieldType: FieldType.Input,
            type: Type.Text,
            name: 'meterNearServiceEntrance',
            title: 'Meter near service entrance',
        },
        global: {
            viewMode: {
                inlineLabel: true,
                inlineData: true,
                boldLabel: true,
                boldData: false,
            },
        },
    },
    {
        config: {
            fieldType: FieldType.Input,
            type: Type.Text,
            name: 'required24HourAccess',
            title: 'Required 24 hour access',
        },
        global: {
            viewMode: {
                inlineLabel: true,
                inlineData: true,
                boldLabel: true,
                boldData: false,
            },
        },
    },
    {
        config: {
            fieldType: FieldType.Input,
            type: Type.Text,
            name: 'tapsAllowed',
            title: 'Taps allowed',
        },
        global: {
            viewMode: {
                inlineLabel: true,
                inlineData: true,
                boldLabel: true,
                boldData: false,
            },
        },
    },
    {
        config: {
            fieldType: FieldType.Input,
            type: Type.Textarea,
            name: 'additionalComment',
            title: 'Additional comment',
        },
        global: {
            viewMode: {
                inlineLabel: true,
                inlineData: true,
                boldLabel: true,
                boldData: false,
            },
        },
    },
];
