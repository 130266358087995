import { ReactComponent as ProfileImage } from '@assets/profileImage.svg';
import { ReactComponent as ProfileLogoutIcon } from '@assets/profileLogoutIcon.svg';
import React from 'react';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';

import { queryClient } from '../../../App';


export const SidebarFooter = () => {
    const navigate = useNavigate();
    const handleLogout = async () => {
        localStorage.removeItem('token');
        localStorage.removeItem('userGroup');
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('cognitoSub');

        await queryClient.invalidateQueries({ queryKey: ['userInfo'] });

        toast('Logged out successfully', { type: 'success' });

        navigate('/');
    };

    return (
        <div className="footer-part">
            <div className="user-info d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center column-gap-2">
                    <ProfileImage />
                    <p>My profile</p>
                </div>

                <ProfileLogoutIcon onClick={() => handleLogout()} />
            </div>
        </div>
    );
};
