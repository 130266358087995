import useUrlState from '@ahooksjs/use-url-state';
import fetchGBI from '@queries/fetchGBI';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';

import {
    AdminBaseURL,
    DefaultBaseURL,
    getMutationUrlParams,
} from './urlHelpers';

export enum ProjectStatus {
    IN_PROGRESS = 'in_progress',
    DONE = 'done',
    NOT_DONE = 'not_done',
    OPEN = 'open',
    BLOCKED = 'blocked',
}

export interface INewGuideline {
    companyRequirementId?: string;
    utilityRequirementId?: string;
    ahjRequirementId?: string;
    otherRequirementId?: string;
}

export interface INewProject {
    name?: string;
    company?: string;
    companyId?: string;
    description?: string;
    products?: string[];
    projectAge?: string;
    status?: ProjectStatus;
    dueDate?: string;
    companyRequirementId?: string;
    utilityRequirementId?: string;
    ahjRequirementId?: string;
    otherRequirementId?: string;
    files?: string[];
    projectDetail?: {
        customerName?: string;
        address?: string;
        utilityCompany?: string;
        meterNumber?: string;
        utilityAccountNumber?: string;
        utilityAddress?: string;
        utilityCity?: string;
        pictureOfMainPanelWithDeadFrontOff?: string;
        zipFileOfHousePhotos?: string;
    };
}

export const useCreateProject = () => {
    const client = useQueryClient();

    return useMutation({
        mutationFn: async (newProject: INewProject) => {
            const { data } = await axios.post(
                DefaultBaseURL + '/projects',
                newProject,
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem(
                            'token'
                        )}`,
                    },
                }
            );
            return {
                data,
            };
        },
        onSuccess: () => {
            return client.invalidateQueries({ queryKey: ['projects'] });
        },
    });
};

export const useEditProject = (id: string) => {
    const client = useQueryClient();

    return useMutation({
        mutationFn: async (projectNewData: INewProject) => {
            const {
                data: { project },
            } = await axios.patch(
                DefaultBaseURL + '/projects/' + id,
                projectNewData,
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem(
                            'token'
                        )}`,
                    },
                }
            );
            return {
                project,
            };
        },
        onSuccess: () => {
            return client.invalidateQueries({ queryKey: ['projects'] });
        },
    });
};

export const useDeleteProject = () => {
    const client = useQueryClient();

    return useMutation({
        mutationFn: async (projectId: string) => {
            await axios.delete(DefaultBaseURL + `/projects/${projectId}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
            });
        },
        onSuccess: () => {
            return client.invalidateQueries({ queryKey: ['projects'] });
        },
    });
};

export const useGetProjects = (filter?: Partial<{ [x: string]: any }>) => {
    const [urlState] = useUrlState();
    const group = localStorage.getItem('userGroup');
    const url = group === 'admin' ? AdminBaseURL : DefaultBaseURL;
    const href = getMutationUrlParams(
        `${url}/projects`,
        filter ? filter : urlState,
        'projects'
    ).href;

    return useQuery({
        queryKey: ['projects', href, group],
        queryFn: () => fetchGBI(href),
    });
};

export const useGetProject = (projectId: string) =>
    useQuery({
        queryKey: ['projects', projectId],
        queryFn: () => fetchGBI(DefaultBaseURL + `/projects/${projectId}`),
    });
