import { FormGenerator } from '@components/Forms/FormGenerator';
import { Loader } from '@components/Loader/Loader';
import { NavigationHeader } from '@components/NavigationHeader/NavigationHeader';
import { Tabs } from '@components/Tabs/Tabs';
import { ProjectsNavigation } from '@pages/projects/header/ProjectsNavigation';
import { useGetAllCompanies } from '@queries/companies.query';
import React from 'react';

import './Projects.scss';

export const ProjectsCreatePart1 = ({
    companyId,
    products,
    isLastStep,
    config,
    isLoading,
}: {
    companyId: string;
    products: any;
    isLastStep: boolean;
    config: any;
    isLoading: boolean;
}) => {
    const { status } = useGetAllCompanies({
        ['filter.status']: 'active',
    });

    const tabsConfig = [
        {
            title: 'Overview',
            content:
                status === 'success' ? (
                    <FormGenerator
                        settings={config}
                        defaultData={{
                            company: { id: companyId },
                            products,
                        }}
                        formTitle="General details"
                    />
                ) : (
                    <Loader />
                ),
        },
    ];

    return (
        <>
            <NavigationHeader detailTitle="Creating new project">
                <ProjectsNavigation
                    submitButtonTitle="Save new project"
                    isLoading={isLoading}
                    isDisabled={isLoading}
                    isLastStep={isLastStep}
                />
            </NavigationHeader>

            <div className="row">
                <div className="col-lg-12">
                    <Tabs config={tabsConfig} />
                </div>
            </div>
        </>
    );
};
