import { getGroup } from '@utils/getGroup';
import { useEffect } from 'react';
import { useNavigate } from 'react-router';

import './Projects.scss';

export const ProjectsCreateRedirect = () => {
    const navigate = useNavigate();

    useEffect(() => {
        navigate(`/${getGroup()}/projects/1/create`);
    }, []);

    return null;
};
