import { Deliverable } from '@components/Deliverables/Deliverable';
import { FormGenerator } from '@components/Forms/FormGenerator';
import { guidelinesSelectFormConfig } from '@components/Guidelines/guidelinesConfig';
import { Loader } from '@components/Loader/Loader';
import { NavigationHeader } from '@components/NavigationHeader/NavigationHeader';
import { Tabs } from '@components/Tabs/Tabs';
import { isPlansSelected } from '@pages/projects/ProjectsDetails';
import { ProjectsSidebarDetailsEdit } from '@pages/projects/sidebar/ProjectsSidebarDetailsEdit';
import { useGetAllCompanies } from '@queries/companies.query';
import { useGetFilesByResourceId } from '@queries/files.query';
import {
    useAttachJurisdiction,
    useGetAllJurisdictions,
} from '@queries/guidelines.query';
import {
    INewGuideline,
    INewProject,
    useEditProject,
    useGetProject,
} from '@queries/projects.query';
import { getGroup } from '@utils/getGroup';
import { getPostMutationObject } from '@utils/getPostMutationObject';
import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { toast } from 'react-toastify';

import {
    getElementsToValidate,
    isInvalidField,
} from '../../validations/helpers';
import './Projects.scss';
import {
    allDetailsProjectFormConfig,
    projectsFormConfig,
} from './ProjectsFormConfig';
import { ProjectsNavigation } from './header/ProjectsNavigation';

export const ProjectsEdit = () => {
    const params = useParams();
    const { id } = params;
    const navigate = useNavigate();
    const { status, data } = useGetProject(id ?? '');
    const projectsMutation = useEditProject(id ?? '');
    const jurisdictionAttachMutation = useAttachJurisdiction();
    const { data: companiesData } = useGetAllCompanies({
        ['filter.status']: 'active',
    });
    const { data: filesData } = useGetFilesByResourceId(params.id ?? '');

    const companyDropdown = companiesData?.data?.map((company: any) => {
        return {
            id: company.id,
            valueToShow: company.name,
        };
    });

    let projectData = data;
    const [isLoading, setIsLoading] = useState(false);
    const [companyId, setCompanyId] = useState(data?.companyId);

    const { data: companyGuidelines } = useGetAllJurisdictions('company');
    const { data: otherGuidelines } = useGetAllJurisdictions('other');
    const { data: ahjGuidelines } = useGetAllJurisdictions('ahj');
    const { data: utilityGuidelines } = useGetAllJurisdictions('utility');

    const companyGuidelinesData = companyGuidelines?.data?.map(
        (guideline: any) => {
            return {
                id: guideline.id,
                valueToShow: guideline.name,
            };
        }
    );

    const otherGuidelinesData = otherGuidelines?.data?.map((guideline: any) => {
        return {
            id: guideline.id,
            valueToShow: guideline.name,
        };
    });

    const ahjGuidelinesData = ahjGuidelines?.data?.map((guideline: any) => {
        return {
            id: guideline.id,
            valueToShow: guideline.name,
        };
    });

    const utilityGuidelinesData = utilityGuidelines?.data?.map(
        (guideline: any) => {
            return {
                id: guideline.id,
                valueToShow: guideline.name,
            };
        }
    );

    const [selectedCompanyGuideline, setSelectedCompanyGuideline] =
        React.useState<any>(undefined);

    const [selectedOtherGuideline, setSelectedOtherGuideline] =
        React.useState<any>(undefined);

    const [selectedAhjGuideline, setSelectedAhjGuideline] =
        React.useState<any>(undefined);

    const [selectedUtilityGuideline, setSelectedUtilityGuideline] =
        React.useState<any>(undefined);

    const setCompany = (id: string) => {
        setCompanyId(id);
        projectData && (projectData.companyId = id);
    };

    const hasNonEmptyProperty = (obj: any) => {
        for (let key in obj) {
            if (obj[key] !== null && obj[key] !== '') return true;
        }
        return false;
    };

    const projectConfig = projectsFormConfig(companyDropdown, setCompany);

    const allDetailsConfig = allDetailsProjectFormConfig(
        undefined,
        undefined,
        undefined,
        undefined,
        id
    );

    const guidelinesConfig = guidelinesSelectFormConfig(
        companyGuidelinesData,
        setSelectedCompanyGuideline,
        otherGuidelinesData,
        setSelectedOtherGuideline,
        ahjGuidelinesData,
        setSelectedAhjGuideline,
        utilityGuidelinesData,
        setSelectedUtilityGuideline
    );

    const onSubmit = (e: any) => {
        e.preventDefault();
        setIsLoading(true);

        const formData: INewProject = getPostMutationObject(e, projectConfig);

        const allDetailsData = getPostMutationObject(e, allDetailsConfig);
        const guidelines: INewGuideline = getPostMutationObject(
            e,
            guidelinesConfig
        );
        if (
            hasNonEmptyProperty(allDetailsData) &&
            data?.products.find((product: any) => product.name === 'Plans')
        ) {
            formData.projectDetail = allDetailsData;
            formData.projectDetail.pictureOfMainPanelWithDeadFrontOff =
                '-main-panel';
            formData.projectDetail.zipFileOfHousePhotos = '-zip-file-house';
        }

        if (formData) {
            formData.companyId = companyId;
        }

        if (params.id) {
            jurisdictionAttachMutation.mutate(
                {
                    projectId: params.id ?? '',
                    companyRequirementId: guidelines?.companyRequirementId,
                    utilityRequirementId: guidelines?.utilityRequirementId,
                    ahjRequirementId: guidelines?.ahjRequirementId,
                    otherRequirementId: guidelines?.otherRequirementId,
                },
                {
                    onSuccess: () => {
                        toast.success('Guideline edited successfully');
                    },
                }
            );
        }

        const { fieldsToValidate, inputsToValidate } = getElementsToValidate([
            ...projectConfig,
            ...allDetailsConfig,
        ]);

        if (
            isInvalidField(fieldsToValidate, inputsToValidate, {
                ...formData,
                ...allDetailsData,
                products: projectData?.products,
            })
        ) {
            // here can be a generic toast error message
            // toast.error('Please fill all required fields');
            setIsLoading(false);
        } else {
            delete formData.products;
            delete formData.company;
            delete formData.files;

            return projectsMutation.mutate(formData, {
                onSuccess: () => {
                    toast.success('Project edited successfully');
                    navigate(`/${getGroup()}/projects/${params.id}`);
                },
                onError: () => {
                    setIsLoading(false);
                },
            });
        }
    };

    const tabsConfig = [
        {
            title: 'Overview',
            content:
                status === 'success' ? (
                    <FormGenerator
                        settings={projectConfig}
                        defaultData={{
                            ...projectData,
                            files: filesData,
                        }}
                        formTitle="General details"
                    />
                ) : (
                    <Loader />
                ),
        },
        ...(isPlansSelected(projectData)
            ? [
                  {
                      title: 'All details',
                      content:
                          status === 'success' ? (
                              <FormGenerator
                                  settings={allDetailsConfig}
                                  defaultData={{
                                      ...projectData?.projectDetail,
                                  }}
                              />
                          ) : (
                              <Loader />
                          ),
                  },
              ]
            : []),
        {
            title: 'Deliverable',
            content: <Deliverable products={data?.products} />,
        },
    ];

    return (
        <div className="projects-edit content-wrapper with-header">
            <form onSubmit={e => onSubmit(e)} noValidate>
                <NavigationHeader
                    detailTitle={projectData?.name ?? "Project's details"}
                >
                    <ProjectsNavigation
                        submitButtonTitle="Save project"
                        isDisabled={isLoading}
                        isLoading={isLoading}
                        isLastStep={true}
                    />
                </NavigationHeader>

                <div className="row">
                    <div className="col-lg-6">
                        <Tabs config={tabsConfig} />
                    </div>
                    <ProjectsSidebarDetailsEdit />
                </div>
            </form>
        </div>
    );
};
