import { ReactComponent as JurisdictionIcon } from '@assets/jurisdictionIcon.svg';
import { ReactComponent as ProjectsIcon } from '@assets/projectsIcon.svg';
import { ReactComponent as SettingsIcon } from '@assets/settingsIcon.svg';
import { ReactComponent as TasksIcon } from '@assets/tasksIcon.svg';

export const designerMenu = [
    {
        name: 'Projects',
        icon: ProjectsIcon,
        path: '/designer/projects',
    },
    {
        name: 'Tasks',
        icon: TasksIcon,
        path: '/designer/tasks',
    },
    {
        name: 'Jurisdiction',
        icon: JurisdictionIcon,
        path: '/designer/jurisdiction',
    },
    {
        name: 'Settings',
        icon: SettingsIcon,
        path: '/designer/settings/edit',
    },
];
