import { formatDate } from '@utils/utils';
import classNames from 'classnames';
import React from 'react';

import './Comments.scss';

interface DisplayCommentsProps {
    commentsData: any;
    className?: string;
}

export const DisplayComments = ({
    commentsData,
    className,
}: DisplayCommentsProps) => {
    return (
        <div className={classNames('all-comments', className)}>
            {commentsData?.length > 0 ? (
                commentsData?.map((comment: any) => {
                    return (
                        <div key={comment.id} className="single-comment">
                            <div className="author-date">
                                <p className="author">
                                    {comment.user.firstName}
                                </p>
                                <p className="date">
                                    {formatDate(comment.createdAt)}
                                </p>
                            </div>

                            <p className="comment">{comment.text}</p>
                        </div>
                    );
                })
            ) : (
                <h5>No comments added yet</h5>
            )}
        </div>
    );
};
