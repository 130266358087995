import { TabsContent } from '@components/Tabs/TabsContent';
import { TabsHeader } from '@components/Tabs/TabsHeader';
import React from 'react';

import './Tabs.scss';


export const Tabs = ({
    config,
}: {
    config: Array<{ title: string; content: any }>;
}) => {
    return (
        <div className="gbi-tabs">
            <TabsHeader config={config} />
            <TabsContent config={config} />
        </div>
    );
};
