import { FieldType, Type, settingsType } from '@components/Forms/FormGenerator';

export const projectsFormConfig = (
    companySelectData?: { id: string; valueToShow: string }[],
    onCompanyChange?: (data: any) => void,
    onProductsChange?: (data: any) => void,
    onSelectedFile?: (data: File[]) => void,
    selectedFile?: File[],
    resourceId?: string
): settingsType[] => [
    {
        config: {
            fieldType: FieldType.Input,
            type: Type.Text,
            name: 'name',
            title: "Project's name",
        },
        validation: {
            required: true,
            minLength: 3,
            errorMessage: "Project's name must be at least 3 characters long",
        },
        global: {
            viewMode: {
                boldLabel: true,
                featuredData: true,
            },
        },
    },
    {
        config: {
            fieldType: FieldType.Textarea,
            type: Type.Text,
            name: 'description',
            title: 'Description',
        },
        global: {
            viewMode: {
                boldLabel: true,
            },
        },
    },
    {
        config: {
            fieldType: FieldType.Select,
            type: Type.Text,
            name: 'company',
            title: 'Company',
            selectData: companySelectData,
            onChange: (data: any) => onCompanyChange?.(data),
        },
        global: {
            viewMode: {
                boldLabel: true,
            },
        },
    },
    {
        config: {
            fieldType: FieldType.Hr,
            type: Type.Text,
            name: 'moreOptions',
            title: 'More options',
        },
    },
    {
        config: {
            fieldType: FieldType.Input,
            type: Type.DatetimeLocal,
            name: 'dueDate',
            title: 'Due date',
        },
        global: {
            viewMode: {
                inlineLabel: true,
                inlineData: true,
                boldData: true,
            },
        },
    },
    {
        config: {
            fieldType: FieldType.Checkbox,
            type: Type.Checkbox,
            name: 'products',
            title: 'Products',
            onChange: (data: any) => onProductsChange?.(data),
        },
        validation: {
            required: true,
            minLength: 1,
            errorMessage: 'At least 1 product must be selected',
        },
        global: {
            viewMode: {
                disabled: true,
            },
            editMode: {
                disabled: true,
            },
        },
    },
    {
        config: {
            fieldType: FieldType.Hr,
            type: Type.Text,
            name: 'empty',
            title: '',
        },
    },
    {
        config: {
            fieldType: FieldType.FilesUpload,
            type: Type.Text,
            name: 'files',
            title: 'Site survey files',
            resourceId: resourceId,
            onChange: (data: File[]) => onSelectedFile?.(data),
            files: selectedFile,
        },
        global: {
            viewMode: {
                boldLabel: true,
            },
        },
    },
];

export const allDetailsProjectFormConfig = (
    onZipHouseFiles?: (data: File[]) => void,
    zipHouseFiles?: File[],
    onMainPanelFiles?: (data: File[]) => void,
    mainPanelFiles?: File[],
    resourceId?: string
): settingsType[] => [
    {
        config: {
            fieldType: FieldType.Hr,
            type: Type.Text,
            name: '',
            title: 'Customer information',
        },
    },
    {
        config: {
            fieldType: FieldType.Input,
            type: Type.Text,
            name: 'customerName',
            title: 'Customer Name',
        },
        global: {
            viewMode: {
                boldLabel: true,
            },
        },
        validation: {
            required: true,
        },
    },
    {
        config: {
            fieldType: FieldType.Input,
            type: Type.Text,
            name: 'address',
            title: 'Address / GPS Coords',
        },
        global: {
            viewMode: {
                boldLabel: true,
            },
        },
        validation: {
            required: true,
        },
    },
    {
        config: {
            fieldType: FieldType.Input,
            type: Type.Text,
            name: 'utilityCompany',
            title: 'Utility company',
        },
        global: {
            viewMode: {
                boldLabel: true,
            },
        },
        validation: {
            required: true,
        },
    },
    {
        config: {
            fieldType: FieldType.Input,
            type: Type.Text,
            name: 'meterNumber',
            title: 'Meter #',
        },
        global: {
            viewMode: {
                boldLabel: true,
            },
        },
        validation: {
            required: false,
        },
    },
    {
        config: {
            fieldType: FieldType.Input,
            type: Type.Text,
            name: 'utilityAccountNumber',
            title: 'Utility account #',
        },
        global: {
            viewMode: {
                boldLabel: true,
            },
        },
        validation: {
            required: false,
        },
    },
    {
        config: {
            fieldType: FieldType.Input,
            type: Type.Text,
            name: 'jurisdictionWeAreSubmittingPlansTo',
            title: 'Jurisdiction we are submitting plans to',
        },
        global: {
            viewMode: {
                boldLabel: true,
            },
        },
        validation: {
            required: false,
        },
    },
    {
        config: {
            fieldType: FieldType.Input,
            type: Type.Text,
            name: 'sla',
            title: 'SLA (timeline for project return)',
        },
        global: {
            viewMode: {
                boldLabel: true,
            },
        },
        validation: {
            required: false,
        },
    },

    // Project information

    {
        config: {
            fieldType: FieldType.Hr,
            type: Type.Text,
            name: '',
            title: 'Project information',
        },
    },
    {
        config: {
            fieldType: FieldType.Input,
            type: Type.Text,
            name: 'layout',
            title: 'Layout (Approved by Customer )',
        },
        global: {
            viewMode: {
                boldLabel: true,
            },
        },
        validation: {
            required: false,
        },
    },
    {
        config: {
            fieldType: FieldType.Input,
            type: Type.Text,
            name: 'module',
            title: 'Module (model and manufacturer)',
        },
        global: {
            viewMode: {
                boldLabel: true,
            },
        },
        validation: {
            required: true,
        },
    },
    {
        config: {
            fieldType: FieldType.Input,
            type: Type.Text,
            name: 'inverterType',
            title: 'Inverter type and amount (model and manufacturer) ',
        },
        global: {
            viewMode: {
                boldLabel: true,
            },
        },
        validation: {
            required: true,
        },
    },
    {
        config: {
            fieldType: FieldType.Input,
            type: Type.Text,
            name: 'batteryInformation',
            title: 'Battery Information (model and manufacturer)',
        },
        global: {
            viewMode: {
                boldLabel: true,
            },
        },
        validation: {
            required: true,
        },
    },
    {
        config: {
            fieldType: FieldType.Input,
            type: Type.Text,
            name: 'rackingSystemInformation',
            title: 'Racking system information (model and manufacturer )',
        },
        global: {
            viewMode: {
                boldLabel: true,
            },
        },
        validation: {
            required: true,
        },
    },
    {
        config: {
            fieldType: FieldType.Input,
            type: Type.Text,
            name: 'bosEquipmentDesired',
            title: 'BOS Equipment desired (manufacturer)',
        },
        global: {
            viewMode: {
                boldLabel: true,
            },
        },
        validation: {
            required: true,
        },
    },
    {
        config: {
            fieldType: FieldType.FilesUpload,
            type: Type.Text,
            name: 'zipFileOfHousePhotos',
            title: 'Zip file of house photos',
            resourceId: resourceId ? `${resourceId}-zip-file-house` : undefined,
            files: zipHouseFiles,
            onChange: (data: File[]) => onZipHouseFiles?.(data),
        },
        global: {
            viewMode: {
                boldLabel: true,
            },
        },
        validation: {
            required: true,
        },
    },

    // Structural

    {
        config: {
            fieldType: FieldType.Hr,
            type: Type.Text,
            name: '',
            title: 'Structural',
        },
    },
    {
        config: {
            fieldType: FieldType.Input,
            type: Type.Text,
            name: 'tilt',
            title: 'Tilt',
        },
        global: {
            viewMode: {
                boldLabel: true,
            },
        },
        validation: {
            required: false,
        },
    },
    {
        config: {
            fieldType: FieldType.Input,
            type: Type.Text,
            name: 'azimuth',
            title: 'Azimuth',
        },
        global: {
            viewMode: {
                boldLabel: true,
            },
        },
        validation: {
            required: false,
        },
    },
    {
        config: {
            fieldType: FieldType.Input,
            type: Type.Text,
            name: 'roofingType',
            title: 'Roofing Type E.g. Comp shingle, W Tile Roof',
        },
        global: {
            viewMode: {
                boldLabel: true,
            },
        },
        validation: {
            required: false,
        },
    },
    {
        config: {
            fieldType: FieldType.Input,
            type: Type.Text,
            name: 'roofHeight',
            title: 'Roof Height: 1,2, or 3 story',
        },
        global: {
            viewMode: {
                boldLabel: true,
            },
        },
        validation: {
            required: false,
        },
    },
    {
        config: {
            fieldType: FieldType.Input,
            type: Type.Text,
            name: 'onCenterRafterSpacing',
            title: 'On Center Rafter Spacing',
        },
        global: {
            viewMode: {
                boldLabel: true,
            },
        },
        validation: {
            required: false,
        },
    },
    {
        config: {
            fieldType: FieldType.Input,
            type: Type.Text,
            name: 'rafterSizeAndType',
            title: 'Rafter size and type (e.g. 2x4 prefab truss)',
        },
        global: {
            viewMode: {
                boldLabel: true,
            },
        },
        validation: {
            required: false,
        },
    },
    {
        config: {
            fieldType: FieldType.Input,
            type: Type.Text,
            name: 'maxRafterSpanAndDesiredPenetrationSpacing',
            title: 'Max rafter span and desired penetration spacing',
        },
        global: {
            viewMode: {
                boldLabel: true,
            },
        },
        validation: {
            required: false,
        },
    },

    // Electrical

    {
        config: {
            fieldType: FieldType.Hr,
            type: Type.Text,
            name: '',
            title: 'Electrical',
        },
    },
    {
        config: {
            fieldType: FieldType.Input,
            type: Type.Text,
            name: 'interconnectionMethod',
            title: 'Interconnection method',
        },
        global: {
            viewMode: {
                boldLabel: true,
            },
        },
        validation: {
            required: false,
        },
    },
    {
        config: {
            fieldType: FieldType.Input,
            type: Type.Text,
            name: 'wholeHomePartialBackup',
            title: 'Whole Home / Partial Backup (backup loads)',
        },
        global: {
            viewMode: {
                boldLabel: true,
            },
        },
        validation: {
            required: true,
            errorMessage: "Please put N/A if there's no battery",
        },
    },
    {
        config: {
            fieldType: FieldType.Input,
            type: Type.Text,
            name: 'mainPanelBusRating',
            title: 'Main panel bus rating / main panel rating',
        },
        global: {
            viewMode: {
                boldLabel: true,
            },
        },
        validation: {
            required: true,
        },
    },
    {
        config: {
            fieldType: FieldType.FilesUpload,
            type: Type.Text,
            name: 'pictureOfMainPanelWithDeadFrontOff',
            title: 'Picture of main panel with dead front off',
            resourceId: resourceId ? `${resourceId}-main-panel` : undefined,
            files: mainPanelFiles,
            onChange: (data: File[]) => onMainPanelFiles?.(data),
        },
        global: {
            // viewMode: {
            //     boldLabel: true,
            // },
        },
        validation: {
            required: true,
        },
    },
    {
        config: {
            fieldType: FieldType.Input,
            type: Type.Text,
            name: 'locationOfAllSubPanels',
            title: 'Location of all sub panels ',
        },
        global: {
            viewMode: {
                boldLabel: true,
            },
        },
        validation: {
            required: true,
        },
    },
    {
        config: {
            fieldType: FieldType.Input,
            type: Type.Text,
            name: 'conduitTypeUsed',
            title: 'Conduit type used (EMT or PVC)',
        },
        global: {
            viewMode: {
                boldLabel: true,
            },
        },
        validation: {
            required: false,
        },
    },
    {
        config: {
            fieldType: FieldType.Input,
            type: Type.Text,
            name: 'scopeOfWorkAdditionalToSolarInstall',
            title: 'Scope of Work additional to solar install',
        },
        global: {
            viewMode: {
                boldLabel: true,
            },
        },
        validation: {
            required: false,
        },
    },

    {
        config: {
            fieldType: FieldType.Textarea,
            type: Type.Textarea,
            name: 'additionalComments',
            title: 'Additional comments',
        },
        global: {
            viewMode: {
                boldLabel: true,
            },
        },
        validation: {
            required: false,
        },
    },
];
