import { CompanyEdit } from '@pages/jurisdiction/Company/CompanyEdit';
import { Jurisdiction } from '@pages/jurisdiction/Jurisdiction';
import { JurisdictionDetails } from '@pages/jurisdiction/JurisdictionDetails';


export const jurisdictionManagerRoutes = [
    {
        path: '/manager/jurisdiction',
        Component: Jurisdiction,
    },
    {
        path: '/manager/jurisdiction/:type/:id',
        Component: JurisdictionDetails,
    },

    // Company
    {
        path: '/manager/jurisdiction/company/:id/edit',
        Component: CompanyEdit,
    },
];
