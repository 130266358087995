import { ProjectComments } from '@components/Comments/ProjectComments';
import { Deliverable } from '@components/Deliverables/Deliverable';
import { FormGenerator } from '@components/Forms/FormGenerator';
import { Loader } from '@components/Loader/Loader';
import { NavigationHeader } from '@components/NavigationHeader/NavigationHeader';
import { Tabs } from '@components/Tabs/Tabs';
import { ProjectsSidebarDetailsEdit } from '@pages/projects/sidebar/ProjectsSidebarDetailsEdit';
import { useGetAllCompanies } from '@queries/companies.query';
import { useDeleteProject, useGetProject } from '@queries/projects.query';
import { getGroup } from '@utils/getGroup';
import React from 'react';
import { useNavigate, useParams } from 'react-router';

import './Projects.scss';
import {
    allDetailsProjectFormConfig,
    projectsFormConfig,
} from './ProjectsFormConfig';
import { ProjectsNavigation } from './header/ProjectsNavigation';

export const isPlansSelected = (data: any) =>
    !!data?.products?.find((product: any) => product.name === 'Plans');

export const ProjectsDetails = () => {
    const params = useParams();
    const navigate = useNavigate();
    const projectId = params.id ?? '';
    const { status, data } = useGetProject(projectId);
    const { data: companiesData } = useGetAllCompanies({
        ['filter.status']: 'active',
    });

    const deleteProjectMutation = useDeleteProject();
    const allDetailsConfig = allDetailsProjectFormConfig(
        undefined,
        undefined,
        undefined,
        undefined,
        projectId
    );

    const handleDelete = () => {
        deleteProjectMutation.mutate(projectId, {
            onSuccess: () => {
                navigate(`/${getGroup()}/projects`);
            },
        });
    };

    const companyDropdown = companiesData?.data?.map((company: any) => {
        return {
            id: company.id,
            valueToShow: company.name,
        };
    });

    const tabsConfig = [
        {
            title: 'Overview',
            content: (
                <form>
                    {status === 'success' ? (
                        <FormGenerator
                            settings={projectsFormConfig(companyDropdown)}
                            defaultData={data}
                        />
                    ) : (
                        <Loader />
                    )}
                </form>
            ),
        },
        ...(isPlansSelected(data)
            ? [
                  {
                      title: 'All details',
                      content: (
                          <FormGenerator
                              settings={allDetailsConfig}
                              defaultData={{ ...data?.projectDetail }}
                          />
                      ),
                  },
              ]
            : []),
        {
            title: 'Deliverable',
            content: <Deliverable products={data?.products} />,
        },
        {
            title: 'Comments',
            content: <ProjectComments />,
        },
    ];

    return (
        <div className="projects-details content-wrapper with-header">
            <NavigationHeader
                detailTitle={data?.name ?? "Project's details"}
                onBack={() => navigate(`/${getGroup()}/projects`)}
            >
                <ProjectsNavigation
                    defaultStatus={data?.status}
                    handleDelete={handleDelete}
                />
            </NavigationHeader>

            <div className="row">
                <div className="col-lg-6">
                    <Tabs config={tabsConfig} />
                </div>
                <ProjectsSidebarDetailsEdit />
            </div>
        </div>
    );
};
