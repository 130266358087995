import fetchGBI from '@queries/fetchGBI';
import { IResourceFile } from '@queries/files.query';
import {
    UseQueryResult,
    useMutation,
    useQuery,
    useQueryClient,
} from '@tanstack/react-query';
import axios from 'axios';

import { DefaultBaseURL } from './urlHelpers';

export type IDeliverableFile = {
    file: IResourceFile;
    id: string;
    name: string;
    resourceId: string;
    s3Key: string;
    status: string;
};

// Attach / create
export const useAttachDeliverable = () => {
    const client = useQueryClient();

    return useMutation({
        mutationFn: async (deliverable: {
            // todo remove projectId when taskId is ready on backend
            taskId?: string;
            projectId?: string;
            fileId: string;
            type: string;
        }) => {
            const {
                // todo change project to something
                data: { project },
            } = await axios.post(
                DefaultBaseURL + '/deliverables/attach',
                deliverable,
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem(
                            'token'
                        )}`,
                    },
                }
            );
            return {
                project,
            };
        },
        onSuccess: () => {
            return client.invalidateQueries({ queryKey: ['deliverable'] });
        },
    });
};

// Patch / edit
export const useEditDeliverable = (deliverableId: string) => {
    const client = useQueryClient();

    return useMutation({
        mutationFn: async (deliverableData: { status: string }) => {
            const {
                data: { deliverable },
            } = await axios.patch(
                DefaultBaseURL + '/deliverables/' + deliverableId,
                deliverableData,
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem(
                            'token'
                        )}`,
                    },
                }
            );
            return {
                deliverable,
            };
        },
        onSuccess: () => {
            return client.invalidateQueries({
                queryKey: ['deliverable'],
            });
        },
    });
};

// Get / read
export const useGetDeliverableByProjectId = (
    projectId: string
): UseQueryResult<IDeliverableFile[]> =>
    useQuery({
        queryKey: ['deliverable', projectId],
        queryFn: () =>
            fetchGBI(DefaultBaseURL + `/deliverables/project/${projectId}`),
    });

// Delete
export const useDeleteDeliverable = () => {
    const client = useQueryClient();

    return useMutation({
        mutationFn: async (deliverableId: string) => {
            await axios.delete(
                DefaultBaseURL + `/deliverables/${deliverableId}`,
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem(
                            'token'
                        )}`,
                    },
                }
            );
        },
        onSuccess: () => {
            return client.invalidateQueries({ queryKey: ['deliverable'] });
        },
    });
};
