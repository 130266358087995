export const getStatus = (status: string) => {
    let statusToRender = status;

    switch (status) {
        case 'active':
            statusToRender = 'Active';
            break;
        case 'in_active':
            statusToRender = 'Inactive / Paused';
            break;
        case 'open':
            statusToRender = 'Open';
            break;
        case 'backlog':
            statusToRender = 'Backlog';
            break;
        case 'in progress':
            statusToRender = 'In Progress';
            break;
        case 'in_progress':
            statusToRender = 'In Progress';
            break;
        case 'blocked':
            statusToRender = 'Blocked';
            break;
        case 'disabled':
            statusToRender = 'Disabled';
            break;
        case 'closed':
            statusToRender = 'Closed';
            break;
        case 'done':
            statusToRender = 'Done';
            break;
        case 'not_done':
            statusToRender = 'Not done';
            break;
        default:
            break;
    }

    return statusToRender;
};

export const getStatusClasses = (status: string) => {
    return {
        'status--active': status === 'active',
        'status--inactive': status === 'in_active',
        'status--open': status === 'open',
        'status--backlog': status === 'backlog',
        'status--in-progress':
            status === 'in_progress' || status === 'in progress',
        'status--done': status === 'done' || status === 'closed',
        'status--blocked': status === 'blocked' || status === 'not_done',
        'status--disabled': status === 'disabled',
    };
};
