import useUrlState from '@ahooksjs/use-url-state';
import fetchGBI from '@queries/fetchGBI';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';

import { DefaultBaseURL, getMutationUrlParams } from './urlHelpers';

export interface IGuideline {
    id: string;
    category: string;
    note: string;
}

export interface INewGuideline {
    category: string;
    note: string;
    ahjId?: string;
    companyRequirementId?: string;
    utilityId?: string;
    otherId?: string;
}

export const useCreateGuideline = () => {
    const client = useQueryClient();

    return useMutation({
        mutationFn: async (newGuideline: INewGuideline) => {
            const {
                data: { guideline },
            } = await axios.post(DefaultBaseURL + '/guidelines', newGuideline, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
            });
            return {
                guideline,
            };
        },
        onSuccess: () => {
            return client.invalidateQueries({ queryKey: ['jurisdictions'] });
        },
    });
};

export const useGetAllGuidelinesByProjectId = (projectId: string) => {
    const [urlState] = useUrlState();
    const group = localStorage.getItem('userGroup');
    const url = group === 'admin' ? DefaultBaseURL : DefaultBaseURL;
    const href = getMutationUrlParams(
        `${url}/guidelines/project/${projectId}`,
        urlState,
        'guidelinesByProject'
    ).href;

    return useQuery({
        queryKey: ['guidelinesByProject', href, group],
        queryFn: () => fetchGBI(href),
    });
};

export const useAttachJurisdiction = () => {
    const client = useQueryClient();

    return useMutation({
        mutationFn: async (guideline: {
            projectId: string;
            companyRequirementId?: string;
            utilityRequirementId?: string;
            ahjRequirementId?: string;
            otherRequirementId?: string;
        }) => {
            const {
                data: { project },
            } = await axios.post(
                DefaultBaseURL + '/jurisdictions/project/attach',
                guideline,
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem(
                            'token'
                        )}`,
                    },
                }
            );
            return {
                project,
            };
        },
        onSuccess: () => {
            return client.invalidateQueries({ queryKey: ['guidelinesAttach'] });
        },
    });
};

export const useGetAllJurisdictions = (filterType?: string) => {
    const [urlState] = useUrlState();
    const group = localStorage.getItem('userGroup');
    const url = group === 'admin' ? DefaultBaseURL : DefaultBaseURL;
    const href = getMutationUrlParams(
        `${url}/jurisdictions${filterType ? `?filter.type=${filterType}` : ''}`,
        urlState,
        'guidelines'
    ).href;

    return useQuery({
        queryKey: ['guidelines', href, group],
        queryFn: () => fetchGBI(href),
    });
};
