import { settingsType } from '@components/Forms/FormGenerator';

export const getPostMutationObject = (e: any, config: settingsType[]) => {
    const allFieldsWithNames = config
        .filter(e => e.config.name !== '')
        .map(e => e.config.name);

    return allFieldsWithNames.reduce((obj, key) => {
        return {
            ...obj,
            [key]: e.target[key]?.value !== '' ? e.target[key]?.value : null,
        };
    }, {});
};
