import { ReactComponent as PencilIcon } from '@assets/pencilIcon.svg';
import { ReactComponent as TrashIcon } from '@assets/trashIcon.svg';
import React from 'react';
import { useNavigate, useParams } from 'react-router';


export const EditDelete = ({
    handleDelete,
}: {
    handleDelete?: (id: string) => void;
}) => {
    const params = useParams();
    const navigation = useNavigate();

    return (
        <>
            <div className="edit" onClick={() => navigation('edit')}>
                <PencilIcon />
            </div>

            {handleDelete && (
                <div
                    className="delete"
                    onClick={() => handleDelete?.(params?.id ?? '')}
                >
                    <TrashIcon />
                </div>
            )}
        </>
    );
};
