import React from 'react';

interface ModalProps {
    children: React.ReactNode;
    showModalButtonTitle?: string;
    modalTitle?: string;
    closeButton?: boolean;
    saveButton?: boolean;
}

export const GBIModal = ({
    children,
    showModalButtonTitle = 'Show modal',
    modalTitle,
    closeButton = true,
    saveButton = true,
}: ModalProps) => {
    return (
        <>
            {/* Button trigger modal  */}
            <button
                type="button"
                className="btn btn-primary"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
            >
                {showModalButtonTitle}
            </button>

            {/* Modal */}
            <div
                className="modal fade"
                id="exampleModal"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">
                                {modalTitle}
                            </h5>
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            ></button>
                        </div>
                        <div className="modal-body">{children}</div>
                        <div className="modal-footer">
                            {closeButton && (
                                <button
                                    type="button"
                                    className="btn btn-secondary"
                                    data-bs-dismiss="modal"
                                >
                                    Close
                                </button>
                            )}

                            {saveButton && (
                                <button
                                    type="button"
                                    className="btn btn-primary"
                                >
                                    Save changes
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
