import React from 'react';
import { NavLink } from 'react-router-dom';

import { ButtonGBI, ButtonVariant } from '../ButtonGBI/ButtonGBI';

interface INavLinkProps {
    to: string;
    variant?: ButtonVariant;
    buttonName?: string;
    buttonType?: string;
    className?: string;
    children?: React.ReactNode;
}

export const NavLinkButton = ({
    to,
    variant = 'primary',
    className,
    children,
}: INavLinkProps) => {
    return (
        <NavLink
            to={to}
            className={({ isActive, isPending }) =>
                isActive ? 'active' : isPending ? 'pending' : ''
            }
        >
            <ButtonGBI
                variant={variant}
                size="sm"
                className={className}
                onClick={() => {}}
            >
                {children}
            </ButtonGBI>
        </NavLink>
    );
};
