import { UserGroups } from '@queries/users.query';

export const getGroup = (userGroup?: UserGroups) => {
    const group = userGroup ?? localStorage.getItem('userGroup');

    switch (group) {
        case UserGroups.EE_ENGINEER:
            return 'ee-engineer';

        case UserGroups.PE_ENGINEER:
            return 'pe-engineer';
    }

    return group;
};
