import React from 'react';
import './DetailTitle.scss';

interface IDetailTitleProps {
    title: string;
    icon: React.ReactNode;
}

export const DetailTitle = ({ title, icon: Icon }: IDetailTitleProps) => {
    return (
        <div className="detail-title d-flex align-items-center column-gap-2">
            {Icon}
            <h4 className="m-0">{title}</h4>
        </div>
    );
};
