// @ts-nocheck
import { TanStackTable } from '@components/Table/TanStackTable';
import { INewUser } from '@queries/users.query';
import { getGroup } from '@utils/getGroup';
import React from 'react';


// todo this looks like something that has been used in few places. Maybe it should be extracted to a separate component
type UsersTableProps = {
    tableSetup: any;
    propData?: any;
    isDropdownEnabled?: boolean;
    isFooterEnabled?: boolean;
    className?: string;
};

export interface Users extends INewUser {
    subRows?: Users[];
}

export const UsersTable = ({
    propData,
    tableSetup,
    isDropdownEnabled = true,
    isFooterEnabled = true,
    className,
}: UsersTableProps) => {
    return (
        <TanStackTable
            className={className}
            tableSetup={tableSetup}
            propData={propData}
            createButtonTitle="Create new user"
            linkTo={`/${getGroup()}/users`}
            dropdownFiltersFor="users"
            isDropdownEnabled={isDropdownEnabled}
            isFooterEnabled={isFooterEnabled}
        />
    );
};
