import useUrlState from '@ahooksjs/use-url-state';
import { ReactComponent as CompaniesIcon } from '@assets/companiesIcon.svg';
import { ReactComponent as EmptyCompanyIcon } from '@assets/emptyCompanyIcon.svg';
import { DetailTitle } from '@components/DetailTitle/DetailTitle';
import { EmptyPage } from '@components/EmptyPage/EmptyPage';
import { Loader } from '@components/Loader/Loader';
import { Pagination } from '@components/Pagination/Pagination';
import {
    CompanyStatus,
    useDeleteCompany,
    useEditCompany,
    useGetAllCompanies,
} from '@queries/companies.query';
import { useQueryClient } from '@tanstack/react-query';
import { getGroup } from '@utils/getGroup';
import { isObjectEmpty } from '@utils/utils';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import './Companies.scss';
import { CompaniesTable } from './CompaniesTable';


export const Companies = () => {
    const client = useQueryClient();

    const [urlState, setUrlState] = useUrlState();
    const isFiltered = !isObjectEmpty(urlState);

    const [companyId, setCompanyId] = useState('');
    const [companyStatus, setCompanyStatus] = useState(CompanyStatus.ACTIVE);

    const { data: companyData, fetchStatus } = useGetAllCompanies();
    const deleteMutation = useDeleteCompany();
    const companiesMutation = useEditCompany(companyId);

    const handleDelete = (id: string) => {
        deleteMutation.mutate(id, {
            onSuccess: () => toast.success('Company deleted successfully'),
        });
    };

    const handlePause = (id: string, status: CompanyStatus) => {
        setCompanyId(id);
        setCompanyStatus(status);
    };

    useEffect(() => {
        if (companyId !== '') {
            companiesMutation.mutate(
                {
                    status: companyStatus,
                },
                {
                    onSuccess: () => {
                        toast.success('Company status changed successfully');
                        return client.invalidateQueries({
                            queryKey: ['companies'],
                        });
                    },
                }
            );
        }
    }, [companyId, companyStatus]);

    const getCompaniesComponent = () => {
        if (fetchStatus === 'fetching') {
            return <Loader />;
        }

        if (
            fetchStatus === 'idle' &&
            (companyData?.data?.length > 0 || isFiltered)
        ) {
            return (
                <CompaniesTable
                    propData={companyData.data}
                    handleDelete={id => handleDelete(id)}
                    handlePause={(id, status) => handlePause(id, status)}
                />
            );
        }

        if (
            fetchStatus === 'idle' &&
            companyData?.data?.length === 0 &&
            !isFiltered
        ) {
            return (
                <EmptyPage
                    title="No company has been created yet"
                    to={`/${getGroup()}/companies/create`}
                    buttonName="Create new company"
                    icon={<EmptyCompanyIcon />}
                />
            );
        }

        return null;
    };

    return (
        <div className="companies-page content-wrapper">
            <DetailTitle title="Companies" icon={<CompaniesIcon />} />

            {getCompaniesComponent()}

            <Pagination
                currentPage={companyData?.meta.currentPage}
                totalPages={companyData?.meta.totalPages}
                prefix="companies"
            />
        </div>
    );
};
