import { DisplayComments } from '@components/Comments/DisplayComments';
import { ProjectComments } from '@components/Comments/ProjectComments';
import {
    SearchableDropdown,
    SelectedElement,
} from '@components/Forms/components/searchableDropdown/SearchableDropdown';
import { GBIModal } from '@components/Modal/Modal';
import { useGetCommentsByProjectId } from '@queries/comments.query';
import { TaskStatuses, useEditTask } from '@queries/tasks.query';
import classNames from 'classnames';
import React from 'react';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';

import './Tasks.scss';

export const TasksSidebar = ({
    commentId,
    assignee,
    disableOnSubmit,
    onAssigneeSelected,
}: {
    commentId?: string;
    assignee: SelectedElement;
    disableOnSubmit?: boolean;
    onAssigneeSelected?: (result: SelectedElement) => void;
}) => {
    const params = useParams();
    const taskId = params.id ?? '';
    const tasksMutation = useEditTask(taskId);
    // const { data: taskComments } = useGetCommentsByTaskId(taskId);
    const { data: commentsData } = useGetCommentsByProjectId(commentId ?? '');

    const setAssignee = (selectedElement: SelectedElement) => {
        onAssigneeSelected?.(selectedElement);
        if (!disableOnSubmit) {
            return tasksMutation.mutate(
                {
                    // @ts-ignore
                    assigneeId: selectedElement.id || assignee?.id,
                    status: TaskStatuses.IN_PROGRESS,
                },
                {
                    onSuccess: () => {
                        toast.success('Task assignee has been changed');
                    },
                }
            );
        }
    };

    return (
        <div className={classNames('sidebar-wrapper col-lg-6')}>
            {
                <SearchableDropdown
                    onSelect={(result: any) => setAssignee(result)}
                    initialData={assignee}
                ></SearchableDropdown>
            }
            <div className="comments-section">
                <div className="title-add-new-comment">
                    <h4>Comments:</h4>
                    <GBIModal
                        modalTitle="Comments"
                        showModalButtonTitle="Add comment"
                        saveButton={false}
                    >
                        <ProjectComments commentId={commentId} />
                    </GBIModal>
                </div>

                <DisplayComments
                    commentsData={commentsData?.data?.slice(0, 4)}
                    className="details"
                />
            </div>
        </div>
    );
};
