import { FormGenerator } from '@components/Forms/FormGenerator';
import { Loader } from '@components/Loader/Loader';
import { NavigationHeader } from '@components/NavigationHeader/NavigationHeader';
import { IEditUser, useEditUser, useGetUser } from '@queries/users.query';
import { getGroup } from '@utils/getGroup';
import { getPostMutationObject } from '@utils/getPostMutationObject';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { toast } from 'react-toastify';

import './Users.scss';
import { userTypes } from './UsersCreate';
import { usersFormConfig } from './UsersFormConfig';
import { UsersNavigation } from './header/UsersNavigation';


export const UsersEdit = () => {
    const params = useParams();
    const navigate = useNavigate();
    const { status, data } = useGetUser(params.id ?? '');
    const userMutation = useEditUser(params.id ?? '');

    let usersData = data;
    const [isLoading, setIsLoading] = useState(false);
    const [userGroupId, setUserGroupId] = useState(usersData?.group);

    const setUserTypeId = (id: string) => {
        setUserGroupId(id);
        usersData && (usersData.group = id);
    };

    const userConfig = usersFormConfig(userGroupId, userTypes, setUserTypeId);

    useEffect(() => {
        setUserGroupId(data?.group);
    }, [data?.group]);

    const onSubmit = (e: any) => {
        e.preventDefault();
        setIsLoading(true);

        const formData: IEditUser = getPostMutationObject(e, userConfig);
        formData && (formData.group = userGroupId);
        usersData && (usersData.lastName = e.target.lastName.value);
        usersData && (usersData.address = 'address placeholder');

        if (formData.password === '') {
            delete formData.password;
        }

        return userMutation.mutate(formData, {
            onSuccess: () => {
                toast.success('User edited successfully');
                navigate(`/${getGroup()}/users`);
            },
            onError: () => {
                setIsLoading(false);
            },
        });
    };

    return (
        <div className="users-edit content-wrapper with-header">
            <form onSubmit={e => onSubmit(e)}>
                <NavigationHeader detailTitle={data?.name ?? "User's details"}>
                    <UsersNavigation
                        submitButtonTitle="Save user"
                        isDisabled={isLoading}
                        isLoading={isLoading}
                    />
                </NavigationHeader>

                <div className="row">
                    <div className="col-lg-6">
                        {status === 'success' ? (
                            <FormGenerator
                                settings={userConfig}
                                defaultData={usersData}
                            />
                        ) : (
                            <Loader />
                        )}
                    </div>
                </div>
            </form>
        </div>
    );
};
