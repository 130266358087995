import { CancelSubmit } from '@components/NavigationHeader/parts/cancelSubmit/CancelSubmit';
import { EditDelete } from '@components/NavigationHeader/parts/editDelete/EditDelete';
import { StatusDropdown } from '@components/NavigationHeader/parts/statusDropdown/StatusDropdown';
import { isEditCreatePage } from '@utils/utils';
import React from 'react';


export const CompaniesNavigation = ({
    submitButtonTitle = 'Save new',
    defaultStatus,
    handleDelete,
    isLoading = false,
    isDisabled = false,
}: {
    submitButtonTitle?: string;
    defaultStatus?: string;
    handleDelete?: (id: string) => void;
    isLoading?: boolean;
    isDisabled?: boolean;
}) => {
    return isEditCreatePage() ? (
        <CancelSubmit
            submitButtonTitle={submitButtonTitle}
            isLoading={isLoading}
            isDisabled={isDisabled}
            location="companies"
        />
    ) : (
        <>
            <EditDelete handleDelete={handleDelete} />

            <StatusDropdown defaultValue={defaultStatus} />
        </>
    );
};
