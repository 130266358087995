// @ts-nocheck
import { TanStackTable } from '@components/Table/TanStackTable';
import { getGroup } from '@utils/getGroup';
import React from 'react';

type TasksTableProps = {
    tableSetup: any;
    propData?: any;
    isDropdownEnabled?: boolean;
    isFooterEnabled?: boolean;
    className?: string;
    extraLinkTo?: string;
    projectId?: string;
};

export interface Tasks extends Task {
    subRows?: Task[];
}

export const TasksTable = ({
    propData,
    tableSetup,
    isDropdownEnabled = true,
    isFooterEnabled = true,
    className,
    extraLinkTo,
    projectId
}: TasksTableProps) => {
    const CSVData = propData?.map((task: any, index: number) => {
        return {
            highlightedColumn: index + 1,
            title: task.title,
            type: task.type,
            companyName: task.project?.company?.name || 'No company',
            assignedTo: task.assignee?.firstName || 'Unassigned',
            dueDate: task.dueDate,
            status: task.status,
        };
    });

    const CSVColumns = [
        {
            id: 'highlightedColumn',
            displayName: 'Tasks',
        },
        {
            id: 'title',
            displayName: 'Title',
        },
        {
            id: 'type',
            displayName: 'Type',
        },
        {
            id: 'companyName',
            displayName: 'Company',
        },
        {
            id: 'assignedTo',
            displayName: 'Assigned to',
        },
        {
            id: 'dueDate',
            displayName: 'Due date',
        },
        {
            id: 'status',
            displayName: 'Status',
        },
    ];

    return (
        <TanStackTable
            className={className}
            tableSetup={tableSetup}
            propData={propData}
            createButtonTitle="Create new task"
            projectIdForTaskCreation={projectId}
            linkTo={
                extraLinkTo
                    ? `/${getGroup()}/tasks/${extraLinkTo}`
                    : `/${getGroup()}/tasks`
            }
            dropdownFiltersFor="tasks"
            isDropdownEnabled={isDropdownEnabled}
            isFooterEnabled={isFooterEnabled}
            CSVColumns={CSVColumns}
            CSVData={CSVData}
            isCSVButtonEnabled={false}
        />
    );
};
