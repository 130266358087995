// @ts-nocheck
import { ReactComponent as SortingArrowsIcon } from '@assets/sortingArrows.svg';
import { TableActions } from '@components/Table/TableActions';
import { ColumnDef } from '@tanstack/react-table';
import { handleSort } from '@utils/sorting';
import { formatDate } from '@utils/utils';
import React from 'react';

import { taskTypes } from './TasksCreate';
import { Tasks } from './TasksTable';


export const tasksTableSetup = (
    handleDelete: any,
    handleEdit: any,
    urlState?: any,
    setUrlState?: any
): ColumnDef<Tasks>[] => {
    const group = localStorage.getItem('userGroup');
    const isAdmin = group === 'admin';

    return [
        {
            accessorKey: 'id',
            header: 'ID',
            footer: props => props.column.id,
            filterTitle: 'ID',
        },
        {
            accessorKey: 'title',
            header: () => (
                <button
                    onClick={() => handleSort('title', urlState, setUrlState)}
                >
                    Task's title
                    <SortingArrowsIcon />
                </button>
            ),
            footer: props => props.column.id,
            filterTitle: "Task's title",
        },
        {
            accessorKey: 'type',
            header: () => (
                <button
                    onClick={() => handleSort('type', urlState, setUrlState)}
                >
                    Type
                    <SortingArrowsIcon />
                </button>
            ),
            cell: data => {
                const type = data.row.original.type;
                return taskTypes.find(taskType => taskType.id === type)
                    ?.valueToShow;
            },
            footer: props => props.column.id,
            filterTitle: 'Type',
        },
        {
            accessorKey: 'project',
            header: () => (
                <button
                    onClick={() =>
                        handleSort(
                            'project.company.name',
                            urlState,
                            setUrlState
                        )
                    }
                >
                    Company
                    <SortingArrowsIcon />
                </button>
            ),
            cell: data => {
                return data.row.original?.project?.company?.name;
            },
            footer: props => props.column.id,
            filterTitle: 'Company',
        },
        {
            accessorKey: 'assigneeId',
            header: () => (
                <button
                    onClick={() =>
                        handleSort('assignee.firstName', urlState, setUrlState)
                    }
                >
                    Assigned to
                    <SortingArrowsIcon />
                </button>
            ),
            cell: data => {
                return data.row.original?.assignee
                    ? data.row.original.assignee.firstName
                    : 'Not Selected';
            },
            footer: props => props.column.id,
            filterTitle: 'Assigned to',
        },
        {
            accessorKey: 'dueDate',
            header: () => (
                <button
                    onClick={() => handleSort('dueDate', urlState, setUrlState)}
                >
                    Due date
                    <SortingArrowsIcon />
                </button>
            ),
            cell: data => formatDate(data.row.getValue('dueDate')),
            footer: props => props.column.id,
            filterTitle: 'DueDate',
        },
        {
            accessorKey: 'status',
            header: () => (
                <button
                    onClick={() => handleSort('status', urlState, setUrlState)}
                >
                    Status
                    <SortingArrowsIcon />
                </button>
            ),
            footer: props => props.column.id,
            filterTitle: 'Status',
        },
        {
            allowAsProps: true,
            accessorKey: 'actions',
            header: 'Actions',
            filterTitle: 'Actions',
            cell: data => {
                return (
                    <TableActions
                        handleDelete={
                            isAdmin
                                ? () => handleDelete(data.row.getValue('id'))
                                : undefined
                        }
                        handleEdit={() => handleEdit(data)}
                    />
                );
            },
        },
    ];
};
