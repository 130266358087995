import { ButtonGBI } from '@components/ButtonGBI/ButtonGBI';
import { DownloadFile } from '@components/FilesUpload/DownloadFile';
import { useGetDeliverableByProjectId } from '@queries/deliverables.query';
import { useGetProject } from '@queries/projects.query';
import { DefaultBaseURL } from '@queries/urlHelpers';
import { downloadZip } from 'client-zip';
import React from 'react';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';

export const DeliverableActions = ({
    handleCheckboxChange,
}: {
    handleCheckboxChange?: any;
}) => {
    const params = useParams();
    const projectId = params.id ?? '';

    const { data: projectData } = useGetProject(projectId);
    const { data: deliverablesData } = useGetDeliverableByProjectId(projectId);
    const [isDownload, setIsDownload] = React.useState(false);

    const activeDeliverables = deliverablesData?.filter(
        deliverable => deliverable.status === 'active'
    );

    const activeFilesId = activeDeliverables?.map(
        deliverable => deliverable.file.id
    );

    async function downloadTestZip() {
        if (activeFilesId && activeFilesId.length === 0) {
            toast.success('There is no active files to download');
            return;
        }
        const allActiveFiles = activeFilesId?.map(async (id: any) => {
            const res = await fetch(`${DefaultBaseURL}/files/${id}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
            });
            return res.json();
        });
        const filesWithSingedUrl = await Promise.all(allActiveFiles!);

        const allDeliverablesBlob = filesWithSingedUrl.map(
            async (file: any) => {
                const response = await fetch(file.url);
                const blob = await response.blob();
                const fullFileUrl = file.url.split('/').pop();
                return {
                    name: fullFileUrl.slice(0, fullFileUrl.indexOf('?')),
                    input: blob,
                };
            }
        );
        const allFilesData = await Promise.all(allDeliverablesBlob);
        const blob = await downloadZip(allFilesData).blob();
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = `${projectData.name}-deliverables.zip`;
        link.click();
        link.remove();
    }

    const onClickDownloadAllActiveFiles = async () => {
        // setIsDownload(true);

        await downloadTestZip();
    };

    // todo replace that with working bulk delete
    // useEffect(() => {
    //     if (isDownload) {
    //         setTimeout(() => {
    //             const filesToDownload =
    //                 document.getElementsByClassName('auto-download-file');
    //             console.log('ttt filesToDownload', filesToDownload);
    //
    //             for (let i = 0; i < filesToDownload.length; i++) {
    //                 setTimeout(() => {
    //                     console.log(
    //                         'ttt filesToDownload[i]',
    //                         filesToDownload[i]
    //                     );
    //                     (filesToDownload[i] as HTMLElement).click();
    //                 }, 500);
    //                 // console.log('ttt filesToDownload', filesToDownload[i]); // logs each element to the console
    //                 // (filesToDownload[i] as HTMLElement).click();
    //             }
    //         }, 500);
    //
    //         setTimeout(() => {
    //             setIsDownload(false);
    //         }, 5000);
    //     }
    // }, [isDownload]);

    return (
        <div className="deliverable-actions">
            <ButtonGBI onClick={() => onClickDownloadAllActiveFiles()}>
                Download all active files
            </ButtonGBI>

            {isDownload &&
                activeFilesId?.map((id: any) => (
                    <span key={id}>
                        downloading
                        <DownloadFile id={id} />
                    </span>
                ))}

            <div className="deliverable-checkbox">
                <label
                    className="form-check-label"
                    htmlFor="flexSwitchCheckDefault"
                >
                    Show old files
                </label>
                <div className="button r" id="button-1">
                    <input
                        type="checkbox"
                        className="checkbox"
                        onChange={handleCheckboxChange}
                    />
                    <div className="knobs"></div>
                    <div className="layer"></div>
                </div>
            </div>
        </div>
    );
};
