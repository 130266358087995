import React from 'react';
import { ToastContainer } from 'react-toastify';

export const Toast = () => {
    return (
        <ToastContainer
            stacked
            position="bottom-right"
            autoClose={2500}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
        />
    );
};
