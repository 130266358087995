import { ReactComponent as DownloadIcon } from '@assets/download.svg';
import { ReactComponent as PdfIcon } from '@assets/pdfFile.svg';
import { ReactComponent as TrashIcon } from '@assets/trashIcon.svg';
import { DownloadFile } from '@components/FilesUpload/DownloadFile';
import { FileStatus } from '@components/FilesUpload/FileStatus';
import { useDeleteDeliverable } from '@queries/deliverables.query';
import { IResourceFile, useDeleteFile } from '@queries/files.query';
import React from 'react';
import { toast } from 'react-toastify';

type SingleFileProps = {
    file: IResourceFile;
    status?: string;
    deliverableId?: string;
    withStatus?: boolean;
};
const bytesToMB = (bytes: number): string => {
    return (bytes / (1024 * 1024)).toFixed(2) + ' MB';
};
export const SingleFile = ({
    file,
    status,
    deliverableId,
    withStatus = false,
}: SingleFileProps) => {
    const group = localStorage.getItem('userGroup');
    const [isDownload, setIsDownload] = React.useState(false);

    const deleteFileMutation = useDeleteFile();
    const deleteDeliverableMutation = useDeleteDeliverable();

    const handleDeleteFile = (id: string) => {
        if (deliverableId) {
            deleteDeliverableMutation.mutate(deliverableId, {
                onSuccess: () => {
                    toast.success('Deliverable deleted successfully');
                },
            });
        } else {
            deleteFileMutation.mutate(id, {
                onSuccess: () => toast.success('File deleted successfully'),
            });
        }
    };

    return (
        <div key={file.id} className="file-single">
            <div className="description-part">
                <PdfIcon />
                <div>
                    <div className="name">{file.name}</div>
                    <div className="date-file-size">
                        {new Date(file.createdAt).toLocaleDateString('en-US')} -{' '}
                        {bytesToMB(file.fileSize)}
                    </div>
                </div>
            </div>

            <div className="actions-part">
                {withStatus && deliverableId && (
                    <FileStatus status={status} fileId={deliverableId} />
                )}

                <DownloadIcon onClick={() => setIsDownload(true)} />

                {isDownload && (
                    <DownloadFile id={file.id} setIsDownload={setIsDownload} />
                )}
                {group === 'admin' && (
                    <TrashIcon
                        onClick={() => handleDeleteFile(file.id)}
                        className="delete"
                    />
                )}
            </div>
        </div>
    );
};
