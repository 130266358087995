import { Tasks } from '@pages/tasks/Tasks';
import { TasksCreate } from '@pages/tasks/TasksCreate';
import { TasksDetails } from '@pages/tasks/TasksDetails';
import { TasksEdit } from '@pages/tasks/TasksEdit';

export const tasksManagerRoutes = [
    {
        path: '/manager/tasks',
        Component: Tasks,
    },
    {
        path: '/manager/tasks/:id',
        Component: TasksDetails,
    },
    {
        path: '/manager/tasks/create',
        Component: TasksCreate,
    },
    {
        path: '/manager/tasks/:id/edit',
        Component: TasksEdit,
    },
];
