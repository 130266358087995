import moment from 'moment/moment';

export const isDateFormat = (date: string) => {
    return moment(date, 'DD/MM/YYYY', true).isValid();
};

export const isISODateFormat = (date: string) => {
    return moment(date, moment.ISO_8601, true).isValid();
};
export const formatDate = (date: string | Date) =>
    moment(date).local().format('DD/MM/YYYY');

export const isEditPage = () => window.location.pathname.includes('edit');
export const isCreatePage = () => window.location.pathname.includes('create');

export const isEditCreatePage = () => isEditPage() || isCreatePage();
export const isDetailsPage = () => !isEditCreatePage();

export const isObjectEmpty = (obj: any) => {
    return Object.keys(obj).length === 0;
};
