import { TanStackTable } from '@components/Table/TanStackTable';
import { INewProject } from '@queries/projects.query';
import { getGroup } from '@utils/getGroup';
import React from 'react';

type JurisdictionsTableProps = {
    tableSetup: any;
    propData?: any;
    isDropdownEnabled?: boolean;
    isFooterEnabled?: boolean;
    className?: string;
};

export interface Jurisdictions extends INewProject {
    subRows?: Jurisdictions[];
}

export const JurisdictionTable = ({
    propData,
    tableSetup,
    isDropdownEnabled = true,
    isFooterEnabled = true,
    className,
}: JurisdictionsTableProps) => {
    const group = getGroup();
    
    return (
        <TanStackTable
            className={className}
            tableSetup={tableSetup}
            propData={propData}
            createButtonTitle="Create new jurisdiction"
            isCreateButtonEnabled={group !== 'manager'}
            linkTo={`/${getGroup()}/jurisdiction`}
            useType={true}
            dropdownFiltersFor="jurisdiction"
            isDropdownEnabled={isDropdownEnabled}
            isFooterEnabled={isFooterEnabled}
            isMultiselect={true}
        />
    );
};
