import { ReactComponent as LogoSVG } from '@assets/logo.svg';
import { NavLinkButton } from '@components/NavLink/NavLinkButton';
import { UserGroups } from '@queries/users.query';
import classNames from 'classnames';
import React from 'react';
import { useLocation } from 'react-router';
import { adminMenu } from 'src/configs/menu/adminMenu';
import { designerMenu } from 'src/configs/menu/designerMenu';
import { managerMenu } from 'src/configs/menu/managerMenu';

import { eeEngineerMenu } from '../../../configs/menu/eeEngineerMenu';
import { employeeMenu } from '../../../configs/menu/employeeMenu';
import { peEngineerMenu } from '../../../configs/menu/peEngineerMenu';
import { SidebarFooter } from './SidebarFooter';
import './SidebarMenu.scss';

export const SidebarMenu = () => {
    const location = useLocation();
    const group = localStorage.getItem('userGroup');
    let menuConfig;

    switch (group) {
        case UserGroups.ADMIN:
            menuConfig = adminMenu;
            break;

        case UserGroups.MANAGER:
            menuConfig = managerMenu;
            break;

        case UserGroups.EMPLOYEE:
            menuConfig = employeeMenu;
            break;

        case UserGroups.DESIGNER:
            menuConfig = designerMenu;
            break;

        case UserGroups.EE_ENGINEER:
            menuConfig = eeEngineerMenu;
            break;

        case UserGroups.PE_ENGINEER:
            menuConfig = peEngineerMenu;
            break;

        default:
            menuConfig = managerMenu;
            break;
    }

    return (
        <nav id="sidebar" className="sidebar-menu-inside">
            <div className="header-part">
                <div className="logo sidebar-header">
                    <a href="/">
                        <LogoSVG />
                    </a>
                </div>

                <ul className="menu nav-items list-unstyled components d-flex flex-column">
                    <p className="fw-bold">MENU</p>
                    {menuConfig.map(menuElement => (
                        <NavLinkButton
                            key={menuElement.name}
                            to={menuElement.path}
                            variant="menu"
                            className={classNames('w-100 mb-1 text-start', {
                                active: location.pathname.includes(
                                    menuElement.path
                                ),
                            })}
                        >
                            <menuElement.icon className="me-2" />
                            {menuElement.name}
                        </NavLinkButton>
                    ))}
                </ul>
            </div>

            <SidebarFooter />
        </nav>
    );
};
