import { getGroup } from '@utils/getGroup';
import classNames from 'classnames';
import React from 'react';
import { useLocation, useNavigate } from 'react-router';

export const JurisdictionTypeSwitch = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const group = localStorage.getItem('userGroup');
    const isAdmin = group === 'admin';
    const isDesigner = group === 'designer';

    return (
        <div className="jurisdiction-type-switch">
            {(isDesigner || isAdmin) && (
                <button
                    onClick={() =>
                        navigate(`/${getGroup()}/jurisdiction/utility/create`)
                    }
                    className={classNames({
                        active: location.pathname.includes('/utility/create'),
                    })}
                >
                    Utility
                </button>
            )}
            {(isDesigner || isAdmin) && (
                <button
                    onClick={() =>
                        navigate(`/${getGroup()}/jurisdiction/ahj/create`)
                    }
                    className={classNames({
                        active: location.pathname.includes('/ahj/create'),
                    })}
                >
                    AHJ
                </button>
            )}
            <button
                onClick={() =>
                    navigate(`/${getGroup()}/jurisdiction/company/create`)
                }
                className={classNames({
                    active: location.pathname.includes('/company/create'),
                })}
            >
                Company
            </button>
            {(isDesigner || isAdmin) && (
                <button
                    onClick={() =>
                        navigate(`/${getGroup()}/jurisdiction/other/create`)
                    }
                    className={classNames({
                        active: location.pathname.includes('/other/create'),
                    })}
                >
                    Other
                </button>
            )}
        </div>
    );
};
