import { RequiredIndicator } from '@components/Forms/components/shared/RequiredIndicator';
import React from 'react';

import { configType, globalType, validationType } from '../../FormGenerator';
import { GetData, GetLabel } from '../../helpers/GetLabelData';
import './DropdownField.scss';

export const DropdownField = ({
    config: { name, title, selectData, onChange, type, url },
    global,
    validation,
    disabled,
    defaultData,
    unsetOption = false,
}: {
    config: configType;
    global?: globalType;
    validation?: validationType;
    disabled: boolean;
    defaultData: any;
    unsetOption?: boolean;
}) => {
    const mapIdToValue = (id: string) => {
        return selectData?.find(item => item.id === id)?.valueToShow;
    };

    const getValueToShow = (): string => {
        const emptyText = 'Select';
        if (defaultData?.[`${name}Id`]) {
            return mapIdToValue(defaultData?.[`${name}Id`]) ?? emptyText;
        }
        if (defaultData?.[name]?.id) {
            return mapIdToValue(defaultData?.[name]?.id) ?? emptyText;
        }
        if (defaultData?.[name]) {
            return mapIdToValue(defaultData[name]) ?? emptyText;
        }
        return emptyText;
    };

    if (disabled) {
        if (global?.viewMode?.hidden) return null;
        return (
            <div className="input-field form-group">
                <GetLabel title={title} global={global} />
                {type === 'link' ? (
                    <a href={url}>
                        <GetData
                            title={getValueToShow()}
                            global={global}
                        />
                    </a>
                ) : (
                    <GetData title={getValueToShow()} global={global} />
                )}
            </div>
        );
    }

    return (
        <div className="dropdown-field form-group">
            <label htmlFor={title}>
                {title} <RequiredIndicator isRequired={validation?.required} />
            </label>
            <input
                style={{ display: 'none' }}
                disabled
                type="text"
                className="form-control"
                id={name}
                defaultValue={defaultData?.[name]}
            />
            <div className="dropdown form-control">
                <div
                    className="dropdown-toggle"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                >
                    {getValueToShow()}
                </div>
                <ul className="dropdown-menu">
                    {unsetOption && (
                        <li>
                            <span
                                className="dropdown-item"
                                onClick={() => onChange?.(null)}
                            >
                                Unset
                            </span>
                        </li>
                    )}
                    {selectData?.map(item => {
                        return (
                            <li key={item.id}>
                                <span
                                    className="dropdown-item"
                                    onClick={() => onChange?.(item.id)}
                                >
                                    {item.valueToShow}
                                </span>
                            </li>
                        );
                    })}
                </ul>
            </div>
        </div>
    );
};
