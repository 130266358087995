// @ts-nocheck
import { FieldType, Type, settingsType } from '@components/Forms/FormGenerator';

export const usersFormConfig = (
    userTypeId: string | undefined,
    userTypes?: { id: string; valueToShow: string }[],
    onUserTypeIdChange?: (data: any) => void,
    companySelectData?: { id: string; valueToShow: string }[],
    onCompanyChange?: (data: any) => void,
    role?: string
): settingsType[] => [
    {
        config: {
            fieldType: FieldType.Input,
            type: Type.Text,
            subType: 'fullName',
            name: 'firstName',
            title: 'Full name',
        },
        global: {
            viewMode: {
                boldLabel: true,
                featuredData: true,
            },
        },
    },
    {
        config: {
            fieldType: FieldType.Input,
            type: 'email',
            name: 'email',
            title: 'E-mail',
        },
        validation: {
            required: true,
            minLength: 3,
            errorMessage: "Email field can't be empty",
        },
        global: {
            viewMode: {
                boldLabel: true,
            },
        },
    },
    {
        config: {
            fieldType: FieldType.Input,
            type: 'password',
            name: 'password',
            title: 'Password',
        },
        validation: {
            required: true,
            minLength: 3,
            errorMessage: "Password field can't be empty",
        },
        global: {
            viewMode: {
                hidden: true,
            },
        },
    },
    {
        config: {
            fieldType: FieldType.Hr,
            type: Type.Text,
            name: '',
            title: 'Role & assigment',
        },
    },
    {
        config: {
            fieldType: FieldType.Select,
            type: Type.Text,
            name: 'group',
            title: 'Role',
            selectData: userTypes,
            onChange: (data: any) => onUserTypeIdChange?.(data),
        },
        validation: {
            required: true,
            minLength: 1,
            errorMessage: 'Role field is required',
        },
        global: {
            viewMode: {
                boldData: true,
            },
        },
    },
    ...((['manager', 'admin'].includes(role) && (userTypeId === 'employee' || userTypeId === 'manager'))
        ? [
            {
                config: {
                    fieldType: FieldType.Select,
                    type: Type.Text,
                    name: 'companyId',
                    title: 'Company',
                    selectData: companySelectData,
                    onChange: (data: any) => onCompanyChange?.(data),
                },
                global: {
                    viewMode: {
                        boldData: true,
                    },
                },
            },
        ]
        : []),
    {
        config: {
            fieldType: FieldType.Input,
            type: Type.Text,
            name: 'supervisor',
            title: 'Supervisor',
        },
        global: {
            viewMode: {
                boldData: true,
            },
        },
    },
];
