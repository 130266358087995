import { UserStatus, useEditUser } from '@queries/users.query';
import { useQueryClient } from '@tanstack/react-query';
import { getStatus, getStatusClasses } from '@utils/statuses';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';


export const StatusDropdown = ({
    defaultValue,
}: {
    defaultValue: UserStatus | undefined;
}) => {
    const client = useQueryClient();
    const [status, setStatus] = useState(defaultValue);
    const params = useParams();
    const usersMutation = useEditUser(params.id ?? '');

    const statusToRender = defaultValue && getStatus(defaultValue);

    const onSubmit = () => {
        if (status)
            return usersMutation.mutate(
                {
                    status,
                },
                {
                    onSuccess: () => {
                        return client.invalidateQueries({
                            queryKey: ['users'],
                        });
                    },
                }
            );
    };

    useEffect(() => {
        if (status !== defaultValue) {
            onSubmit();
        }
    }, [status]);

    return (
        <div className="status-change-dropdown">
            <div className="dropdown">
                <button
                    className={classNames(
                        'btn dropdown-toggle',
                        defaultValue && getStatusClasses(defaultValue)
                    )}
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                >
                    {statusToRender}
                </button>
                <ul className="dropdown-menu">
                    <li>
                        <span
                            role="button"
                            tabIndex={0}
                            className="dropdown-item"
                            onClick={() => setStatus(UserStatus.ACTIVE)}
                        >
                            Active
                        </span>
                    </li>
                    <li>
                        <span
                            role="button"
                            tabIndex={0}
                            className="dropdown-item"
                            onClick={() => setStatus(UserStatus.DISABLED)}
                        >
                            Disabled
                        </span>
                    </li>
                </ul>
            </div>
        </div>
    );
};
