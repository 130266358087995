import { FormGenerator } from '@components/Forms/FormGenerator';
import { Loader } from '@components/Loader/Loader';
import { NavigationHeader } from '@components/NavigationHeader/NavigationHeader';
import {
    INewCompany,
    useEditCompany,
    useGetCompany,
    useUploadCompanyLogo,
} from '@queries/companies.query';
import { useGetUsers } from '@queries/users.query';
import { getGroup } from '@utils/getGroup';
import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { toast } from 'react-toastify';

import {
    getElementsToValidate,
    isInvalidField,
} from '../../validations/helpers';
import './Companies.scss';
import { getCompanyDataObject } from './CompaniesCreate';
import { companiesFormConfig } from './CompaniesFormConfig';
import { CompaniesSidebar } from './CompaniesSidebar';
import { CompaniesNavigation } from './header/CompaniesNavigation';

const getUsersData = (userData?: any[]) => {
    return userData?.map((manager: any) => ({
        id: manager.id,
        valueToShow: manager.firstName + ' ' + manager.lastName,
    }));
};

export const CompaniesEdit = () => {
    const params = useParams();
    const navigate = useNavigate();
    const { data: userData } = useGetUsers({ ['filter.group']: 'manager' });
    const { status, data } = useGetCompany(params.id ?? '');
    const companiesMutation = useEditCompany(params.id ?? '');
    const createCompanyLogoMutation = useUploadCompanyLogo();

    const usersData = getUsersData(userData?.data);

    let companyData = data;

    const [isLoading, setIsLoading] = useState(false);
    const [managerId, setManagerId] = useState(data?.manager?.id);
    const setManager = (id: string) => {
        setManagerId(id);
        companyData && (companyData.managerId = id);
    };

    const companyConfig = companiesFormConfig(true, usersData, setManager);
    const onSubmit = (event: any) => {
        event.preventDefault();
        setIsLoading(true);

        const formData: INewCompany = getCompanyDataObject(event);
        delete formData.manager;
        formData && (formData.managerId = managerId);

        const companyData = getCompanyDataObject(event);
        const { fieldsToValidate, inputsToValidate } =
            getElementsToValidate(companyConfig);

        if (isInvalidField(fieldsToValidate, inputsToValidate, companyData)) {
            setIsLoading(false);
        } else {
            return companiesMutation.mutate(formData, {
                onSuccess: () => {
                    toast.success('Company edited successfully');
                    navigate(`/${getGroup()}/companies`);
                },
                onError: () => {
                    setIsLoading(false);
                },
            });
        }
    };

    return (
        <div className="companies-edit content-wrapper with-header">
            <form onSubmit={onSubmit} noValidate>
                <NavigationHeader
                    detailTitle={companyData?.name ?? "Company's edit"}
                >
                    <CompaniesNavigation
                        defaultStatus={companyData?.status}
                        submitButtonTitle="Save company"
                        isDisabled={isLoading}
                        isLoading={isLoading}
                    />
                </NavigationHeader>
                <div className="row">
                    <div className="col-lg-6">
                        {status === 'success' ? (
                            <FormGenerator
                                settings={companyConfig}
                                defaultData={companyData}
                            />
                        ) : (
                            <Loader />
                        )}
                    </div>
                    <CompaniesSidebar />
                </div>
            </form>
        </div>
    );
};
