import { ButtonGBI } from '@components/ButtonGBI/ButtonGBI';
import { FormGenerator } from '@components/Forms/FormGenerator';
import { TanStackTable } from '@components/Table/TanStackTable';
import { guidelinesFormConfig } from '@pages/jurisdiction/Guidelines/GuidelinesFormConfig';
import { guidelinesTableSetup } from '@pages/jurisdiction/Guidelines/GuidelinesTableConfig';
import { getPostMutationObject } from '@utils/getPostMutationObject';
import { isEditCreatePage } from '@utils/utils';
import React, { useState } from 'react';
import { useLocation } from 'react-router';
import { useSearchParams } from 'react-router-dom';

import './Guidelines.scss';

export enum GuidelineTypes {
    p10 = 'p_1_0',
    p20 = 'p_2_0',
    p30 = 'p_3_0',
    p31AttachmentDetail = 'p_3_1_attachment_detail',
    p4 = 'p_4',
    p41LdgDetail = 'p_4_1_ldg_detail',
    p5 = 'p_5',
    designGuidelines = 'design_guidelines',
    other = 'other',
}

export const guidelineTypes = [
    {
        id: GuidelineTypes.p10,
        valueToShow: 'P-1.0',
    },
    {
        id: GuidelineTypes.p20,
        valueToShow: 'P-2.0',
    },
    {
        id: GuidelineTypes.p30,
        valueToShow: 'P-3.0',
    },
    {
        id: GuidelineTypes.p31AttachmentDetail,
        valueToShow: 'P-3.1 - Attachment Detail',
    },
    {
        id: GuidelineTypes.p4,
        valueToShow: 'P-4',
    },
    {
        id: GuidelineTypes.p41LdgDetail,
        valueToShow: 'P-4.1 - LDG Detail',
    },
    {
        id: GuidelineTypes.p5,
        valueToShow: 'P-5',
    },
    {
        id: GuidelineTypes.designGuidelines,
        valueToShow: 'Design Guidelines',
    },
    {
        id: GuidelineTypes.other,
        valueToShow: 'Other',
    },
];

export const GuidelinesCreate = ({
    guidelinesData,
    setGuidelinesData,
}: {
    guidelinesData: any;
    setGuidelinesData: any;
}) => {
    const [guidelineCategory, setGuidelineCategory] = useState('');
    const [searchParams, setSearchParams] = useSearchParams();
    const location = useLocation();
    const config = guidelinesFormConfig(guidelineTypes, setGuidelineCategory);

    const onSubmit = (e: any) => {
        e.preventDefault();

        setSearchParams(undefined);

        const formData: any = getPostMutationObject(e, config);
        // todo categoryToShow will be used later in table
        const categoryToShow = guidelineTypes.find(
            guidelineType => guidelineType.id === guidelineCategory
        )?.valueToShow;
        formData.category = guidelineCategory;

        setGuidelinesData([...guidelinesData, formData]);
    };

    return (
        <div className="guidelines-create">
            <form onSubmit={e => onSubmit(e)}>
                <div className="guidelines-table">
                    <h4>Guidelines</h4>
                    {isEditCreatePage() && (
                        <ButtonGBI
                            variant="secondary"
                            onClick={() =>
                                setSearchParams('addNewRule', { replace: true })
                            }
                        >
                            Add new rule
                        </ButtonGBI>
                    )}
                </div>

                {guidelinesData.length > 0 && (
                    <TanStackTable
                        className="sidebar full-size"
                        tableSetup={guidelinesTableSetup()}
                        propData={guidelinesData}
                        createButtonTitle="Add new rule"
                        isDropdownEnabled={false}
                        isFooterEnabled={false}
                        tableHeaderActions={false}
                        isRowClickable={false}
                    />
                )}

                {location.search.includes('addNewRule') && (
                    <FormGenerator
                        settings={config}
                        saveButton="Save"
                        defaultData={{
                            category: guidelineCategory,
                        }}
                    />
                )}
            </form>
        </div>
    );
};
