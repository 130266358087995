import { CancelSubmit } from '@components/NavigationHeader/parts/cancelSubmit/CancelSubmit';
import { EditDelete } from '@components/NavigationHeader/parts/editDelete/EditDelete';
import { isEditCreatePage } from '@utils/utils';
import React from 'react';


export const AhjNavigation = ({
    submitButtonTitle = 'Save new',
    handleDelete,
    isLoading = false,
    isDisabled = false,
}: {
    submitButtonTitle?: string;
    handleDelete?: (id: string) => void;
    isLoading?: boolean;
    isDisabled?: boolean;
}) => {
    return isEditCreatePage() ? (
        <CancelSubmit
            submitButtonTitle={submitButtonTitle}
            isLoading={isLoading}
            isDisabled={isDisabled}
            location="jurisdiction/ahj"
        />
    ) : (
        <EditDelete handleDelete={handleDelete} />
    );
};
