import { CommentsSection } from '@components/Comments/CommentsSection';
import { useGetCommentsByProjectId } from '@queries/comments.query';
import React from 'react';
import { useParams } from 'react-router';

export const ProjectComments = ({ commentId }: { commentId?: string }) => {
    const params = useParams();
    const projectId = commentId ?? params.id ?? '';
    const { data: commentsData } = useGetCommentsByProjectId(projectId);

    return (
        <CommentsSection
            commentsData={commentsData}
            commentFor="project"
            commentId={commentId}
        />
    );
};
