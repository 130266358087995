import useUrlState from '@ahooksjs/use-url-state';
import { ReactComponent as EmptyProjectsIcon } from '@assets/emptyProjectsIcon.svg';
import { ReactComponent as ProjectsIcon } from '@assets/projectsIcon.svg';
import { DetailTitle } from '@components/DetailTitle/DetailTitle';
import { EmptyPage } from '@components/EmptyPage/EmptyPage';
import { Loader } from '@components/Loader/Loader';
import { ConfirmationModal } from '@components/Modal/ConfirmationModal';
import { Pagination } from '@components/Pagination/Pagination';
import { useDeleteProject, useGetProjects } from '@queries/projects.query';
import { getGroup } from '@utils/getGroup';
import { isObjectEmpty } from '@utils/utils';
import React, {useEffect, useState} from 'react';
import { useNavigate } from 'react-router';

import './Projects.scss';
import { ProjectsTable } from './ProjectsTable';
import { projectsTableSetup } from './ProjectsTableConfig';

export const Projects = () => {
    const [urlState, setUrlState] = useUrlState();
    const isFiltered = !isObjectEmpty(urlState);
    const navigate = useNavigate();
    const { data: projectsData, fetchStatus } = useGetProjects();
    const deleteMutation = useDeleteProject();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [projectDataToDelete, setProjectDataToDelete] = useState({
        projectId: '',
        projectName: '',
    });
    const group = getGroup();

    const handleEdit = (data: any) => {
        return navigate(`/${getGroup()}/projects/${data.row.original.id}/edit`);
    };

    const handleConfirmationModalShow = (data: any) => {
        const projectName = data.row.original.name;
        const projectId = data.row.original.id;
        setProjectDataToDelete({ projectName, projectId });
        setIsModalVisible(true);
    };

    const handleDelete = (projectId: string) => {
        deleteMutation.mutate(projectId);
    };

    const onConfirmationDeleteCancel = () => {
        setIsModalVisible(false);
    };

    const getProjectsComponent = () => {
        if (fetchStatus === 'fetching') {
            return <Loader />;
        }

        if (
            fetchStatus === 'idle' &&
            (projectsData?.data?.length > 0 || isFiltered)
        ) {
            return (
                <ProjectsTable
                    propData={projectsData.data}
                    tableSetup={projectsTableSetup(
                        handleConfirmationModalShow,
                        handleEdit,
                        urlState,
                        setUrlState
                    )}
                />
            );
        }

        if (
            fetchStatus === 'idle' &&
            projectsData?.data?.length === 0 &&
            !isFiltered
        ) {
            return (
                <EmptyPage
                    title="Looks like noone has added a project yet"
                    to={`/${getGroup()}/projects/1/create`}
                    buttonName="Create new project"
                    icon={<EmptyProjectsIcon />}
                />
            );
        }

        return null;
    };

    useEffect(() => {
        if (
            group === 'admin' ||
            group === 'designer' ||
            group === 'pe-engineer' ||
            group === 'ee-engineer'
        ) {
            if (Object.keys(urlState).length === 0) {
                setUrlState({ 'filter.status': 'not_done' });
            }
        }
    }, [group]);

    return (
        <div className="projects-page content-wrapper">
            <DetailTitle title="Projects" icon={<ProjectsIcon />} />

            {getProjectsComponent()}

            <Pagination
                currentPage={projectsData?.meta?.currentPage}
                totalPages={projectsData?.meta?.totalPages}
                prefix="projects"
            />
            <ConfirmationModal
                isVisible={isModalVisible}
                onConfirmed={handleDelete}
                projectData={projectDataToDelete}
                onCanceled={onConfirmationDeleteCancel}
            />
        </div>
    );
};
