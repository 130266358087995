import { ProjectStatus, useEditProject } from '@queries/projects.query';
import { useQueryClient } from '@tanstack/react-query';
import { getStatus, getStatusClasses } from '@utils/statuses';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';


export const StatusDropdown = ({
    defaultValue,
}: {
    defaultValue: ProjectStatus | undefined;
}) => {
    const client = useQueryClient();
    const [status, setStatus] = useState(defaultValue);
    const params = useParams();
    const projectsMutation = useEditProject(params.id ?? '');

    const statusToRender = defaultValue && getStatus(defaultValue);

    const onSubmit = () => {
        if (status)
            return projectsMutation.mutate(
                {
                    status,
                },
                {
                    onSuccess: () => {
                        toast.success('Project status changed successfully');
                        return client.invalidateQueries({
                            queryKey: ['projects'],
                        });
                    },
                }
            );
    };

    useEffect(() => {
        if (status !== defaultValue) {
            onSubmit();
        }
    }, [status]);

    return (
        <div className="status-change-dropdown">
            <div className="dropdown">
                <button
                    className={classNames(
                        'btn dropdown-toggle',
                        defaultValue && getStatusClasses(defaultValue)
                    )}
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                >
                    {statusToRender}
                </button>
                <ul className="dropdown-menu">
                    <li>
                        <span
                            role="button"
                            tabIndex={0}
                            className="dropdown-item"
                            onClick={() => setStatus(ProjectStatus.OPEN)}
                        >
                            Open
                        </span>
                    </li>
                    <li>
                        <span
                            role="button"
                            tabIndex={0}
                            className="dropdown-item"
                            onClick={() => setStatus(ProjectStatus.IN_PROGRESS)}
                        >
                            In progress
                        </span>
                    </li>
                    <li>
                        <span
                            role="button"
                            tabIndex={0}
                            className="dropdown-item"
                            onClick={() => setStatus(ProjectStatus.BLOCKED)}
                        >
                            Blocked
                        </span>
                    </li>
                    <li>
                        <span
                            role="button"
                            tabIndex={0}
                            className="dropdown-item"
                            onClick={() => setStatus(ProjectStatus.NOT_DONE)}
                        >
                            Not done
                        </span>
                    </li>
                </ul>
            </div>
        </div>
    );
};
