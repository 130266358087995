import { GuidelinesDetails } from '@components/Guidelines/GuidelinesDetails';
import { GuidelinesSelect } from '@components/Guidelines/GuidelinesSelect';
import { GuidelinesSelectEdit } from '@components/Guidelines/GuidelinesSelectEdit';
import { Loader } from '@components/Loader/Loader';
import { TasksTable } from '@pages/tasks/TasksTable';
import { tasksTableSidebarSetup } from '@pages/tasks/TasksTableSidebarConfig';
import { Task, useDeleteTask } from '@queries/tasks.query';
import { getGroup } from '@utils/getGroup';
import { isCreatePage, isDetailsPage, isEditPage } from '@utils/utils';
import classNames from 'classnames';
import React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router';

export const ProjectsSidebar = ({
    tasks,
    status,
}: {
    tasks: { data: Task[] };
    status: 'success' | 'pending' | 'error';
}) => {
    const params = useParams();
    const projectId = params.id ?? '';
    const location = useLocation();
    const navigate = useNavigate();
    const deleteTaskMutation = useDeleteTask();
    const handleTaskDelete = (data: any) => {
        deleteTaskMutation.mutate(data.row.original.id);
    };

    const handleTaskEdit = (data: any) => {
        return navigate(`/${getGroup()}/tasks/${data.row.original.id}/edit`);
    };

    const handleGuidelines = () => {
        if (isDetailsPage()) {
            return <GuidelinesDetails projectId={projectId} />;
        }

        if (isEditPage()) {
            return <GuidelinesSelectEdit projectId={projectId} />;
        }

        if (isCreatePage()) {
            return <GuidelinesSelect projectId={projectId} />;
        }

        return null;
    };

    return (
        <div
            className={classNames('sidebar-wrapper col-lg-6', {
                disabled: location.pathname.includes('edit'),
            })}
        >
            {status === 'success' ? (
                <>
                    <h4>Tasks</h4>
                    <TasksTable
                        projectId={projectId}
                        className="sidebar"
                        propData={tasks?.data}
                        tableSetup={tasksTableSidebarSetup(
                            handleTaskDelete,
                            handleTaskEdit
                        )}
                        isDropdownEnabled={false}
                        isFooterEnabled={false}
                    />
                </>
            ) : (
                <Loader />
            )}

            {handleGuidelines()}
        </div>
    );
};
