import { GetData } from '@components/Forms/helpers/GetLabelData';
import React from 'react';

import { globalType } from '../../FormGenerator';

export const AddressField = ({
    disabled,
    global,
    defaultValues,
}: {
    disabled: boolean;
    global?: globalType;
    defaultValues: any;
}) => {
    const addressValue = `${defaultValues?.address} ${defaultValues?.local} ${defaultValues?.zipCode} ${defaultValues?.city}`;

    if (disabled) {
        return (
            <div className="address-field">
                <div className="form-group">
                    <p>Address</p>
                    <GetData title={addressValue} global={global} />
                </div>
            </div>
        );
    }

    return (
        <div className="address-field">
            <div className="form-group">
                <div className="row">
                    <div className="col-lg-10">
                        <label htmlFor="address">Address</label>
                        <input
                            type="text"
                            className="form-control"
                            id="address"
                            disabled={disabled}
                            defaultValue={defaultValues?.address}
                        />
                    </div>
                    <div className="col-lg-2">
                        <label htmlFor="local">Local</label>
                        <input
                            type="text"
                            className="form-control"
                            id="local"
                            disabled={disabled}
                            defaultValue={defaultValues?.local}
                        />
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-2">
                        <label htmlFor="zipCode">ZIP-code</label>
                        <input
                            type="text"
                            className="form-control"
                            id="zipCode"
                            disabled={disabled}
                            defaultValue={defaultValues?.zipCode}
                        />
                    </div>
                    <div className="col-lg-10">
                        <label htmlFor="city">City</label>
                        <input
                            type="text"
                            className="form-control"
                            id="city"
                            disabled={disabled}
                            defaultValue={defaultValues?.city}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};
