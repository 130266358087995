// @ts-nocheck
import { ReactComponent as SortingArrowsIcon } from '@assets/sortingArrows.svg';
import { ColumnDef } from '@tanstack/react-table';
import { handleSort } from '@utils/sorting';
import React from 'react';

import { Users } from './UsersTable';


export const guidelinesTableSetup = (
    urlState?: any,
    setUrlState?: any
): ColumnDef<Users>[] => {
    return [
        {
            accessorKey: 'category',
            header: () => (
                <button
                    onClick={() =>
                        handleSort('category', urlState, setUrlState)
                    }
                >
                    Category
                    <SortingArrowsIcon />
                </button>
            ),
            filterTitle: 'Category',
        },
        {
            accessorKey: 'note',
            header: 'Note',
            filterTitle: 'Note',
        },
    ];
};
