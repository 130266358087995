import React, { useEffect } from 'react';
import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

export const ConfirmationModal = ({
    isVisible = false,
    onConfirmed,
    onCanceled,
    projectData,
}: {
    isVisible: boolean;
    onConfirmed: (projectId: string) => void;
    onCanceled: () => void;
    projectData: {
        projectId: string;
        projectName: string;
    };
}) => {
    const [show, setShow] = useState(isVisible);

    useEffect(() => {
        setShow(isVisible);
    }, [isVisible]);

    const handleClose = () => {
        setShow(false);
        onCanceled();
    };

    const handleConfirmed = () => {
        onConfirmed(projectData.projectId);
        handleClose();
    };

    return (
        <>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Delete confirmation</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure you want to delete{' '}
                    <b className={'text-danger'}>{projectData.projectName}</b> project?
                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-between">
                    <Button variant="secondary" onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button
                        variant="primary btn-danger"
                        onClick={handleConfirmed}
                    >
                        Confirm
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};
