import useUrlState from '@ahooksjs/use-url-state';
import { ReactComponent as SquareMinusIcon } from '@assets/square-minus.svg';
import { ButtonGBI } from '@components/ButtonGBI/ButtonGBI';
import { DropdownFilterAssignee } from '@components/Table/DropdownsAssignee';
import { taskTypes } from '@pages/tasks/TasksCreate';
import { CompanyStatus } from '@queries/companies.query';
import { JurisdictionTypes } from '@queries/jurisdiction.query';
import { ProjectStatus } from '@queries/projects.query';
import { TaskStatuses } from '@queries/tasks.query';
import { UserStatus } from '@queries/users.query';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';

type DropdownsFiltersProps = {
    options: {
        label: string;
        value: string;
        filterOption: Partial<{ [x: string]: any }>;
    }[];
    placeholder?: string;
    isMultiselect?: boolean;
};

export const DropdownsFilters = ({
    options,
    placeholder,
    isMultiselect,
}: DropdownsFiltersProps) => {
    const location = useLocation();
    const [urlState, setUrlState] = useUrlState();
    const navigate = useNavigate();

    const isFilterFor = Object.keys(options[0].filterOption)[0];
    const defaultOption = urlState[isFilterFor] ?? placeholder ?? 'Select';
    const [selectedOption, setSelectedOption] = useState(defaultOption);

    const decodedSearch = decodeURIComponent(location.search);
    const isSomethingSelected =
        selectedOption !== placeholder && selectedOption !== 'Select';

    const onChange = (option: {
        label: string;
        value: string;
        filterOption: Partial<{ [x: string]: any }>;
    }) => {
        // Multi-filters
        if (isMultiselect) {
            const filterOption = Object.keys(option.filterOption)[0];

            // when url has no filters
            if (
                !location.search.includes(Object.keys(option.filterOption)[0])
            ) {
                return setUrlState({ [filterOption]: `$in:${option.value}` });
            } else {
                // when there is already this filter - remove it
                if (decodedSearch.includes(option.value)) {
                    let newSearch = decodedSearch.split(option.value).join('');

                    // Remove comma
                    if (newSearch[newSearch.length - 1] === ',') {
                        newSearch = newSearch.slice(0, -1);
                    }

                    // Last filter removed
                    if (newSearch.split(':')[1] === '') {
                        const fullUrl = location.pathname + newSearch;
                        if (fullUrl.split(newSearch)[1] === '') {
                            return navigate(location.pathname);
                        }
                    }

                    return navigate(newSearch);
                }

                return navigate(`${decodedSearch},${option.value}`);
            }
        } else {
            // this will work for all other single filters
            setUrlState(option.filterOption);
            setSelectedOption(option.label);
        }
    };

    useEffect(() => {
        if (isMultiselect) {
            const filterOption = Object.keys(options[0].filterOption)[0];
            const filterValues = decodedSearch
                .split(`${filterOption}=$in:`)[1]
                ?.split(/,|%2C/g);

            if (filterValues) {
                // this will make sure that the last filter value is not merged wit other params
                filterValues[filterValues.length - 1] =
                    filterValues[filterValues.length - 1].split('&')[0];

                const selectedOptions = options
                    .filter(option => filterValues.includes(option.value))
                    .map(option => option.label);

                if (selectedOptions.length === 1) {
                    setSelectedOption(selectedOptions[0]);
                } else {
                    setSelectedOption(selectedOptions.join(', '));
                }
            }
        } else {
            setSelectedOption(
                options.find(option => option.value === defaultOption)?.label ??
                    placeholder ??
                    'Select'
            );
        }
    }, [defaultOption]);

    const handleResetFilter = () => {
        setUrlState({ [isFilterFor]: undefined });
    };

    return (
        <div
            className={classNames('dropdown', {
                'is-selected': isSomethingSelected,
            })}
        >
            <ButtonGBI
                className="btn-select dropdown-toggle"
                data-bs-toggle="dropdown"
                aria-expanded="false"
            >
                {selectedOption}
            </ButtonGBI>
            <ul className="dropdown-menu">
                {options.map(option => {
                    const isActive = decodedSearch.includes(option.value);

                    return (
                        <li key={option.value}>
                            <span
                                role="button"
                                tabIndex={0}
                                className={classNames('dropdown-item', {
                                    active: isActive,
                                })}
                                onClick={() => onChange(option)}
                            >
                                {option.label}
                            </span>
                        </li>
                    );
                })}
            </ul>
            {isSomethingSelected && (
                <span
                    className="reset-filter"
                    onClick={() => handleResetFilter()}
                >
                    <SquareMinusIcon />
                </span>
            )}
        </div>
    );
};

export const DropdownFilter = ({
    dropdownFiltersFor,
    currentUserId,
    isMultiselect = false,
}: {
    dropdownFiltersFor: string;
    currentUserId?: string;
    isMultiselect?: boolean;
}) => {
    switch (dropdownFiltersFor) {
        case 'companies':
            return (
                <DropdownsFilters
                    placeholder="Status"
                    options={[
                        {
                            label: 'Active',
                            value: CompanyStatus.ACTIVE,
                            filterOption: {
                                ['filter.status']: CompanyStatus.ACTIVE,
                            },
                        },
                        {
                            label: 'Inactive',
                            value: CompanyStatus.IN_ACTIVE,
                            filterOption: {
                                ['filter.status']: CompanyStatus.IN_ACTIVE,
                            },
                        },
                    ]}
                />
            );
        case 'projects':
            return (
                <DropdownsFilters
                    placeholder="Status"
                    options={[
                        {
                            label: 'Open',
                            value: ProjectStatus.OPEN,
                            filterOption: {
                                'filter.status': ProjectStatus.OPEN,
                            },
                        },
                        {
                            label: 'In progress',
                            value: ProjectStatus.IN_PROGRESS,
                            filterOption: {
                                'filter.status': ProjectStatus.IN_PROGRESS,
                            },
                        },
                        {
                            label: 'Blocked',
                            value: ProjectStatus.BLOCKED,
                            filterOption: {
                                'filter.status': ProjectStatus.BLOCKED,
                            },
                        },
                        {
                            label: 'Done',
                            value: ProjectStatus.DONE,
                            filterOption: {
                                'filter.status': ProjectStatus.DONE,
                            },
                        },
                        {
                            label: 'Not Done',
                            value: ProjectStatus.NOT_DONE,
                            filterOption: {
                                'filter.status': ProjectStatus.NOT_DONE,
                            },
                        },
                    ]}
                />
            );
        case 'tasks':
            return (
                <>
                    <DropdownsFilters
                        placeholder="Status"
                        options={[
                            {
                                label: 'Open',
                                value: TaskStatuses.OPEN,
                                filterOption: {
                                    'filter.status': TaskStatuses.OPEN,
                                },
                            },
                            {
                                label: 'In progress',
                                value: TaskStatuses.IN_PROGRESS,
                                filterOption: {
                                    'filter.status': TaskStatuses.IN_PROGRESS,
                                },
                            },
                            {
                                label: 'Closed',
                                value: TaskStatuses.CLOSED,
                                filterOption: {
                                    'filter.status': TaskStatuses.CLOSED,
                                },
                            },
                            {
                                label: 'Not closed',
                                value: `$not:${TaskStatuses.CLOSED}`,
                                filterOption: {
                                    'filter.status': `$not:${TaskStatuses.CLOSED}`,
                                },
                            },
                            {
                                label: 'Backlog',
                                value: TaskStatuses.BACKLOG,
                                filterOption: {
                                    'filter.status': TaskStatuses.BACKLOG,
                                },
                            },
                        ]}
                    />

                    {/* todo replace with working company filter */}
                    {/*<DropdownsFilters*/}
                    {/*    placeholder="Company"*/}
                    {/*    options={[*/}
                    {/*        {*/}
                    {/*            label: 'Walter',*/}
                    {/*            value: '01HP4G47GTEDWEXY3QZXHVJZBK',*/}
                    {/*            filterOption: {*/}
                    {/*                ['filter.project.company.id']:*/}
                    {/*                    '01HP4G47GTEDWEXY3QZXHVJZBK',*/}
                    {/*            },*/}
                    {/*        },*/}
                    {/*        {*/}
                    {/*            label: 'In progress',*/}
                    {/*            value: TaskStatuses.IN_PROGRESS,*/}
                    {/*            filterOption: {*/}
                    {/*                ['filter.status']: TaskStatuses.IN_PROGRESS,*/}
                    {/*            },*/}
                    {/*        },*/}
                    {/*    ]}*/}
                    {/*/>*/}

                    <DropdownsFilters
                        placeholder="Type"
                        options={taskTypes.map(type => {
                            return {
                                label: type.valueToShow,
                                value: type.id,
                                filterOption: {
                                    ['filter.type']: type.id,
                                },
                            };
                        })}
                    />

                    <DropdownFilterAssignee currentUserId={currentUserId} />
                </>
            );

        case 'jurisdiction':
            return (
                <DropdownsFilters
                    placeholder="Type"
                    isMultiselect={isMultiselect}
                    options={[
                        {
                            label: 'AHJ',
                            value: JurisdictionTypes.AHJ,
                            filterOption: {
                                ['filter.type']: JurisdictionTypes.AHJ,
                            },
                        },
                        {
                            label: 'Company',
                            value: JurisdictionTypes.COMPANY,
                            filterOption: {
                                ['filter.type']: JurisdictionTypes.COMPANY,
                            },
                        },
                        {
                            label: 'Other',
                            value: JurisdictionTypes.OTHER,
                            filterOption: {
                                ['filter.type']: JurisdictionTypes.OTHER,
                            },
                        },
                        {
                            label: 'Utility',
                            value: JurisdictionTypes.UTILITY,
                            filterOption: {
                                ['filter.type']: JurisdictionTypes.UTILITY,
                            },
                        },
                    ]}
                />
            );

        case 'users':
            return (
                <DropdownsFilters
                    placeholder="Status"
                    options={[
                        {
                            label: 'Active',
                            value: UserStatus.ACTIVE,
                            filterOption: {
                                'filter.status': UserStatus.ACTIVE,
                            },
                        },
                        {
                            label: 'Disabled',
                            value: UserStatus.DISABLED,
                            filterOption: {
                                'filter.status': UserStatus.DISABLED,
                            },
                        },
                    ]}
                />
            );
        default:
            return null;
    }
};
