import fetchGBI from '@queries/fetchGBI';
import { UserGroups, UserStatus } from '@queries/users.query';
import {
    UseQueryResult,
    useMutation,
    useQuery,
    useQueryClient,
} from '@tanstack/react-query';
import axios from 'axios';

import { DefaultBaseURL } from './urlHelpers';

const baseApiUrl = process.env.REACT_APP_BASE_API_URL;

export const useGetLogin = (data: { email: string; password: string }) =>
    useQuery({
        queryKey: ['loginToken', data.email],
        queryFn: () => axios.post(`${baseApiUrl}/api/v1/auth/signin`, data),
    });

export const useGetUserInfo = (): UseQueryResult<{
    address: string;
    cognitoSub: string;
    email: string;
    emailConfirmed: boolean;
    firstName: string;
    group: UserGroups;
    id: string;
    lastName: string;
    settings: any;
    status: UserStatus;
    supervisor: string;
}> => {
    const userToken = localStorage.getItem('token');

    return useQuery({
        queryKey: ['userInfo', userToken],
        queryFn: () => fetchGBI(DefaultBaseURL + '/users/userinfo'),
    });
};

export const useGetVerificationCode = () => {
    const client = useQueryClient();

    return useMutation({
        mutationFn: async ({
            email,
            code,
        }: {
            email: string;
            code: string;
        }) => {
            const {
                data: { verificationCode },
            } = await axios.post(DefaultBaseURL + '/auth/verify', {
                email,
                code,
            });
            return {
                verificationCode,
            };
        },
        onSuccess: () => {
            return client.invalidateQueries({ queryKey: ['verificationCode'] });
        },
    });
};

export const useResendVerificationCode = () => {
    const client = useQueryClient();

    return useMutation({
        mutationFn: async (email: string) => {
            const {
                data: { verificationCode },
            } = await axios.post(DefaultBaseURL + '/auth/resend', {
                email,
            });
            return {
                verificationCode,
            };
        },
        onSuccess: () => {
            return client.invalidateQueries({ queryKey: ['verificationCode'] });
        },
    });
};

export const useForgotPassword = () => {
    const client = useQueryClient();

    return useMutation({
        mutationFn: async (email: string) => {
            const {
                data: { forgotPassword },
            } = await axios.post(DefaultBaseURL + '/auth/forgot-password', {
                email,
            });
            return {
                forgotPassword,
            };
        },
        onSuccess: () => {
            return client.invalidateQueries({ queryKey: ['forgotPassword'] });
        },
    });
};

export const useNewPassword = () => {
    const client = useQueryClient();

    return useMutation({
        mutationFn: async (data: {
            email: string;
            password: string;
            code: string;
        }) => {
            const {
                data: { newPassword },
            } = await axios.post(DefaultBaseURL + '/auth/new-password', data);
            return {
                newPassword,
            };
        },
        onSuccess: () => {
            return client.invalidateQueries({ queryKey: ['newPassword'] });
        },
    });
};
