import { useGetFileById } from '@queries/files.query';
import { useEffect } from 'react';
import { toast } from 'react-toastify';

type SingleFileProps = {
    id: string;
    setIsDownload?: (isDownload: boolean) => void;
};

export const DownloadFile = ({ id, setIsDownload }: SingleFileProps) => {
    const { data: fileData } = useGetFileById(id);

    const saveFile = () => {
        if (fileData === undefined) {
            return;
        }

        const a = document.createElement('a');
        a.className = 'auto-download-file';
        a.download = fileData.name;
        a.href = fileData.url;
        document.body.appendChild(a);
        a.click();

        document.body.removeChild(a);
        setIsDownload && setIsDownload(false);
        toast.success('File downloaded successfully');

        return;
    };

    useEffect(() => {
        if (fileData) {
            saveFile();
        }
    }, [fileData?.url]);

    return null;
};
