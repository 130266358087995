import { TanStackTable } from '@components/Table/TanStackTable';
import { INewProject } from '@queries/projects.query';
import { getGroup } from '@utils/getGroup';
import React from 'react';

type ProjectsTableProps = {
    tableSetup: any;
    propData?: any;
    isDropdownEnabled?: boolean;
    isFooterEnabled?: boolean;
    className?: string;
};

export interface Projects extends INewProject {
    subRows?: Projects[];
}

export const ProjectsTable = ({
    propData,
    tableSetup,
    isDropdownEnabled = true,
    isFooterEnabled = true,
    className,
}: ProjectsTableProps) => {
    const group = getGroup();
    const propDataWithProducts = propData.map((data: any) => {
        const productNames = data?.products?.map(
            (product: any) => product.name
        );

        return {
            ...data,
            productsList: productNames?.join(', '), // this creates a string of product names separated by commas
            productNames: productNames?.join('; '), // this creates a string of product names separated by semicolons - each product is in separate column
        };
    });

    const CSVColumns = [
        {
            id: 'highlightedColumn',
            displayName: 'Projects',
        },
        {
            id: 'name',
            displayName: 'Name',
        },
        {
            id: 'company.name',
            displayName: 'Company name',
        },
        {
            id: 'dueDate',
            displayName: 'Due date',
        },
        {
            id: 'owner.firstName',
            displayName: 'Owner',
        },
        {
            id: 'createdAt',
            displayName: 'Created',
        },
        {
            id: 'updatedAt',
            displayName: 'Last modified',
        },
        {
            id: 'projectAge',
            displayName: 'Project age',
        },
        {
            id: 'status',
            displayName: 'Status',
        },
        {
            id: 'productsList',
            displayName: 'Products list',
        },
        {
            id: 'productNames',
            displayName: 'Products',
        },
    ];

    return (
        <TanStackTable
            className={className}
            tableSetup={tableSetup}
            propData={propDataWithProducts}
            createButtonTitle="Create new project"
            isCreateButtonEnabled={
                group !== 'ee-engineer' && group !== 'pe-engineer'
            }
            linkTo={`/${getGroup()}/projects`}
            dropdownFiltersFor="projects"
            isDropdownEnabled={isDropdownEnabled}
            isFooterEnabled={isFooterEnabled}
            isCSVButtonEnabled={true}
            CSVColumns={CSVColumns}
        />
    );
};
