import { GuidelinesDetailsByCategory } from '@components/Guidelines/GuidelinesDetailsByCategory';
import { GuidelineTypes } from '@pages/jurisdiction/Guidelines/GuidelinesCreate';
import { useGetAllGuidelinesByProjectId } from '@queries/guidelines.query';
import React from 'react';

export const GuidelinesDetails = ({
    projectId,
    showTitle = true,
}: {
    projectId: string;
    showTitle?: boolean;
}) => {
    const { data: allGuidelines } = useGetAllGuidelinesByProjectId(projectId);
    const availableGuidelinesTypes = Object.values(GuidelineTypes);

    return (
        <div className="guidelines-details">
            {showTitle && <h4>Guidelines</h4>}
            <div className="details">
                {allGuidelines?.length > 0 ? (
                    availableGuidelinesTypes.map(guidelineType => {
                        return (
                            <GuidelinesDetailsByCategory
                                key={guidelineType}
                                projectId={projectId}
                                data={allGuidelines}
                                type={guidelineType}
                            />
                        );
                    })
                ) : (
                    <div>No guidelines selected</div>
                )}
            </div>
        </div>
    );
};
