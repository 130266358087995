import useUrlState from '@ahooksjs/use-url-state';
import { PaginationPrevNext } from '@components/Pagination/PaginationPrevNext';
import React, { useEffect, useState } from 'react';

import './Pagination.scss';


export const Pagination = ({
    currentPage,
    totalPages,
    prefix,
}: {
    currentPage: number;
    totalPages: number;
    prefix: string;
}) => {
    const [urlState, setUrlState] = useUrlState();

    const search = urlState['search_' + prefix]
        ? urlState['search_' + prefix]
        : undefined;

    const [pageNumber, setPageNumber] = useState(1);
    const [direction, setDirection] = useState('');

    const [isNextAvailable, setIsNextAvailable] = useState(true);
    const [isPrevAvailable, setIsPrevAvailable] = useState(false);

    const handleNextPage = () => {
        setPageNumber(pageNumber + 1);
        setDirection('next');
    };

    const handlePreviousPage = () => {
        setPageNumber(pageNumber - 1);
        setDirection('previous');
    };

    // Reset page number when search is changed
    useEffect(() => {
        if (search !== undefined) {
            setUrlState({
                ...urlState,
                page: undefined,
            });
        }
    }, [search]);

    // Manage URL param page number
    useEffect(() => {
        const page = urlState.page ? Number(urlState.page) : 0;

        if (direction === 'next') {
            return setUrlState({
                ...urlState,
                page: currentPage + 1,
            });
        }

        if (direction === 'previous') {
            return setUrlState({
                ...urlState,
                page: page - 1,
            });
        }
    }, [pageNumber]);

    // Manage pagination buttons
    useEffect(() => {
        if (currentPage === 1) {
            setIsPrevAvailable(false);
        } else {
            setIsPrevAvailable(true);
        }

        if (currentPage === totalPages) {
            setIsNextAvailable(false);
        } else {
            setIsNextAvailable(true);
        }
    }, [currentPage]);

    if (totalPages > 1) {
        return (
            <PaginationPrevNext
                isPrevAvailable={isPrevAvailable}
                isNextAvailable={isNextAvailable}
                currentPage={currentPage}
                totalPages={totalPages}
                handleNextPage={handleNextPage}
                handlePreviousPage={handlePreviousPage}
            />
        );
    }

    return null;
};
