import { ButtonGBI } from '@components/ButtonGBI/ButtonGBI';
import { FilesUpload } from '@components/FilesUpload/FilesUpload';
import { SearchableDropdownForForm } from '@components/Forms/components/searchableDropdownForForm/SearchableDropdownForForm';
import { IResourceFileResponse } from '@queries/files.query';
import { isEditCreatePage } from '@utils/utils';
import classNames from 'classnames';
import React from 'react';
import { useLocation } from 'react-router';

import './Forms.scss';
import { AddressField } from './components/addressField/AddressField';
import { CheckboxField } from './components/checkboxField/CheckboxField';
import { DropdownField } from './components/dropdownField/DropdownField';
import { InputField } from './components/inputField/InputField';
import { JurisdictionTypeField } from './components/jurisdictionTypeField/JurisdictionTypeField';
import { SeparateLine } from './components/separateLine/SeparateLine';
import { TextareaField } from './components/textareaField/TextareaField';

export enum FieldType {
    Input = 'input',
    Address = 'address',
    Select = 'select',
    Dropdown = 'dropdown',
    SearchableDropdown = 'searchableDropdown',
    Textarea = 'textarea',
    Checkbox = 'checkbox',
    Radio = 'radio',
    Date = 'date',
    JurisdictionType = 'jurisdictionType',
    FilesUpload = 'filesUpload',
    Hr = 'hr',
}

export enum Type {
    Text = 'text',
    Number = 'number',
    Tel = 'tel',
    Textarea = 'textarea',
    Date = 'date',
    DatetimeLocal = 'datetime-local',
    Checkbox = 'checkbox',
    Time = 'time',
    Email = 'email',
    Link = 'link',
    Password = 'password',
}

export enum SubType {
    Currency = 'currency', // this makes Form Generator to render data with prefix '$'
    FullName = 'fullName', // this will merge two fields into one - First and Last name
}

export type configType = {
    fieldType: FieldType;
    type: Type;
    subType?: SubType;
    name: string;
    title: string;
    selectData?: { id: string; valueToShow: string }[];
    onChange?: (data: any) => void;
    files?: File[];
    url?: string;
    unsetOption?: boolean;
    resourceId?: string;
    attachId?: string;
    selectedType?: string;
    deliverable?: boolean;
    isOldFilesShown?: boolean;
    isUploadLogo?: boolean;
    valueToShowKey?: string;
    dataSourceUrlPath?: string;
};

export type globalType = {
    viewMode?: {
        hidden?: boolean; // this makes Form Generator to hide field in view mode

        // Label settings
        boldLabel?: boolean; // this makes Form Generator to render label with class 'fw-bold'
        inlineLabel?: boolean; // this makes Form Generator to render label with class 'd-inline-block pe-4'

        // Data settings
        boldData?: boolean; // this makes Form Generator to render data with class 'fw-bold'
        featuredData?: boolean; // this makes Form Generator to render data with class 'featured'
        inlineData?: boolean; // this makes Form Generator to render data with class 'd-inline-block'
        prefixData?: string; // this makes Form Generator to render data with prefix
        disabled?: boolean; // this makes Form Generator to render data as disabled
    };
    editMode?: {
        disabled?: boolean; // this makes Form Generator to render data as disabled
    };
};

export type validationType = {
    required?: boolean;
    errorMessage?: string;
    minLength?: number;
    maxLength?: number;
    pattern?: string;
};

export type settingsType = {
    config: configType;
    validation?: validationType;
    global?: globalType;
};

export interface IDetailsUIGeneratorProps {
    settings: settingsType[];
    defaultData?: any;
    formTitle?: string;
    saveButton?: string;
    forceEdit?: boolean;
    withDetailsWrapper?: boolean;
    onFileUploaded?: (files: IResourceFileResponse[]) => void;
}

export const FormGenerator = ({
    settings,
    defaultData,
    formTitle,
    saveButton,
    forceEdit = false,
    withDetailsWrapper = true,
    onFileUploaded,
}: IDetailsUIGeneratorProps) => {
    const location = useLocation();
    const isEnabled = forceEdit || isEditCreatePage();

    const getFieldTypeComponent = (setting: settingsType) => {
        const { fieldType, title } = setting.config;

        switch (fieldType) {
            case FieldType.Hr:
                return <SeparateLine title={title} />;
            case FieldType.Input:
                return (
                    <InputField
                        config={setting.config}
                        validation={setting.validation}
                        global={setting?.global}
                        disabled={!isEnabled}
                        defaultData={defaultData}
                    />
                );

            case FieldType.Address:
                return (
                    <AddressField
                        global={setting.global}
                        disabled={!isEnabled}
                        defaultValues={defaultData}
                    />
                );
            case FieldType.Select:
                return (
                    <DropdownField
                        config={setting.config}
                        global={setting.global}
                        validation={setting.validation}
                        disabled={!isEnabled}
                        defaultData={defaultData}
                        unsetOption={setting.config.unsetOption}
                    />
                );
            case FieldType.Textarea:
                return (
                    <TextareaField
                        config={setting.config}
                        global={setting.global}
                        validation={setting.validation}
                        disabled={!isEnabled}
                        defaultData={defaultData}
                    />
                );
            case FieldType.Checkbox:
                return (
                    <CheckboxField
                        config={setting.config}
                        global={setting.global}
                        disabled={!isEnabled}
                        defaultData={defaultData}
                    />
                );
            case FieldType.JurisdictionType:
                return (
                    <JurisdictionTypeField
                        config={setting.config}
                        global={setting.global}
                        disabled={!isEnabled}
                        defaultData={defaultData}
                    />
                );
            case FieldType.FilesUpload:
                return (
                    <FilesUpload
                        config={setting.config}
                        global={setting.global}
                        validation={setting.validation}
                        disabled={!isEnabled}
                        defaultData={defaultData}
                        onFileUploaded={onFileUploaded}
                    />
                );
            case FieldType.SearchableDropdown:
                return (
                    <SearchableDropdownForForm
                        config={setting.config}
                        validation={setting.validation}
                        valueToShowKey={setting.config.valueToShowKey}
                        dataSourceUrlPath={
                            setting.config.dataSourceUrlPath ?? ''
                        }
                    />
                );
            default:
                return (
                    <div className="form-group">
                        <label htmlFor="name">Name of the company</label>
                        <input type="text" className="form-control" id="name" />
                    </div>
                );
        }
    };

    return (
        <div
            className={classNames('form-generator', {
                details: withDetailsWrapper,
                edit: location.pathname.includes('edit'),
                create: location.pathname.includes('create'),
            })}
        >
            {formTitle && <h3>{formTitle}</h3>}
            {settings.map(setting => {
                const key =
                    setting.config.name != ''
                        ? setting.config.name
                        : setting.config.title;
                return (
                    <div
                        className={classNames('field-wrapper', {
                            'needs-validation': setting.validation?.required,
                        })}
                        key={key}
                    >
                        {getFieldTypeComponent(setting)}
                        <div className="error-text">
                            {setting.validation?.errorMessage}
                        </div>
                    </div>
                );
            })}

            {saveButton && (
                <div className="save-button d-flex justify-content-between mt-4">
                    <ButtonGBI type="submit" className="ms-auto">
                        {saveButton}
                    </ButtonGBI>
                </div>
            )}
        </div>
    );
};
