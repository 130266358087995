import { ButtonGBI } from '@components/ButtonGBI/ButtonGBI';
import React from 'react';

interface IButtonProps {
    type?: 'button' | 'submit' | 'reset';
    title?: string;
    isDisabled?: boolean;
    isLoading?: boolean;
    onClick?: () => void;
    children?: React.ReactNode;
}

export const SubmitGBI = ({
    type = 'submit',
    title = 'Submit',
    isDisabled = false,
    isLoading = false,
    onClick,
    children,
    ...props
}: IButtonProps) => {
    return (
        <ButtonGBI
            type={type}
            disabled={isDisabled}
            {...props}
            onClick={onClick}
        >
            {children}
            {title}
            {isLoading && (
                <span
                    className="spinner-border spinner-border-sm"
                    style={{ marginLeft: '1rem' }}
                />
            )}
        </ButtonGBI>
    );
};
