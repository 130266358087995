import { SubmitGBI } from '@components/ButtonGBI/SubmitGBI';
import { FormGenerator } from '@components/Forms/FormGenerator';
import { newPasswordFormConfig } from '@pages/verification/newPasswordConfig';
import { useNewPassword } from '@queries/login.query';
import React from 'react';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import './Verification.scss';

export const NewPassword = () => {
    const config = newPasswordFormConfig();
    const navigate = useNavigate();
    const newPasswordMutation = useNewPassword();

    const [searchParams] = useSearchParams();

    const searchParamsData = new URLSearchParams(searchParams);

    const email = searchParamsData.get('email')?.replace(/\s/g, '+');
    const code = searchParamsData.get('code');

    const [isLoading, setIsLoading] = React.useState(false);

    const onSubmit = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
        setIsLoading(true);

        const email = e.target.email.value;
        const password = e.target.password.value;

        if (email && password && code) {
            newPasswordMutation.mutate(
                { email, password, code },
                {
                    onSuccess: () => {
                        setIsLoading(false);
                        toast(
                            'Password changed successfully. Please login with your new password.',
                            {
                                type: 'success',
                            }
                        );
                        navigate('/');
                    },
                    onError: () => {
                        setIsLoading(false);
                        toast('Error changing password', { type: 'error' });
                    },
                }
            );
        } else {
            setIsLoading(false);
            toast('Please fill all required fields', { type: 'error' });
        }
    };

    return (
        <div className="new-password container">
            <form onSubmit={e => onSubmit(e)} noValidate>
                <FormGenerator
                    settings={config}
                    forceEdit={true}
                    defaultData={{
                        email,
                    }}
                />

                <SubmitGBI
                    isDisabled={isLoading}
                    isLoading={isLoading}
                    title="Change password"
                />
            </form>
        </div>
    );
};
