import React from 'react';

export const TabsHeader = ({
    config,
}: {
    config: Array<{ title: string; content: any }>;
}) => {
    const headingElements = config.map((tab, index) => {
        return (
            <li key={index} className="nav-item" role="presentation">
                <button
                    className={`nav-link ${index === 0 ? 'active' : ''}`}
                    id={`${tab.title.toLowerCase().replace(' ', '-')}-tab`}
                    data-bs-toggle="tab"
                    data-bs-target={`#${tab.title
                        .toLowerCase()
                        .replace(' ', '-')}`}
                    type="button"
                    role="tab"
                    aria-controls={tab.title.toLowerCase().replace(' ', '-')}
                    aria-selected={index === 0}
                >
                    {tab.title}
                </button>
            </li>
        );
    });

    return (
        <ul className="nav nav-tabs" id="myTab" role="tablist">
            {headingElements}
        </ul>
    );
};
