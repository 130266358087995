import { settingsType } from '@components/Forms/FormGenerator';
import React from 'react';
import { toast } from 'react-toastify';

export const getElementsToValidate = (config: settingsType[]) => {
    const fieldsToValidate = config.filter(
        configElement => configElement.validation?.required === true
    );
    const inputsToValidate =
        document.getElementsByClassName('needs-validation');

    return { fieldsToValidate, inputsToValidate };
};

const addErrorClass = (
    elementToValidate: Element,
    fieldType: string,
    errorClass: string
) => {
    const field = elementToValidate.getElementsByTagName(fieldType);
    field[0]?.classList.add(errorClass);
};

export const isInvalidField = (
    configObject: settingsType[],
    elementsToValidate: HTMLCollectionOf<Element>,
    data: any
) => {
    return configObject.some((object: any, index: number) => {
        const elementToValidate = elementsToValidate[index];
        elementToValidate.classList.remove('error-occurred');

        if (
            object.validation.required &&
            (data[object.config.name] === undefined ||
                data[object.config.name] === '' ||
                data[object.config.name] === null)
        ) {
            elementToValidate.classList.add('error-occurred');
            addErrorClass(
                elementToValidate,
                object.config.fieldType,
                'input-error-occurred'
            );

            toast.error(
                <span>
                    Please fill <b>{object.config.title}</b> field
                </span>
            );

            // Scroll to the element
            elementToValidate.scrollIntoView({
                block: 'start',
                behavior: 'smooth',
            });

            return true;
        }

        if (
            data[object.config.name]?.toString()?.length <
            object.validation.minLength
        ) {
            elementToValidate.classList.add('error-occurred');
            addErrorClass(
                elementToValidate,
                object.config.fieldType,
                'input-error-occurred'
            );

            toast.error(
                <span>
                    <b>{object.config.title}</b> must be at least{' '}
                    {object.validation.minLength} characters long
                </span>
            );

            // Scroll to the element
            elementToValidate.scrollIntoView({
                block: 'start',
                behavior: 'smooth',
            });

            return true;
        }

        return false;
    });
};
