import { Loader } from '@components/Loader/Loader';
import { AhjCreate } from '@pages/jurisdiction/AHJ/AhjCreate';
import { CompanyCreate } from '@pages/jurisdiction/Company/CompanyCreate';
import { UtilityCreate } from '@pages/jurisdiction/Utility/UtilityCreate';
import { JurisdictionTypes } from '@queries/jurisdiction.query';
import React from 'react';
import { useParams } from 'react-router';

import './Jurisdiction.scss';


export const JurisdictionCreate = () => {
    const group = localStorage.getItem('userGroup');
    const isManager = group === 'manager';
    const params = useParams();
    const jurisdictionType = isManager
        ? JurisdictionTypes.COMPANY
        : params.type ?? '';

    switch (jurisdictionType) {
        case JurisdictionTypes.AHJ:
            return <AhjCreate />;

        case JurisdictionTypes.UTILITY:
            return <UtilityCreate />;

        case JurisdictionTypes.COMPANY:
            return <CompanyCreate />;
    }

    return <Loader />;
};
