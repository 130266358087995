import { SubmitGBI } from '@components/ButtonGBI/SubmitGBI';
import { FormGenerator } from '@components/Forms/FormGenerator';
import { forgotPasswordFormConfig } from '@pages/verification/forgotPasswordConfig';
import { useForgotPassword } from '@queries/login.query';
import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import './Verification.scss';

export const ForgotPassword = () => {
    const config = forgotPasswordFormConfig();
    const forgotMutation = useForgotPassword();

    const [searchParams] = useSearchParams();

    const searchParamsData = new URLSearchParams(searchParams);

    const email = searchParamsData.get('email')?.replace(/\s/g, '+');

    const [isLoading, setIsLoading] = React.useState(false);

    const onSubmit = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
        setIsLoading(true);

        const email = e.target.email.value;
        if (email) {
            forgotMutation.mutate(email, {
                onSuccess: () => {
                    setIsLoading(false);
                    toast('Email sent successfully. Please check your email.', {
                        type: 'success',
                    });
                },
                onError: () => {
                    setIsLoading(false);
                    toast('Error sending email', { type: 'error' });
                },
            });
        }
    };

    return (
        <div className="forgot-password container">
            <form onSubmit={e => onSubmit(e)} noValidate>
                <FormGenerator
                    settings={config}
                    forceEdit={true}
                    defaultData={{ email }}
                />

                <SubmitGBI
                    isDisabled={isLoading}
                    isLoading={isLoading}
                    title="Reset password"
                />
            </form>
        </div>
    );
};
