import { Companies } from '@pages/companies/Companies';
import { CompaniesCreate } from '@pages/companies/CompaniesCreate';
import { CompaniesDetails } from '@pages/companies/CompaniesDetails';
import { CompaniesEdit } from '@pages/companies/CompaniesEdit';


export const companiesAdminRoutes = [
    {
        path: '/admin/companies',
        Component: Companies,
    },
    {
        path: '/admin/companies/:id',
        Component: CompaniesDetails,
    },
    {
        path: '/admin/companies/create',
        Component: CompaniesCreate,
    },
    {
        path: '/admin/companies/:id/edit',
        Component: CompaniesEdit,
    },
];
