import { AddNewComment } from '@components/Comments/AddNewComment';
import { DisplayComments } from '@components/Comments/DisplayComments';
import { UserContext } from '@pages/RootWithSidebar';
import { useCreateComment } from '@queries/comments.query';
import React, { useContext, useState } from 'react';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';

import './Comments.scss';

interface MutateObject {
    text: string;
    userId: string;
    taskId?: string;
    projectId?: string;
}

export const CommentsSection = ({
    commentId,
    commentsData,
    commentFor,
}: {
    commentId?: string;
    commentsData?: any;
    commentFor: 'project' | 'task';
}) => {
    const { user } = useContext(UserContext);
    const params = useParams();
    const id = commentId ?? params.id ?? '';
    const commentsMutation = useCreateComment();
    const [commentText, setCommentText] = useState('');

    const [isLoading, setIsLoading] = useState(false);

    const addComment = () => {
        setIsLoading(true);

        const mutateObject: MutateObject = {
            text: commentText,
            userId: user?.id ?? '',
        };

        if (commentFor === 'task') {
            mutateObject['taskId'] = id;
        } else {
            mutateObject['projectId'] = id;
        }

        // @ts-ignore
        commentsMutation.mutate(mutateObject, {
            onSuccess: () => {
                toast('Comment added successfully', { type: 'success' });
                setCommentText('');
                setIsLoading(false);
            },
            onError: () => {
                setIsLoading(false);
            },
        });
    };

    return (
        <div className="details comments-section">
            <AddNewComment
                commentText={commentText}
                setCommentText={setCommentText}
                isLoading={isLoading}
                addComment={addComment}
            />
            <h4>Comments:</h4>
            <DisplayComments commentsData={commentsData?.data} />
        </div>
    );
};
