import { FormGenerator } from '@components/Forms/FormGenerator';
import { NavigationHeader } from '@components/NavigationHeader/NavigationHeader';
import { GuidelinesCreate } from '@pages/jurisdiction/Guidelines/GuidelinesCreate';
import { JurisdictionTypeSwitch } from '@pages/jurisdiction/JurisdictionTypeSwitch';
import { OtherNavigation } from '@pages/jurisdiction/Other/header/OtherNavigation';
import { INewAHJ, useCreateOther } from '@queries/jurisdiction.query';
import { getGroup } from '@utils/getGroup';
import { getPostMutationObject } from '@utils/getPostMutationObject';
import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import { getElementsToValidate, isInvalidField } from 'src/validations/helpers';

import { otherFormConfig } from './OtherFormConfig';

export const OtherCreate = () => {
    const navigate = useNavigate();
    const postMutation = useCreateOther();
    const otherConfig = otherFormConfig();

    const [guidelinesData, setGuidelinesData] = useState<any>([]);
    const [isLoading, setIsLoading] = useState(false);

    const onSubmit = (e: any) => {
        e.preventDefault();
        setIsLoading(true);

        const otherData: INewAHJ = getPostMutationObject(e, otherConfig);
        otherData && (otherData.guidelines = guidelinesData);
        const { fieldsToValidate, inputsToValidate } =
            getElementsToValidate(otherConfig);

        if (isInvalidField(fieldsToValidate, inputsToValidate, otherData)) {
            setIsLoading(false);
        } else {
            return postMutation.mutate(otherData, {
                onSuccess: () => {
                    toast.success('Other created successfully');
                    return navigate(`/${getGroup()}/jurisdiction`);
                },
                onError: () => {
                    setIsLoading(false);
                },
            });
        }
    };

    return (
        <div className="other-create content-wrapper with-header">
            <div className="row">
                <div className="col-lg-6">
                    <form onSubmit={e => onSubmit(e)} noValidate>
                        <NavigationHeader detailTitle="Creating new Other">
                            <OtherNavigation
                                submitButtonTitle="Save new Other"
                                isDisabled={isLoading}
                            />
                        </NavigationHeader>

                        <JurisdictionTypeSwitch />
                        <FormGenerator
                            settings={otherConfig}
                            formTitle="General details"
                        />
                    </form>
                </div>

                <div className="col-lg-6">
                    <GuidelinesCreate
                        guidelinesData={guidelinesData}
                        setGuidelinesData={setGuidelinesData}
                    />
                </div>
            </div>
        </div>
    );
};
