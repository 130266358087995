import { ButtonGBI } from '@components/ButtonGBI/ButtonGBI';
import { CancelSubmit } from '@components/NavigationHeader/parts/cancelSubmit/CancelSubmit';
import { EditDelete } from '@components/NavigationHeader/parts/editDelete/EditDelete';
import { TaskStatuses } from '@queries/tasks.query';
import { isEditCreatePage } from '@utils/utils';
import React from 'react';

import { StatusDropdown } from './StatusDropdown';


export const TasksNavigation = ({
    submitButtonTitle = 'Save new',
    defaultStatus,
    handleDelete,
    handleCloseTask,
    isLoading = false,
    isDisabled = false,
}: {
    submitButtonTitle?: string;
    defaultStatus?: TaskStatuses;
    handleDelete?: (id: string) => void;
    handleCloseTask?: () => void;
    isLoading?: boolean;
    isDisabled?: boolean;
}) => {
    const isClosed = defaultStatus === TaskStatuses.CLOSED;

    return isEditCreatePage() ? (
        <CancelSubmit
            submitButtonTitle={submitButtonTitle}
            isLoading={isLoading}
            isDisabled={isDisabled}
            location="tasks"
        />
    ) : (
        <>
            {!isClosed && (
                <>
                    <EditDelete handleDelete={handleDelete} />
                    <StatusDropdown
                        defaultValue={defaultStatus ?? TaskStatuses.BACKLOG}
                    />
                </>
            )}

            {handleCloseTask && (
                <ButtonGBI
                    className="status--done"
                    disabled={isClosed}
                    onClick={() => handleCloseTask()}
                >
                    Complete Task
                </ButtonGBI>
            )}
        </>
    );
};
