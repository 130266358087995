import { Toast } from '@components/Toast/Toast';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import 'bootstrap/js/index.esm.js';
import React from 'react';
import { RouterProvider } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { routes } from './routes';
import './styles/App.scss';


Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [
        new BrowserTracing({
            tracingOrigins: ['*'],
        }),
        new Sentry.Replay(),
    ],
    environment: process.env.REACT_APP_NODE_ENV || 'production',
    tracesSampleRate: 0.1,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    release: '1.0.5',
    keepPreviousData: true,
    enabled: process.env.REACT_APP_NODE_ENV === 'production',
});

export const getErrorMessage = error => {
    if (error.response) {
        return error.response.data?.message;
    }

    return error.message;
};

export const queryClient = new QueryClient({
    defaultOptions: {
        mutations: {
            onSuccess: () => {
                toast.success('Data updated!');
            },
            onError: error => {
                const errorMessage = getErrorMessage(error);
                const userFriendlyErrorMessage = errorMessage
                    .replace(
                        /zipCode: Invalid ZIP code/g,
                        'Wrong ZIP code provided'
                    )
                    .replace(
                        /managerId: managerId should not be empty/g,
                        'Manager should be selected'
                    );

                toast.error(
                    `Something went wrong: ${userFriendlyErrorMessage}`
                );
            },
        },
        queries: {
            onSuccess: () => {
                toast.success('Data updated!');
            },
            onError: error => {
                toast.error(`Something went wrong: ${getErrorMessage(error)}`);
            },
            retryOnMount: true,
            retry: false,
            refetchOnWindowFocus: true,
        },
    },
});

export const App = () => {
    return (
        <div className="App">
            <Toast />
            <React.StrictMode>
                <QueryClientProvider client={queryClient}>
                    <RouterProvider router={routes} />
                </QueryClientProvider>
            </React.StrictMode>
        </div>
    );
};
