import { FormGenerator } from '@components/Forms/FormGenerator';
import { Loader } from '@components/Loader/Loader';
import { NavigationHeader } from '@components/NavigationHeader/NavigationHeader';
import { Tabs } from '@components/Tabs/Tabs';
import { useDeleteCompany, useGetCompany } from '@queries/companies.query';
import { useGetUsers } from '@queries/users.query';
import { getGroup } from '@utils/getGroup';
import React from 'react';
import { useNavigate, useParams } from 'react-router';

import './Companies.scss';
import { companiesFormConfig } from './CompaniesFormConfig';
import { CompaniesSidebar } from './CompaniesSidebar';
import { CompaniesNavigation } from './header/CompaniesNavigation';

export const CompaniesDetails = () => {
    const params = useParams();
    const navigate = useNavigate();

    const { data: userData } = useGetUsers({ ['filter.group']: 'manager' });
    const { status, data } = useGetCompany(params.id ?? '');

    const logoUrl = data?.logoUrl;

    const deleteMutation = useDeleteCompany();

    const usersData = userData?.data?.map((user: any) => {
        return {
            id: user.id,
            valueToShow: user.firstName + ' ' + user.lastName,
        };
    });

    let companyData = data;

    const setManager = (id: string) => {
        companyData && (companyData.managerId = id);
    };

    const handleDelete = (id: string) => {
        deleteMutation.mutate(id, {
            onSuccess: () => {
                navigate(`/${getGroup()}/companies`);
            },
        });
    };

    const tabsConfig = [
        {
            title: 'Overview',
            content:
                status === 'success' ? (
                    <FormGenerator
                        settings={companiesFormConfig(
                            false,
                            usersData,
                            setManager
                        )}
                        defaultData={data}
                    />
                ) : (
                    <Loader />
                ),
        },
    ];

    return (
        <div className="companies-detail content-wrapper with-header">
            <NavigationHeader
                detailTitle={data?.name ?? "Company's details"}
                onBack={() => navigate(`/${getGroup()}/companies`)}
            >
                <CompaniesNavigation
                    submitButtonTitle="Save company"
                    defaultStatus={data?.status}
                    handleDelete={id => handleDelete(id)}
                />
            </NavigationHeader>

            <div className="row">
                <div className="col-lg-6">
                    {logoUrl && (
                        <img
                            src={logoUrl}
                            alt="company-logo"
                            className="company-logo"
                        />
                    )}

                    <Tabs config={tabsConfig} />
                </div>
                <CompaniesSidebar />
            </div>
        </div>
    );
};
