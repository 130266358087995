import { FieldType, Type, settingsType } from '@components/Forms/FormGenerator';

export const guidelinesFormConfig = (
    guidelineTypes?: { id: string; valueToShow: string }[],
    onGuidelineTypeChange?: (data: any) => void
): settingsType[] => [
    {
        config: {
            fieldType: FieldType.Select,
            type: Type.Text,
            name: 'category',
            title: 'Category',
            selectData: guidelineTypes,
            onChange: (data: any) => onGuidelineTypeChange?.(data),
        },
        global: {
            viewMode: {
                boldLabel: true,
                featuredData: true,
            },
        },
    },
    {
        config: {
            fieldType: FieldType.Textarea,
            type: Type.Text,
            name: 'note',
            title: 'Note',
        },
        global: {
            viewMode: {
                inlineLabel: true,
                inlineData: true,
                boldLabel: true,
                boldData: false,
            },
        },
    },
];
