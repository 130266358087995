// @ts-nocheck
import { FieldType, Type, settingsType } from '@components/Forms/FormGenerator';

export const tasksFormConfig = ({
    isCreateMode = false,
    taskTypeSelectData,
    projectsSelectData,
    onTaskTypeChange,
    onProjectIdChange,
    onSelectedFile,
    selectedFile,
    projectUrl,
    taskBlockedByUrl,
    onBlockedByTaskIdChange,
    blockedByTaskSelectData,
    projectIdForTaskCreation,
}: {
    isCreateMode?: boolean;
    isViewMode?: boolean;
    taskTypeSelectData?: { id: string; valueToShow: string }[];
    projectsSelectData?: { id: string; valueToShow: string }[];
    onTaskTypeChange?: (data: any) => void;
    onProjectIdChange?: (data: any) => void;
    onSelectedFile?: (data: File[]) => void;
    selectedFile?: File[];
    projectUrl?: string;
    projectIdForTaskCreation?: string;
    taskBlockedByUrl?: string;
    onBlockedByTaskIdChange?: (data: any) => void;
    blockedByTaskSelectData?: { id: string; valueToShow: string }[];
}): settingsType[] => [
    {
        config: {
            fieldType: FieldType.Input,
            type: Type.Text,
            name: 'title',
            title: "Task's title",
        },
        validation: {
            required: true,
            minLength: 3,
            errorMessage: "Task's title must be at least 3 characters long",
        },
        global: {
            viewMode: {
                boldLabel: true,
                featuredData: true,
            },
        },
    },
    {
        config: {
            fieldType: FieldType.Textarea,
            type: Type.Text,
            name: 'description',
            title: "Task's description",
        },
        global: {
            viewMode: {
                boldLabel: true,
            },
        },
    },
    {
        config: {
            fieldType: FieldType.Select,
            type: Type.Text,
            name: 'type',
            title: "Task's type",
            selectData: taskTypeSelectData,
            onChange: (data: any) => onTaskTypeChange?.(data),
        },
        validation: {
            required: true,
            minLength: 1,
            errorMessage: "Please select Task's type",
        },
        global: {
            viewMode: {
                boldLabel: true,
            },
        },
    },
    {
        config: {
            fieldType: FieldType.Select,
            type: Type.Link,
            name: 'blockedByTaskId',
            title: 'Blocked by task',
            selectData: blockedByTaskSelectData,
            onChange: (data: any) => onBlockedByTaskIdChange?.(data),
            url: taskBlockedByUrl,
            unsetOption: true,
        },
        global: {
            viewMode: {
                boldLabel: true,
            },
        },
    },
    ...((isCreateMode && projectIdForTaskCreation === undefined
        ? [
              {
                  config: {
                      fieldType: FieldType.SearchableDropdown,
                      type: Type.Link,
                      name: 'projectId',
                      title: 'Project',
                      selectData: projectsSelectData,
                      onChange: (data: any) => onProjectIdChange?.(data),
                      dataSourceUrlPath: 'projects',
                  },
                  validation: {
                      required: true,
                      minLength: 1,
                      errorMessage: 'Please select Project',
                  },
                  global: {
                      viewMode: {
                          boldLabel: true,
                      },
                      editMode: {
                          disabled: true,
                      },
                  },
              },
          ]
        : []) as settingsType[]),
    ...((!isCreateMode || projectIdForTaskCreation !== undefined
        ? [
              {
                  config: {
                      fieldType: FieldType.Input,
                      type: Type.Link,
                      name: 'projectName',
                      title: 'Project',
                      url: projectUrl,
                  },
                  global: {
                      editMode: {
                          disabled: true,
                      },
                      viewMode: {
                          boldLabel: true,
                      },
                  },
              },
          ]
        : []) as settingsType[]),
    {
        config: {
            fieldType: FieldType.Hr,
            type: Type.Text,
            name: '',
            title: 'More options',
        },
    },
    {
        config: {
            fieldType: FieldType.Input,
            type: Type.DatetimeLocal,
            name: 'dueDate',
            title: 'Due date',
        },
        global: {
            viewMode: {
                inlineLabel: true,
                inlineData: true,
                boldData: true,
            },
        },
    },
    {
        config: {
            fieldType: FieldType.FilesUpload,
            type: Type.Text,
            name: 'files',
            title: 'Files',
            onChange: (data: File[]) => onSelectedFile?.(data),
            files: selectedFile,
        },
    },
];
