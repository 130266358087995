import useUrlState from '@ahooksjs/use-url-state';
import { ReactComponent as CSVFileIcon } from '@assets/CSV-file.svg';
import { ReactComponent as ResetFiltersIcon } from '@assets/arrow-counterclockwise.svg';
import { ReactComponent as SearchIcon } from '@assets/searchIcon.svg';
import { ButtonGBI } from '@components/ButtonGBI/ButtonGBI';
import { DropdownFilter } from '@components/Table/DropdownsFilters';
import { TableColumnFilter } from '@components/Table/TableColumnFilter';
import { UserContext } from '@pages/RootWithSidebar';
import { Column } from '@tanstack/table-core';
import { useDebounce } from 'ahooks';
import React, { useContext, useEffect, useState } from 'react';
import CsvDownloader from 'react-csv-downloader';
import { NavLink } from 'react-router-dom';

type TableHeaderActionsProps = {
    columns?: Column<any>[];
    buttonTitle?: string;
    isCreateButtonEnabled?: boolean;
    onButtonTo?: string;
    projectIdForTaskCreation?: string;
    isDropdownEnabled?: boolean;
    dropdownFiltersFor: string;
    isMultiselect?: boolean;
    CSVColumns?: any;
    CSVData?: any;
    isCSVButtonEnabled?: boolean;
};

export const TableHeaderActions = ({
    columns,
    buttonTitle,
    isCreateButtonEnabled,
    onButtonTo,
    isDropdownEnabled,
    dropdownFiltersFor,
    isMultiselect,
    CSVColumns,
    CSVData,
    isCSVButtonEnabled = false,
    projectIdForTaskCreation,
}: TableHeaderActionsProps) => {
    const { user } = useContext(UserContext);
    const type = dropdownFiltersFor;
    const [urlState, setUrlState] = useUrlState();
    const [searchValue, setSearchValue] = useState(
        urlState?.[`search_${type}`] || ''
    );

    const debouncedValue = useDebounce<string>(searchValue, { wait: 450 });

    useEffect(() => {
        const searchKey = 'search_' + type;

        if (searchValue) {
            setUrlState({ [searchKey]: searchValue });
        }
    }, [debouncedValue]);

    const handleResetFilters = () => {
        const filters = urlState;

        for (let key in filters) {
            filters[key] = undefined;
        }

        setSearchValue('');
        setUrlState(filters);
    };

    return (
        <div className="table-header d-flex justify-content-between mb-4">
            <div className="search-and-filters d-flex column-gap-3">
                <div className="input-group">
                    <input
                        type="text"
                        placeholder="Search by name or manager"
                        aria-label="search"
                        value={searchValue}
                        autoFocus
                        onChange={e => setSearchValue(e.target.value)}
                    />
                    <span className="input-group-text">
                        <SearchIcon />
                    </span>
                </div>
                {isDropdownEnabled && (
                    <DropdownFilter
                        dropdownFiltersFor={dropdownFiltersFor}
                        currentUserId={user?.id}
                        isMultiselect={isMultiselect}
                    />
                )}
                <ButtonGBI
                    variant="primary-opposite"
                    className="reset-all-filters"
                    onClick={() => handleResetFilters()}
                >
                    <ResetFiltersIcon />
                </ButtonGBI>
                {isCSVButtonEnabled && (
                    <CsvDownloader
                        datas={CSVData}
                        columns={CSVColumns ?? columns}
                        filename={dropdownFiltersFor}
                        separator={';'}
                    >
                        <ButtonGBI
                            variant="primary-opposite"
                            className="extra-button"
                        >
                            <CSVFileIcon />
                        </ButtonGBI>
                    </CsvDownloader>
                )}
            </div>

            <div className="create-eye-filter d-flex align-items-stretch column-gap-2">
                <TableColumnFilter options={columns} />

                {buttonTitle && isCreateButtonEnabled && (
                    <NavLink
                        state={{
                            projectIdForTaskCreation,
                        }}
                        className="create-new-button"
                        to={onButtonTo ?? ''}
                    >
                        <ButtonGBI>{buttonTitle}</ButtonGBI>
                    </NavLink>
                )}
            </div>
        </div>
    );
};
