import { ReactComponent as ProjectsIcon } from '@assets/projectsIcon.svg';
import { ReactComponent as SettingsIcon } from '@assets/settingsIcon.svg';
import { ReactComponent as TasksIcon } from '@assets/tasksIcon.svg';
import { ReactComponent as UsersIcon } from '@assets/usersIcon.svg';

export const employeeMenu = [
    {
        name: 'Projects',
        icon: ProjectsIcon,
        path: '/employee/projects',
    },
    {
        name: 'Tasks',
        icon: TasksIcon,
        path: '/employee/tasks',
    },
    // TODO this is removed until client confirm what to do with that
    // {
    //     name: 'Jurisdiction',
    //     icon: JurisdictionIcon,
    //     path: '/employee/jurisdiction',
    // },
    {
        name: 'Users',
        icon: UsersIcon,
        path: '/employee/users',
    },
    {
        name: 'Settings',
        icon: SettingsIcon,
        path: '/employee/settings/edit',
    },
];
