import classNames from 'classnames';
import React from 'react';

import { globalType } from '../FormGenerator';

export const GetLabel = ({
    title,
    global,
}: {
    title: string;
    global?: globalType;
}) => {
    return (
        <p
            className={classNames({
                'd-inline-block pe-4': global?.viewMode?.inlineLabel,
                'fw-bold': global?.viewMode?.boldLabel,
            })}
        >
            {title}
        </p>
    );
};

export const GetData = ({
    title,
    global,
    className,
}: {
    title: string;
    global?: globalType;
    className?: string;
}) => {
    return (
        <p
            className={
                classNames(className, {
                    featured: global?.viewMode?.featuredData,
                    'd-inline-block': global?.viewMode?.inlineData,
                    'fw-bold': global?.viewMode?.boldData,
                })
            }
        >
            {global?.viewMode?.prefixData && (
                <span className="me-1">{global.viewMode.prefixData}</span>
            )}
            {title}
        </p>
    );
};
