import { FormGenerator } from '@components/Forms/FormGenerator';
import { NavigationHeader } from '@components/NavigationHeader/NavigationHeader';
import { companyFormConfig } from '@pages/jurisdiction/Company/CompanyFormConfig';
import { CompanyNavigation } from '@pages/jurisdiction/Company/header/CompanyNavigation';
import { GuidelinesCreate } from '@pages/jurisdiction/Guidelines/GuidelinesCreate';
import { JurisdictionTypeSwitch } from '@pages/jurisdiction/JurisdictionTypeSwitch';
import { INewAHJ, useCreateCompany } from '@queries/jurisdiction.query';
import { getGroup } from '@utils/getGroup';
import { getPostMutationObject } from '@utils/getPostMutationObject';
import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import { getElementsToValidate, isInvalidField } from 'src/validations/helpers';

export const CompanyCreate = () => {
    const navigate = useNavigate();
    const postMutation = useCreateCompany();
    const companyConfig = companyFormConfig();

    const [guidelinesData, setGuidelinesData] = useState<any>([]);
    const [isLoading, setIsLoading] = useState(false);

    const onSubmit = (e: any) => {
        e.preventDefault();
        setIsLoading(true);

        const companyData: INewAHJ = getPostMutationObject(e, companyConfig);
        companyData && (companyData.guidelines = guidelinesData);
        const { fieldsToValidate, inputsToValidate } =
            getElementsToValidate(companyConfig);

        if (isInvalidField(fieldsToValidate, inputsToValidate, companyData)) {
            setIsLoading(false);
        } else {
            return postMutation.mutate(companyData, {
                onSuccess: () => {
                    toast.success('Company created successfully');
                    return navigate(`/${getGroup()}/jurisdiction`);
                },
                onError: () => {
                    setIsLoading(false);
                },
            });
        }
    };

    return (
        <div className="company-create content-wrapper with-header">
            <div className="row">
                <div className="col-lg-6">
                    <form onSubmit={e => onSubmit(e)} noValidate>
                        <NavigationHeader detailTitle="Creating new Compnany">
                            <CompanyNavigation
                                submitButtonTitle="Save new Compnany"
                                isDisabled={isLoading}
                            />
                        </NavigationHeader>

                        <JurisdictionTypeSwitch />
                        <FormGenerator
                            settings={companyConfig}
                            formTitle="General details"
                        />
                    </form>
                </div>

                <div className="col-lg-6">
                    <GuidelinesCreate
                        guidelinesData={guidelinesData}
                        setGuidelinesData={setGuidelinesData}
                    />
                </div>
            </div>
        </div>
    );
};
