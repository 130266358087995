import { FormGenerator } from '@components/Forms/FormGenerator';
import { Loader } from '@components/Loader/Loader';
import { NavigationHeader } from '@components/NavigationHeader/NavigationHeader';
import { Tabs } from '@components/Tabs/Tabs';
import { useDeleteUser, useGetUser } from '@queries/users.query';
import { getGroup } from '@utils/getGroup';
import React from 'react';
import { useNavigate, useParams } from 'react-router';

import './Users.scss';
import { userTypes } from './UsersCreate';
import { usersFormConfig } from './UsersFormConfig';
import { UsersNavigation } from './header/UsersNavigation';

export const UsersDetails = () => {
    const params = useParams();
    const navigate = useNavigate();
    const taskId = params.id ?? '';

    const { status, data } = useGetUser(taskId);

    const deleteUserMutation = useDeleteUser();

    const handleDelete = () => {
        deleteUserMutation.mutate(taskId, {
            onSuccess: () => {
                navigate(`/${getGroup()}/users`);
            },
        });
    };

    const tabsConfig = [
        {
            title: 'Overview',
            content:
                status === 'success' ? (
                    <FormGenerator
                        settings={usersFormConfig(undefined, userTypes)}
                        defaultData={data}
                    />
                ) : (
                    <Loader />
                ),
        },
    ];

    return (
        <div className="users-details content-wrapper with-header">
            <NavigationHeader
                detailTitle={
                    `${data?.firstName} ${data?.lastName}` ?? "User's details"
                }
            >
                <UsersNavigation
                    defaultStatus={data?.status}
                    handleDelete={handleDelete}
                />
            </NavigationHeader>

            <div className="row">
                <div className="col-lg-6">
                    <Tabs config={tabsConfig} />
                </div>
            </div>
        </div>
    );
};
