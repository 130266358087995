import fetchGBI from '@queries/fetchGBI';
import { AdminBaseURL, DefaultBaseURL } from '@queries/urlHelpers';
import { useQuery } from '@tanstack/react-query';
import { getGroup } from '@utils/getGroup';
import { useDebounce } from 'ahooks';
import React, { useEffect, useState } from 'react';

import './SearchableDropdown.scss';

export interface SelectedElement {
    id: string | null;
    valueToShow: string;
}

export const SearchableDropdown = ({
    onSelect,
    initialData,
}: {
    onSelect: (result: SelectedElement) => void;
    initialData: SelectedElement;
}) => {
    const [query, setQuery] = useState(
        initialData ? initialData.valueToShow : ''
    );
    const [isQueryEnabled, setIsQueryEnabled] = useState<boolean>(false);
    const [selectedValue, setSelectedValue] =
        useState<SelectedElement>(initialData);
    useEffect(() => {
        setSelectedValue(initialData);
    }, [initialData]);
    const debouncedQuery = useDebounce(query, { wait: 500 });
    const group = getGroup();

    const { data: users, isFetching } = useQuery({
        queryKey: [debouncedQuery],
        enabled: debouncedQuery.length >= 2 && isQueryEnabled,
        select: data => {
            return data.data.map((user: any) => ({
                id: user.id,
                valueToShow: `${user.firstName} ${user.lastName}`,
            }));
        },
        queryFn: () =>
            fetchGBI(
                (group === 'admin' ? AdminBaseURL : DefaultBaseURL) +
                    `/users?search=${query}`
            ),
    });

    const handleInputChange = (event: any) => {
        setQuery(event.target.value);
        setIsQueryEnabled(true);
    };

    const handleSelect = (item: SelectedElement) => {
        onSelect(item);
        setQuery(item.valueToShow);
        setSelectedValue({ ...item });
        setIsQueryEnabled(false);
    };

    return (
        <div className="container">
            <label className="pt-2">Assigned to:</label>
            <div className="dropdown m-3 w-50">
                <button
                    className="btn btn-secondary dropdown-toggle"
                    type="button"
                    id="dropdownMenuButton"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                >
                    {selectedValue?.valueToShow}
                </button>
                <div
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuButton"
                >
                    <input
                        type="text"
                        onChange={handleInputChange}
                        className="form-control
                            dropdown-header
                              border-0 border-bottom
                              shadow-none mb-2"
                        placeholder="Search..."
                    />
                    {users?.map((result: any) => (
                        <a
                            key={result.id}
                            className="dropdown-item"
                            onClick={() => handleSelect(result)}
                        >
                            {result.valueToShow}
                        </a>
                    ))}
                </div>
            </div>
        </div>
    );
};
