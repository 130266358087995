// @ts-nocheck
import { TableActions } from '@components/Table/TableActions';
import { ColumnDef } from '@tanstack/react-table';
import React from 'react';

import { Projects } from './ProjectsTable';

export const usersTableSidebarSetup = (
    handleDelete: any,
    handleEdit: any,
    handlePause: any
): ColumnDef<Projects>[] => {
    return [
        {
            accessorKey: 'name',
            header: 'Name',
            cell: data => {
                return (
                    data.row.original.firstName +
                    ' ' +
                    data.row.original.lastName
                );
            },
            footer: props => props.column.id,
            filterTitle: 'Name',
        },
        {
            accessorKey: 'email',
            header: 'E-mail',
            footer: props => props.column.id,
            filterTitle: 'E-mail',
        },
        {
            accessorKey: 'status',
            header: 'Status',
            footer: props => props.column.id,
            filterTitle: 'Status',
        },
        {
            allowAsProps: true,
            accessorKey: 'actions',
            header: 'Actions',
            filterTitle: 'Actions',
            cell: data => {
                const status = data.row.original.status;
                let newStatus = status;

                return (
                    <TableActions
                        handleDelete={() =>
                            handleDelete(data.row.getValue('id'))
                        }
                        handleEdit={() => handleEdit(data)}
                        handlePause={() =>
                            handlePause?.(data.row.original.id, newStatus)
                        }
                        status={status}
                    />
                );
            },
        },
    ];
};
