import { FieldType, Type, settingsType } from '@components/Forms/FormGenerator';

export const ahjFormConfig = (): settingsType[] => [
    {
        config: {
            fieldType: FieldType.Input,
            type: Type.Text,
            name: 'name',
            title: 'Name',
        },
        validation: {
            required: true,
            minLength: 3,
            errorMessage: 'Name must be at least 3 characters long',
        },
        global: {
            viewMode: {
                boldLabel: true,
                featuredData: true,
            },
        },
    },
    {
        config: {
            fieldType: FieldType.Input,
            type: Type.Text,
            name: 'necCodeYear',
            title: 'NEC code year',
        },
        global: {
            viewMode: {
                inlineLabel: true,
                inlineData: true,
                boldLabel: true,
                boldData: false,
            },
        },
    },
    {
        config: {
            fieldType: FieldType.Input,
            type: Type.Text,
            name: 'ifcIbcCodeYear',
            title: 'IFC / IBC code year',
        },
        global: {
            viewMode: {
                inlineLabel: true,
                inlineData: true,
                boldLabel: true,
                boldData: false,
            },
        },
    },
    {
        config: {
            fieldType: FieldType.Input,
            type: Type.Text,
            name: 'otherCodes',
            title: 'Other codes',
        },
        global: {
            viewMode: {
                inlineLabel: true,
                inlineData: true,
                boldLabel: true,
                boldData: false,
            },
        },
    },
    {
        config: {
            fieldType: FieldType.Input,
            type: Type.Text,
            name: 'planSetSize',
            title: 'Plan set size',
        },
        global: {
            viewMode: {
                inlineLabel: true,
                inlineData: true,
                boldLabel: true,
                boldData: false,
            },
        },
    },
    {
        config: {
            fieldType: FieldType.Input,
            type: Type.Text,
            name: 'snowLoad',
            title: 'Snow load',
        },
        global: {
            viewMode: {
                inlineLabel: true,
                inlineData: true,
                boldLabel: true,
                boldData: false,
            },
        },
    },
    {
        config: {
            fieldType: FieldType.Input,
            type: Type.Text,
            name: 'windLoad',
            title: 'Wind load',
        },
        global: {
            viewMode: {
                inlineLabel: true,
                inlineData: true,
                boldLabel: true,
                boldData: false,
            },
        },
    },
    {
        config: {
            fieldType: FieldType.Input,
            type: Type.Text,
            name: 'exposureCategory',
            title: 'Exposure category',
        },
        global: {
            viewMode: {
                inlineLabel: true,
                inlineData: true,
                boldLabel: true,
                boldData: false,
            },
        },
    },
    {
        config: {
            fieldType: FieldType.Input,
            type: Type.Text,
            name: 'residentialOrCommercialRequirements',
            title: 'Residential or commercial requirements',
        },
        global: {
            viewMode: {
                inlineLabel: true,
                inlineData: true,
                boldLabel: true,
                boldData: false,
            },
        },
    },
    {
        config: {
            fieldType: FieldType.Textarea,
            type: Type.Textarea,
            name: 'additionalComment',
            title: 'Additional comments',
        },
        global: {
            viewMode: {
                inlineLabel: true,
                inlineData: true,
                boldLabel: true,
                boldData: false,
            },
        },
    },
];
