import { ButtonGBI } from '@components/ButtonGBI/ButtonGBI';
import { PaginationNumbers } from '@components/Pagination/PaginationNumbers';
import React from 'react';

import './Pagination.scss';


export const PaginationPrevNext = ({
    currentPage,
    totalPages,
    isNextAvailable,
    isPrevAvailable,
    handleNextPage,
    handlePreviousPage,
}: {
    currentPage: number;
    totalPages: number;
    isNextAvailable: boolean;
    isPrevAvailable: boolean;
    handleNextPage: () => void;
    handlePreviousPage: () => void;
}) => {
    return (
        <div className="pagination-wrapper">
            <nav aria-label="navigation">
                <ul className="pagination">
                    <li className="page-item">
                        <ButtonGBI
                            variant={
                                !isPrevAvailable
                                    ? 'primary-opposite'
                                    : 'primary'
                            }
                            className="page-link"
                            onClick={() => handlePreviousPage()}
                            disabled={!isPrevAvailable}
                        >
                            Previous
                        </ButtonGBI>
                    </li>
                    <PaginationNumbers
                        totalPages={totalPages}
                        currentPage={currentPage}
                    />
                    <li className="page-item">
                        <ButtonGBI
                            variant={
                                !isNextAvailable
                                    ? 'primary-opposite'
                                    : 'primary'
                            }
                            className="page-link"
                            onClick={() => handleNextPage()}
                            disabled={!isNextAvailable}
                        >
                            Next
                        </ButtonGBI>
                    </li>
                </ul>
            </nav>
        </div>
    );
};
