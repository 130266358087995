import useUrlState from '@ahooksjs/use-url-state';
import { ButtonGBI } from '@components/ButtonGBI/ButtonGBI';
import React from 'react';

import './Pagination.scss';

export const PaginationNumbers = ({
    currentPage,
    totalPages,
}: {
    currentPage: number;
    totalPages: number;
}) => {
    const [urlState, setUrlState] = useUrlState();

    const elements = [];

    for (let i = 1; i <= totalPages; i++) {
        const isDisabled = currentPage === i;
        elements.push(
            <li key={i} className="page-item">
                <ButtonGBI
                    variant={isDisabled ? 'primary-opposite' : 'primary'}
                    className="page-link"
                    onClick={() =>
                        setUrlState({
                            ...urlState,
                            page: i,
                        })
                    }
                    disabled={isDisabled}
                >
                    {i}
                </ButtonGBI>
            </li>
        );
    }

    return elements;
};
