import { TaskStatuses, useEditTask } from '@queries/tasks.query';
import { useQueryClient } from '@tanstack/react-query';
import { getStatus, getStatusClasses } from '@utils/statuses';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';


export const StatusDropdown = ({
    defaultValue,
}: {
    defaultValue: TaskStatuses;
}) => {
    const client = useQueryClient();
    const [status, setStatus] = useState(defaultValue);
    const params = useParams();
    const tasksMutation = useEditTask(params.id ?? '');

    const statusToRender = getStatus(defaultValue);

    const onSubmit = () => {
        if (status)
            return tasksMutation.mutate(
                {
                    status,
                },
                {
                    onSuccess: () => {
                        toast.success('Task status changed successfully');
                        return client.invalidateQueries({
                            queryKey: ['tasks'],
                        });
                    },
                }
            );
    };

    useEffect(() => {
        if (status !== defaultValue) {
            onSubmit();
        }
    }, [status]);

    return (
        <div className="status-change-dropdown">
            <div className="dropdown">
                <button
                    className={classNames(
                        'btn dropdown-toggle',
                        getStatusClasses(defaultValue)
                    )}
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                >
                    {statusToRender}
                </button>
                <ul className="dropdown-menu">
                    <li>
                        <span
                            role="button"
                            tabIndex={0}
                            className="dropdown-item"
                            onClick={() => setStatus(TaskStatuses.OPEN)}
                        >
                            Open
                        </span>
                    </li>
                    <li>
                        <span
                            role="button"
                            tabIndex={0}
                            className="dropdown-item"
                            onClick={() => setStatus(TaskStatuses.IN_PROGRESS)}
                        >
                            In progress
                        </span>
                    </li>
                </ul>
            </div>
        </div>
    );
};
