import { SingleFile } from '@components/FilesUpload/SingleFile';
import { useGetFilesByResourceId } from '@queries/files.query';
import React from 'react';
import { useParams } from 'react-router';

type FilesList = {
    title?: string;
    resourceId?: string;
    withStatus?: boolean;
};

export const FilesList = ({
    title = 'Files',
    resourceId,
    withStatus = false,
}: FilesList) => {
    const params = useParams();
    const projectId = resourceId ?? params.id ?? '';

    const { data: filesData } = useGetFilesByResourceId(projectId);
    return (
        <div className="files-list">
            <div className="title">{title}</div>
            {filesData?.map(file => {
                return (
                    <SingleFile
                        key={file.id}
                        file={file}
                        withStatus={withStatus}
                    />
                );
            })}
        </div>
    );
};
