import useUrlState from '@ahooksjs/use-url-state';
import fetchGBI from '@queries/fetchGBI';
import {
    UseQueryResult,
    useMutation,
    useQuery,
    useQueryClient,
} from '@tanstack/react-query';
import axios from 'axios';

import { DefaultBaseURL, getMutationUrlParams } from './urlHelpers';

export type INewFile = {
    body: File;
    resourceId?: string;
};

export type IResourceFile = {
    file: any;
    id: string;
    name: string;
    resourceId: string;
    s3Key: string;
    createdAt: string;
    fileSize: number;
};

export type IResourceFileResponse = {
    file: any;
    id: string;
    name: string;
    resourceId: string;
    s3Key: string;
    user: {
        id: string;
    };
};

export const useCreateFile = () => {
    const client = useQueryClient();

    return useMutation<IResourceFileResponse[], unknown, INewFile>({
        mutationFn: async (newFile: INewFile): Promise<IResourceFileResponse[]> => {
            const formData = new FormData();

            formData.append('files', newFile.body, newFile.body.name);
            formData.append('resourceId', newFile.resourceId ?? '');

            const { data } = await axios.post<IResourceFileResponse[]>(
                DefaultBaseURL + '/files',
                formData,
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem(
                            'token'
                        )}`,
                        'Content-Type': 'multipart/form-data',
                    },
                }
            );
            return data;
        },
        onSuccess: () => {
            return client.invalidateQueries({ queryKey: ['files'] });
        },
    });
};

export const useGetFileById = (
    id?: string,
    searchParams?: Partial<{ [x: string]: any }>
) => {
    const [urlState] = useUrlState();
    const group = localStorage.getItem('userGroup');
    const href = getMutationUrlParams(
        `${DefaultBaseURL}/files/${id}`,
        searchParams ?? urlState,
        'files'
    ).href;

    return useQuery({
        queryKey: ['files', href, group],
        queryFn: () => fetchGBI(href),
    });
};

export const useGetFilesByResourceId = (
    resourceId?: string,
    searchParams?: Partial<{ [x: string]: any }>
): UseQueryResult<IResourceFile[]> => {
    const [urlState] = useUrlState();
    const group = localStorage.getItem('userGroup');
    const href = getMutationUrlParams(
        `${DefaultBaseURL}/files/resource/${resourceId}`,
        searchParams ?? urlState,
        'files'
    ).href;

    return useQuery({
        queryKey: ['files', href, group],
        queryFn: () => fetchGBI(href),
    });
};

export const useDeleteFile = () => {
    const client = useQueryClient();

    return useMutation({
        mutationFn: async (fileId: string) => {
            await axios.delete(DefaultBaseURL + `/files/${fileId}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
            });
        },
        onSuccess: () => {
            return client.invalidateQueries({ queryKey: ['files'] });
        },
    });
};
