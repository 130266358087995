import { configType, globalType } from '@components/Forms/FormGenerator';
import { GetLabel } from '@components/Forms/helpers/GetLabelData';
import { isDetailsPage, isEditPage } from '@utils/utils';
import React, { useState } from 'react';

import './CheckboxField.scss';

export const CheckboxField = ({
    config: { name, title, onChange },
    global,
    disabled,
    defaultData,
}: {
    config: configType;
    global?: globalType;
    disabled: boolean;
    defaultData: any;
}) => {
    let checkboxData: {
        id: string;
        name: string;
        checked?: boolean;
    }[] = defaultData?.[name];
    if (isDetailsPage() || isEditPage()) {
        checkboxData = checkboxData.map((e: any) => {
            return { ...e, checked: true };
        });
    }

    const [checkboxState, setCheckboxState] = useState(checkboxData);

    const onCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const targetId = e.target.id;
        const targetIsChecked = e.target.checked;

        checkboxState.forEach(item => {
            if (item.id === targetId) {
                item.checked = targetIsChecked;
            }
        });

        onChange?.(checkboxState);
    };

    const isEnabled = () => {
        if (isEditPage()) {
            return global?.editMode?.disabled ?? false;
        }

        if (isDetailsPage()) {
            return global?.viewMode?.disabled ?? false;
        }

        return disabled;
    };

    return (
        <div className="checkbox-field form-group">
            <GetLabel title={title} global={global} />

            <div className="checkbox-elements">
                {checkboxData?.map(
                    (checkbox: {
                        id: string;
                        name: string;
                        checked?: boolean;
                    }) => {
                        return (
                            <div className="checkbox-element" key={checkbox.id}>
                                <input
                                    type="checkbox"
                                    id={checkbox.id}
                                    name={checkbox.name}
                                    disabled={isEnabled()}
                                    defaultChecked={checkbox.checked ?? false}
                                    onChange={onCheckboxChange}
                                />
                                <label htmlFor={checkbox.id}>
                                    {checkbox.name}
                                </label>
                            </div>
                        );
                    }
                )}
            </div>
        </div>
    );
};
