//@ts-nocheck
import useUrlState from '@ahooksjs/use-url-state';
import { ReactComponent as SortingArrowsIcon } from '@assets/sortingArrows.svg';
import { TableActions } from '@components/Table/TableActions';
import { TanStackTable } from '@components/Table/TanStackTable';
import { CompanyStatus } from '@queries/companies.query';
import { ColumnDef } from '@tanstack/react-table';
import { getGroup } from '@utils/getGroup';
import { handleSort } from '@utils/sorting';
import React from 'react';
import { useNavigate } from 'react-router';

import { Projects } from '../projects/ProjectsTable';


type CompaniesTableProps = {
    propData?: any;
    handleDelete?: (id: string) => void;
    handlePause?: (id: string, status: CompanyStatus) => void;
};

export const CompaniesTable = ({
    propData,
    handleDelete,
    handlePause,
}: CompaniesTableProps) => {
    const [urlState, setUrlState] = useUrlState();
    const navigate = useNavigate();

    const tableSetup: ColumnDef<Projects>[] = [
        {
            accessorKey: 'id',
            header: 'ID',
            footer: props => props.column.id,
            filterTitle: 'ID',
            size: 90,
        },
        {
            accessorKey: 'name',
            header: () => (
                <button
                    onClick={() => handleSort('name', urlState, setUrlState)}
                >
                    Company
                    <SortingArrowsIcon />
                </button>
            ),
            footer: props => props.column.name,
            filterTitle: 'Company',
            size: 200,
        },
        {
            accessorKey: 'manager.firstName',
            header: () => (
                <button
                    onClick={() =>
                        handleSort('manager.firstName', urlState, setUrlState)
                    }
                >
                    Manager
                    <SortingArrowsIcon />
                </button>
            ),
            footer: props => props.column.id,
            filterTitle: 'Manager',
        },
        {
            accessorKey: 'manager.email',
            header: 'E-mail',
            footer: props => props.column.id,
            filterTitle: 'E-mail',
        },
        {
            accessorKey: 'status',
            header: () => (
                <button
                    onClick={() => handleSort('status', urlState, setUrlState)}
                >
                    Status
                    <SortingArrowsIcon />
                </button>
            ),
            footer: props => props.column.id,
            filterTitle: 'Status',
            size: 205,
        },
        {
            accessorKey: 'actions',
            header: 'Actions',
            filterTitle: 'Actions',
            cell: data => {
                const status = data.row.original.status;
                let newStatus = status;

                switch (status) {
                    case CompanyStatus.ACTIVE:
                        newStatus = CompanyStatus.IN_ACTIVE;
                        break;

                    case CompanyStatus.IN_ACTIVE:
                        newStatus = CompanyStatus.ACTIVE;
                        break;

                    default:
                        newStatus = CompanyStatus.ACTIVE;
                }

                return (
                    <TableActions
                        handleDelete={() =>
                            handleDelete?.(data.row.getValue('id'))
                        }
                        handleEdit={() => {
                            navigate(
                                `/${getGroup()}/companies/${data.row.getValue(
                                    'id'
                                )}/edit`
                            );
                        }}
                        handlePause={() =>
                            handlePause?.(data.row.getValue('id'), newStatus)
                        }
                        status={status}
                    />
                );
            },
        },
    ];

    return (
        <TanStackTable
            tableSetup={tableSetup}
            propData={propData}
            createButtonTitle="Create new company"
            linkTo={`/${getGroup()}/companies`}
            dropdownFiltersFor="companies"
        />
    );
};
