import { ButtonGBI } from '@components/ButtonGBI/ButtonGBI';
import { CancelSubmit } from '@components/NavigationHeader/parts/cancelSubmit/CancelSubmit';
import { EditDelete } from '@components/NavigationHeader/parts/editDelete/EditDelete';
import { ProjectStatus, useEditProject } from '@queries/projects.query';
import { getGroup } from '@utils/getGroup';
import { isEditCreatePage } from '@utils/utils';
import React from 'react';
import { useNavigate, useParams } from 'react-router';
import { toast } from 'react-toastify';

import { StatusDropdown } from './StatusDropdown';

export const ProjectsNavigation = ({
    submitButtonTitle = 'Save new',
    defaultStatus,
    handleDelete,
    isLoading = false,
    isDisabled = false,
    isLastStep = false,
}: {
    submitButtonTitle?: string;
    defaultStatus?: ProjectStatus;
    handleDelete?: (id: string) => void;
    isLoading?: boolean;
    isDisabled?: boolean;
    isLastStep?: boolean;
}) => {
    const navigate = useNavigate();
    const params = useParams();
    const projectsMutation = useEditProject(params.id ?? '');

    const isClosed = defaultStatus === ProjectStatus.DONE;

    const handleCloseProject = () => {
        return projectsMutation.mutate(
            {
                status: ProjectStatus.DONE,
            },
            {
                onSuccess: () => {
                    toast.success('Project closed successfully');
                    navigate(`/${getGroup()}/projects`);
                },
            }
        );
    };

    return isEditCreatePage() ? (
        <CancelSubmit
            submitButtonTitle={submitButtonTitle}
            isLoading={isLoading}
            isDisabled={isDisabled}
            location="projects"
            isLastStep={isLastStep}
        />
    ) : (
        <>
            {!isClosed && (
                <>
                    <EditDelete handleDelete={handleDelete} />
                    <StatusDropdown defaultValue={defaultStatus} />
                </>
            )}

            <ButtonGBI
                className="status--done"
                disabled={defaultStatus === ProjectStatus.DONE}
                onClick={() => handleCloseProject()}
            >
                Complete Project
            </ButtonGBI>
        </>
    );
};
