import { Projects } from '@pages/projects/Projects';
import { ProjectsCreate } from '@pages/projects/ProjectsCreate';
import { ProjectsCreateRedirect } from '@pages/projects/ProjectsCreateRedirect';
import { ProjectsDetails } from '@pages/projects/ProjectsDetails';
import { ProjectsEdit } from '@pages/projects/ProjectsEdit';

export const projectsAdminRoutes = [
    {
        path: '/admin/projects',
        Component: Projects,
    },
    {
        path: '/admin/projects/:id',
        Component: ProjectsDetails,
    },
    {
        path: '/admin/projects/create',
        Component: ProjectsCreateRedirect,
    },
    {
        path: '/admin/projects/:step/create',
        Component: ProjectsCreate,
    },
    {
        path: '/admin/projects/:id/edit',
        Component: ProjectsEdit,
    },
];
