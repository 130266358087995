import { FormGenerator } from '@components/Forms/FormGenerator';
import { NavigationHeader } from '@components/NavigationHeader/NavigationHeader';
import { AhjNavigation } from '@pages/jurisdiction/AHJ/header/AhjNavigation';
import { GuidelinesCreate } from '@pages/jurisdiction/Guidelines/GuidelinesCreate';
import { JurisdictionTypeSwitch } from '@pages/jurisdiction/JurisdictionTypeSwitch';
import { INewAHJ, useCreateAHJ } from '@queries/jurisdiction.query';
import { getGroup } from '@utils/getGroup';
import { getPostMutationObject } from '@utils/getPostMutationObject';
import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import { getElementsToValidate, isInvalidField } from 'src/validations/helpers';

import { ahjFormConfig } from './AhjFormConfig';

export const AhjCreate = () => {
    const navigate = useNavigate();
    const ahjMutation = useCreateAHJ();
    const ahjConfig = ahjFormConfig();

    const [guidelinesData, setGuidelinesData] = useState<any>([]);
    const [isLoading, setIsLoading] = useState(false);

    const onSubmit = (e: any) => {
        e.preventDefault();
        setIsLoading(true);

        const ahjData: INewAHJ = getPostMutationObject(e, ahjConfig);
        ahjData && (ahjData.guidelines = guidelinesData);
        const { fieldsToValidate, inputsToValidate } =
            getElementsToValidate(ahjConfig);

        if (isInvalidField(fieldsToValidate, inputsToValidate, ahjData)) {
            setIsLoading(false);
        } else {
            ahjMutation.mutate(ahjData, {
                onSuccess: () => {
                    toast.success('AHJ created successfully');
                    return navigate(`/${getGroup()}/jurisdiction`);
                },
                onError: () => {
                    setIsLoading(false);
                },
            });
        }
    };

    return (
        <div className="ahj-create content-wrapper with-header">
            <div className="row">
                <div className="col-lg-6">
                    <form onSubmit={e => onSubmit(e)} noValidate>
                        <NavigationHeader detailTitle="Creating new AHJ">
                            <AhjNavigation
                                submitButtonTitle="Save new AHJ"
                                isDisabled={isLoading}
                                isLoading={isLoading}
                            />
                        </NavigationHeader>
                        <JurisdictionTypeSwitch />
                        <FormGenerator
                            settings={ahjConfig}
                            formTitle="General details"
                        />
                    </form>
                </div>

                <div className="col-lg-6">
                    <GuidelinesCreate
                        guidelinesData={guidelinesData}
                        setGuidelinesData={setGuidelinesData}
                    />
                </div>
            </div>
        </div>
    );
};
