import { FormGenerator } from '@components/Forms/FormGenerator';
import { NavigationHeader } from '@components/NavigationHeader/NavigationHeader';
import { Tabs } from '@components/Tabs/Tabs';
import { ProjectsNavigation } from '@pages/projects/header/ProjectsNavigation';
import React from 'react';

import './Projects.scss';

export const ProjectsCreatePart2 = ({
    config,
    isLoading,
}: {
    config: any;
    isLoading: boolean;
}) => {
    const tabsConfig = [
        {
            title: 'All details',
            content: <FormGenerator settings={config} />,
        },
    ];

    return (
        <>
            <NavigationHeader detailTitle="Creating new project">
                <ProjectsNavigation
                    submitButtonTitle="Save new project"
                    isLoading={isLoading}
                    isDisabled={isLoading}
                    isLastStep={true}
                />
            </NavigationHeader>

            <Tabs config={tabsConfig} />
        </>
    );
};
