import { ReactComponent as PdfIcon } from '@assets/pdfFile.svg';
import React from 'react';

type SingleFileProps = {
    file: File;
};

export const SingleFileCreate = ({ file }: SingleFileProps) => {
    return (
        <div key={file.name} className="file-single">
            <div className="description-part">
                <PdfIcon />
                <div>
                    <div className="name">{file.name}</div>
                    {/* todo replace with real data after getting it from API */}
                    <div className="date-file-size">8 Sep, 07:38 - (2 MB)</div>
                </div>
            </div>
        </div>
    );
};
