import { FieldType, Type, settingsType } from '@components/Forms/FormGenerator';

export const deliverableFormConfig = (
    availableProducts: any,
    isOldFilesShown?: boolean,
    planTaskId?: string,
    stampTaskId?: string,
    stampType?: string,
    stampsData?: any,
    setStampId?: any,
    plansData?: any,
    setPlanId?: any,
    projectId?: string
): settingsType[] => [
    ...(availableProducts?.some((avProduct: any) => ['PE Stamp', 'EE Stamp', 'Wet Stamp'].includes(avProduct))
        ? [
              {
                  config: {
                      fieldType: FieldType.Hr,
                      type: Type.Text,
                      name: '',
                      title: 'Stamps',
                  },
              },
              ...(stampsData?.length > 1
                  ? [
                        {
                            config: {
                                fieldType: FieldType.Select,
                                type: Type.Text,
                                name: 'stampTask',
                                title: 'Stamp task',
                                selectData: stampsData,
                                onChange: (id: any) => setStampId?.(id),
                            },
                            validation: {
                                required: false,
                            },
                            global: {
                                viewMode: {
                                    boldLabel: true,
                                    featuredData: true,
                                    hidden: true,
                                },
                            },
                        },
                    ]
                  : []),

              {
                  config: {
                      fieldType: FieldType.FilesUpload,
                      type: Type.Text,
                      name: 'stamps',
                      title: '',
                      deliverable: true,
                      selectedType: stampType,
                      isOldFilesShown: isOldFilesShown,
                      attachId: projectId,
                      resourceId: stampTaskId,
                  },
                  global: {
                      viewMode: {
                          boldLabel: true,
                      },
                  },
              },
          ]
        : []),
    ...(availableProducts?.includes('Plans')
        ? [
              {
                  config: {
                      fieldType: FieldType.Hr,
                      type: Type.Text,
                      name: '',
                      title: 'Plans',
                  },
              },
              ...(plansData?.length > 1
                  ? [
                        {
                            config: {
                                fieldType: FieldType.Select,
                                type: Type.Text,
                                name: 'planTask',
                                title: 'Plan task',
                                selectData: plansData,
                                onChange: (id: any) => setPlanId?.(id),
                            },
                            validation: {
                                required: false,
                            },
                            global: {
                                viewMode: {
                                    boldLabel: true,
                                    featuredData: true,
                                    hidden: true,
                                },
                            },
                        },
                    ]
                  : []),
              {
                  config: {
                      fieldType: FieldType.FilesUpload,
                      type: Type.Text,
                      name: 'plans',
                      title: '',
                      deliverable: true,
                      isOldFilesShown: isOldFilesShown,
                      attachId: projectId,
                      resourceId: planTaskId,
                  },
                  global: {
                      viewMode: {
                          boldLabel: true,
                      },
                  },
              },
          ]
        : []),
    ...(availableProducts?.includes('Other')
        ? [
              {
                  config: {
                      fieldType: FieldType.Hr,
                      type: Type.Text,
                      name: '',
                      title: 'Others',
                  },
              },
              {
                  config: {
                      fieldType: FieldType.FilesUpload,
                      type: Type.Text,
                      name: 'other',
                      title: '',
                      deliverable: true,
                      isOldFilesShown: isOldFilesShown,
                  },
                  global: {
                      viewMode: {
                          boldLabel: true,
                      },
                  },
              },
          ]
        : []),
];
