const baseUrl = process.env.REACT_APP_BASE_API_URL;
export const DefaultBaseURL = `${baseUrl}/api/v1`;

export const AdminBaseURL = `${baseUrl}/api/v1/admin`;

const getParamWithoutPrefix = (key: string, type?: string) => {
    return key.replace(type ? `_${type}` : '', '');
};

export const getMutationUrlParams = (
    baseUrl: string,
    urlParams?: Partial<{ [x: string]: any }[]> | Partial<{ [x: string]: any }>,
    prefix?: string
) => {
    const url = new URL(baseUrl);

    const paramsArray =
        urlParams &&
        Object.entries(urlParams)
            .map(([key, value]) => {
                if (key === 'search_' + prefix) {
                    const keyWithoutPrefix = getParamWithoutPrefix(key, prefix);
                    return { [keyWithoutPrefix]: value };
                }

                if (
                    key === 'filter.id' ||
                    key === 'filter.status' ||
                    key === 'filter.type' ||
                    key === 'filter.assignee' ||
                    key === 'filter.group' ||
                    key === 'sortBy'
                ) {
                    return { [key]: value };
                }

                if (key === 'page') {
                    return { [key]: value };
                }

                return undefined;
            })
            .filter(Boolean);

    if (paramsArray?.length) {
        paramsArray.forEach(param => {
            if (param)
                url.searchParams.append(
                    Object.keys(param)[0],
                    Object.values(param)[0]
                );
        });
    }

    return url;
};
