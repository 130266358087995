import { ProjectsCreatePart1 } from '@pages/projects/ProjectsCreatePart1';
import { ProjectsCreatePart2 } from '@pages/projects/ProjectsCreatePart2';
import {
    allDetailsProjectFormConfig,
    projectsFormConfig,
} from '@pages/projects/ProjectsFormConfig';
import { useGetAllCompanies } from '@queries/companies.query';
import { useCreateFile } from '@queries/files.query';
import { INewProject, useCreateProject } from '@queries/projects.query';
import { TaskTypes } from '@queries/tasks.query';
import { getGroup } from '@utils/getGroup';
import { getPostMutationObject } from '@utils/getPostMutationObject';
import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { toast } from 'react-toastify';

import {
    getElementsToValidate,
    isInvalidField,
} from '../../validations/helpers';
import './Projects.scss';

export const ProjectsCreate = () => {
    const navigate = useNavigate();

    const params = useParams();
    const { step } = params;

    const projectsMutation = useCreateProject();
    const createFileMutation = useCreateFile();

    const [isLoading, setIsLoading] = useState(false);
    const [companyId, setCompanyId] = useState('');

    // Files
    const [selectedFile, setSelectedFile] = useState<File[]>();
    const [zipHouseFile, setZipHouseFile] = useState<File[]>();
    const [mainPanelWithDeadFrontOffImage, setMainPanelWithDeadFrontOffImage] =
        useState<File[]>();

    const [products, setProducts] = useState([
        {
            id: TaskTypes.DESIGN_PLANS,
            name: 'Plans',
            checked: true,
        },
        {
            id: TaskTypes.PE_STAMP,
            name: 'PE Stamp',
        },
        {
            id: TaskTypes.EE_STAMP,
            name: 'EE Stamp',
        },
        {
            id: TaskTypes.WET_STAMP,
            name: 'Wet Stamp',
        },
        {
            id: TaskTypes.OTHER,
            name: 'Other',
        },
    ]);
    const [isLastStep, setIsLastStep] = useState(false);

    const onSetProducts = (data: any) => {
        // this disables second step when 'plans' are not selected
        if (data[0].checked) {
            setIsLastStep(false);
        } else {
            setIsLastStep(true);
        }
        setProducts(data);
    };

    const { data: companiesData } = useGetAllCompanies({
        ['filter.status']: 'active',
    });

    const companiesSelect = companiesData?.data?.map((company: any) => {
        return {
            id: company.id,
            valueToShow: company.name,
        };
    });

    const projectsConfig = projectsFormConfig(
        companiesSelect,
        setCompanyId,
        onSetProducts,
        setSelectedFile,
        selectedFile
    );

    const allDetailsConfig = allDetailsProjectFormConfig(
        setZipHouseFile,
        zipHouseFile,
        setMainPanelWithDeadFrontOffImage,
        mainPanelWithDeadFrontOffImage
    );

    const onSubmit = (e: any) => {
        e.preventDefault();
        setIsLoading(true);

        const productsArray = products
            .filter((product: any) => product.checked)
            ?.map((product: any) => product.name);

        const projectsData: INewProject = getPostMutationObject(e, [
            ...projectsConfig,
        ]);

        const allDetailsData = getPostMutationObject(e, allDetailsConfig);
        if (productsArray?.includes('Plans')) {
            projectsData.projectDetail = allDetailsData;
            projectsData.projectDetail.pictureOfMainPanelWithDeadFrontOff =
                '-main-panel';
            projectsData.projectDetail.zipFileOfHousePhotos = '-zip-file-house';
        }
        // @ts-ignore
        delete projectsData.company;
        delete projectsData.files;
        projectsData && (projectsData.companyId = companyId);
        projectsData && (projectsData.products = productsArray);

        const config = isLastStep
            ? [...projectsConfig]
            : [...projectsConfig, ...allDetailsConfig];

        const { fieldsToValidate, inputsToValidate } =
            getElementsToValidate(config);

        // todo this can be reused in 'deliverables' later
        const uploadFiles = (files: File[], resourceId: string) => {
            // Create an array of promises
            const promises = files.map(file => {
                return createFileMutation.mutateAsync({
                    body: file,
                    resourceId: resourceId,
                });
            });

            // Use Promise.all to wait for all promises to resolve
            return Promise.all(promises);
        };

        if (
            isInvalidField(fieldsToValidate, inputsToValidate, {
                ...projectsData,
                ...(!isLastStep ? allDetailsData : {}),
            })
        ) {
            setIsLoading(false);
        } else {
            return projectsMutation.mutate(projectsData, {
                onSuccess: data => {
                    if (
                        selectedFile ||
                        mainPanelWithDeadFrontOffImage ||
                        zipHouseFile
                    ) {
                        const loadingFilesToast =
                            toast.loading('Uploading files...');

                        const uploads = [];

                        if (selectedFile) {
                            uploads.push(
                                uploadFiles(selectedFile, data.data.id)
                            );
                        }
                        if (mainPanelWithDeadFrontOffImage) {
                            uploads.push(
                                uploadFiles(
                                    mainPanelWithDeadFrontOffImage,
                                    data.data.id + '-main-panel'
                                )
                            );
                        }
                        if (zipHouseFile) {
                            uploads.push(
                                uploadFiles(
                                    zipHouseFile,
                                    data.data.id + '-zip-file-house'
                                )
                            );
                        }

                        Promise.all(uploads)
                            .then(() => {
                                toast.update(loadingFilesToast, {
                                    render: 'All files uploaded successfully',
                                    type: 'success',
                                    isLoading: false,
                                    autoClose: 2500,
                                });

                                toast.success('Project created successfully');
                                setIsLoading(false);
                                navigate(`/${getGroup()}/projects`);
                            })
                            .catch(() => {
                                toast.update(loadingFilesToast, {
                                    render: 'Error uploading files',
                                    type: 'error',
                                    isLoading: false,
                                    autoClose: 2500,
                                });
                                setIsLoading(false);
                            });
                    } else {
                        toast.success('Project created successfully');
                        setIsLoading(false);
                        navigate(`/${getGroup()}/projects`);
                    }
                },
                onError: () => {
                    setIsLoading(false);
                },
            });
        }
    };

    const getStep = () => {
        return (
            <>
                <span style={{ display: step === '1' ? 'block' : 'none' }}>
                    <ProjectsCreatePart1
                        companyId={companyId}
                        products={products}
                        config={projectsConfig}
                        isLastStep={isLastStep}
                        isLoading={isLoading}
                    />
                </span>

                <span style={{ display: step === '2' ? 'block' : 'none' }}>
                    <ProjectsCreatePart2
                        config={allDetailsConfig}
                        isLoading={isLoading}
                    />
                </span>
            </>
        );
    };

    return (
        <div className="projects-create content-wrapper with-header">
            <form onSubmit={e => onSubmit(e)} noValidate>
                {getStep()}
            </form>
        </div>
    );
};
