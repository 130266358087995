import { ReactComponent as SettingsIcon } from '@assets/settingsIcon.svg';
import { DetailTitle } from '@components/DetailTitle/DetailTitle';
import { FormGenerator } from '@components/Forms/FormGenerator';
import { UserContext } from '@pages/RootWithSidebar';
import { useEditUser } from '@queries/users.query';
import { getGroup } from '@utils/getGroup';
import React, { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { settingsFormConfig } from './SettingsFormConfig';

const statusOptions = [
    { id: 'active', valueToShow: 'Active' },
    { id: 'inactive', valueToShow: 'Inactive' },
];

export const Settings = () => {
    const { user } = useContext(UserContext);
    const updateMutation = useEditUser(user?.id ?? '');
    const group = getGroup();

    const convertActiveToTrue = (status: string) => {
        return status === 'active';
    };
    const taskEmailNotifications = user?.settings?.notifications?.tasks?.email;

    const [notificationSettings, setNotificationSettings] = useState(
        taskEmailNotifications
    );
    const setEmailNotifications = (status: string, type: string) => {
        setNotificationSettings({
            ...notificationSettings,
            [type]: status,
        });
    };

    useEffect(() => {
        if (taskEmailNotifications) {
            let newObj = {};
            Object.entries(taskEmailNotifications).forEach(([key, value]) => {
                // @ts-ignore
                newObj[key] = value ? 'active' : 'inactive';
            });

            setNotificationSettings(newObj);
        }
    }, [user?.settings?.notifications?.tasks?.email]);

    const onSubmit = (e: any) => {
        e.preventDefault();
        return updateMutation.mutate(
            {
                status: user?.status,
                firstName: e.target.firstName.value,
                lastName: e.target.lastName.value,
                email: e.target.email.value,
                // @ts-ignore
                settings: {
                    notifications: {
                        tasks: {
                            email: {
                                taskCreated: convertActiveToTrue(
                                    notificationSettings?.taskCreated
                                ),
                                taskAssigned: convertActiveToTrue(
                                    notificationSettings?.taskAssigned
                                ),
                                taskClosed: convertActiveToTrue(
                                    notificationSettings?.taskClosed
                                ),
                            },
                        },
                    },
                },
            },
            {
                onSuccess: () => {
                    toast.success('User settings updated!');
                },
            }
        );
    };

    return (
        <div className="users-page content-wrapper with-header">
            <form onSubmit={e => onSubmit(e)}>
                <DetailTitle title="Settings" icon={<SettingsIcon />} />

                <FormGenerator
                    settings={settingsFormConfig(
                        statusOptions,
                        setEmailNotifications,
                        group ?? undefined
                    )}
                    defaultData={{
                        ...user,
                        taskAssigned: notificationSettings?.taskAssigned,
                        taskClosed: notificationSettings?.taskClosed,
                        taskCreated: notificationSettings?.taskCreated,
                    }}
                    saveButton="Save changes"
                />
            </form>
        </div>
    );
};
