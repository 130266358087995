import { SingleFileCreate } from '@components/FilesUpload/SingleFileCreate';
import { RequiredIndicator } from '@components/Forms/components/shared/RequiredIndicator';
import React from 'react';

type CreateFilesListType = {
    title?: string;
    required?: boolean;
    files?: File[];
};

export const CreateFilesList = ({
    title = 'Files',
    required = false,
    files,
}: CreateFilesListType) => {
    return (
        <div className="files-list">
            <div className="title">
                {title} <RequiredIndicator isRequired={required} />
            </div>

            {files &&
                files?.map(file => {
                    return <SingleFileCreate key={file.name} file={file} />;
                })}
        </div>
    );
};
