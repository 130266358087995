import useUrlState from '@ahooksjs/use-url-state';
import fetchGBI from '@queries/fetchGBI';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';

import {
    AdminBaseURL,
    DefaultBaseURL,
    getMutationUrlParams,
} from './urlHelpers';

export enum CompanyStatus {
    ACTIVE = 'active',
    IN_ACTIVE = 'in_active',
}

export type INewCompany = {
    name?: string;
    address?: string;
    local?: string;
    zipCode?: string;
    city?: string;
    vatNumber?: string;
    // managerId is required when creating or editing company
    managerId?: string;
    // manager is from response when getting company
    manager?: any;
    pricePerProject?: number;
    status?: CompanyStatus;
};

export type INewCompanyLogo = {
    body: File;
    id: string;
};

export const useCreateCompany = () => {
    const client = useQueryClient();

    return useMutation({
        mutationFn: async (newCompany: INewCompany) => {
            const {
                data: { company },
            } = await axios.post(AdminBaseURL + '/companies', newCompany, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
            });
            return {
                company,
            };
        },
        onSuccess: () => {
            return client.invalidateQueries({ queryKey: ['companies'] });
        },
    });
};

export const useEditCompany = (id: string) => {
    const client = useQueryClient();

    return useMutation({
        mutationFn: async (companyNewData: INewCompany) => {
            const {
                data: { company },
            } = await axios.patch(
                AdminBaseURL + '/companies/' + id,
                companyNewData,
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem(
                            'token'
                        )}`,
                    },
                }
            );
            return {
                company,
            };
        },
        onSuccess: () => {
            return client.invalidateQueries({
                queryKey: ['companies'],
            });
        },
    });
};

export const useDeleteCompany = () => {
    const client = useQueryClient();

    return useMutation({
        mutationFn: async (companyId: string) => {
            await axios.delete(AdminBaseURL + `/companies/${companyId}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
            });
        },
        onSuccess: () => {
            return client.invalidateQueries({ queryKey: ['companies'] });
        },
    });
};

export const useGetAllCompanies = (
    searchParams?: Partial<{ [x: string]: any }>
) => {
    const [urlState] = useUrlState();
    const group = localStorage.getItem('userGroup');
    const url = group === 'admin' ? AdminBaseURL : DefaultBaseURL;
    const href = getMutationUrlParams(
        `${url}/companies`,
        searchParams ?? urlState,
        'companies'
    ).href;

    return useQuery({
        queryKey: ['companies', href, group],
        queryFn: () => fetchGBI(href),
    });
};

export const useGetCompany = (companyId: string) =>
    useQuery({
        queryKey: ['companies', companyId],
        queryFn: () => fetchGBI(AdminBaseURL + `/companies/${companyId}`),
    });

export const useUploadCompanyLogo = () => {
    const client = useQueryClient();

    return useMutation({
        mutationFn: async (newFile: INewCompanyLogo) => {
            const formData = new FormData();

            formData.append('file', newFile.body, newFile.body.name);

            const { data } = await axios.post(
                DefaultBaseURL + '/companies/' + newFile.id + '/logo',
                formData,
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem(
                            'token'
                        )}`,
                    },
                }
            );
            return {
                data,
            };
        },
        onSuccess: () => {
            return client.invalidateQueries({ queryKey: ['companyLogo'] });
        },
    });
};
