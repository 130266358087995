import { guidelineTypes } from '@pages/jurisdiction/Guidelines/GuidelinesCreate';
import React from 'react';

import './Guidelines.scss';

type JurisdictionKey = 'ahj' | 'other' | 'utility' | 'companyRequirement';

export const GuidelinesDetailsByCategory = ({
    projectId,
    type,
    data,
}: {
    projectId: string;
    type: string;
    data: any;
}) => {
    const getGuidelinesByCategory = (category: string) => {
        return data?.filter(
            (guideline: any) => guideline.category === category
        );
    };

    const guidelines = getGuidelinesByCategory(type);

    const keysToCheck: JurisdictionKey[] = [
        'companyRequirement',
        'ahj',
        'utility',
        'other',
    ];

    const nonEmptyKey = keysToCheck.find(
        key => guidelines?.[0]?.[key] !== null
    ) as JurisdictionKey;

    const jurisdictionTypes: Record<JurisdictionKey, string> = {
        ahj: 'AHJ',
        other: 'Other',
        utility: 'Utility',
        companyRequirement: 'Company',
    };

    if (guidelines?.length > 0) {
        const categoryToShow = guidelineTypes.find(
            category => category.id === type
        )?.valueToShow;

        return (
            <div className="guideline-type">
                <h5>{categoryToShow}</h5>
                <div>
                    <div className="header single-guideline">
                        <span className="type">Type</span>
                        <span className="note">Note</span>
                    </div>
                    {guidelines.map((guideline: any) => {
                        return (
                            <div
                                key={guideline.id}
                                className="single-guideline"
                            >
                                <span className="type">
                                    {nonEmptyKey &&
                                        jurisdictionTypes[nonEmptyKey]}
                                </span>
                                <span className="note">{guideline.note}</span>
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    }

    return null;
};
