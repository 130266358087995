import {
    FieldType,
    SubType,
    Type,
    settingsType,
} from '@components/Forms/FormGenerator';

export const companiesFormConfig = (
    isEditMode: boolean,
    managerSelectData?: { id: string; valueToShow: string }[],
    onManagerChange?: (data: any) => void
): settingsType[] => [
    {
        config: {
            fieldType: FieldType.Input,
            type: Type.Text,
            name: 'name',
            title: "Company's name",
        },
        validation: {
            required: true,
            minLength: 3,
            errorMessage: "Company's name must be at least 3 characters long",
        },
        global: {
            viewMode: {
                boldLabel: true,
                featuredData: true,
            },
        },
    },
    ...(isEditMode
        ? [
              {
                  config: {
                      fieldType: FieldType.Select,
                      type: Type.Text,
                      name: 'manager',
                      title: 'Manager',
                      selectData: managerSelectData,
                      onChange: (data: any) => onManagerChange?.(data),
                  },
                  global: {
                      viewMode: {
                          boldLabel: true,
                      },
                  },
              } as settingsType,
          ]
        : []),
    {
        config: {
            fieldType: FieldType.Hr,
            type: Type.Text,
            name: '',
            title: 'Invoice',
        },
    },
    {
        config: {
            fieldType: FieldType.Address,
            type: Type.Text,
            name: 'address',
            title: 'Address',
        },
        global: {
            viewMode: {
                boldData: true,
            },
        },
    },
    {
        config: {
            fieldType: FieldType.Input,
            type: Type.Text,
            name: 'vatNumber',
            title: 'VAT No.',
        },
        global: {
            viewMode: {
                boldData: true,
            },
        },
    },
    {
        config: {
            fieldType: FieldType.Input,
            type: Type.Number,
            subType: SubType.Currency,
            name: 'pricePerProject',
            title: 'Price per project',
        },
        global: {
            viewMode: {
                boldData: true,
            },
        },
    },
];
