import { FormGenerator } from '@components/Forms/FormGenerator';
import { Loader } from '@components/Loader/Loader';
import { NavigationHeader } from '@components/NavigationHeader/NavigationHeader';
import { TasksNavigation } from '@pages/tasks/header/TasksNavigation';
import { useAttachDeliverable } from '@queries/deliverables.query';
import { IResourceFileResponse } from '@queries/files.query';
import {
    IEditTask,
    useEditTask,
    useGetTask,
    useGetTasksByProject,
} from '@queries/tasks.query';
import { getGroup } from '@utils/getGroup';
import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { toast } from 'react-toastify';

import {
    getElementsToValidate,
    isInvalidField,
} from '../../validations/helpers';
import './Tasks.scss';
import { getTasksDataObject, taskTypes } from './TasksCreate';
import { tasksFormConfig } from './TasksFormConfig';
import { TasksSidebar } from './TasksSidebar';

export const TasksEdit = () => {
    const group = localStorage.getItem('userGroup');
    const params = useParams();
    const navigate = useNavigate();
    const { status, data } = useGetTask(params.id ?? '');
    const tasksMutation = useEditTask(params.id ?? '');
    const [selectedBlockedByTaskId, setSelectedBlockedByTaskId] =
        useState<string>(data?.blockedBy?.id);
    let taskData = data;
    const [taskTypeId, setTaskTypeId] = useState(data?.type);

    const setTaskType = (id: string) => {
        setTaskTypeId(id);
        taskData && (taskData.type = id);
    };

    const { data: tasksByProject } = useGetTasksByProject(
        taskData?.project.id,
        '$not:closed'
    );
    const tasksSelect = tasksByProject?.data
        ?.filter((task: any) => task.id !== params.id)
        .map((task: any) => {
            return {
                id: task.id,
                valueToShow: task.title,
            };
        });

    const [isLoading, setIsLoading] = useState(false);
    const [projectId, setProjectId] = useState(taskData?.project.id);
    const attachDeliverableMutation = useAttachDeliverable();

    const setProject = (id: string) => {
        setProjectId(id);
        taskData && (taskData.projectId = id);
    };

    const onFileUploaded = (uploadedFiles: IResourceFileResponse[]) => {
        attachDeliverableMutation.mutate(
            {
                projectId,
                fileId: uploadedFiles[0].id,
                type: taskTypeId,
            },
            {
                onSuccess: () => {
                    toast.update('deliverable-toast', {
                        render: 'File uploaded & attached successfully',
                        type: 'success',
                        isLoading: false,
                        autoClose: 2000,
                    });
                },
            }
        );
    };

    const taskConfig = tasksFormConfig({
        taskTypeSelectData: taskTypes,
        onTaskTypeChange: setTaskType,
        onProjectIdChange: setProject,
        projectUrl: `/${group}/projects/${projectId}`,
        onBlockedByTaskIdChange: (id: string) => setSelectedBlockedByTaskId(id),
        blockedByTaskSelectData: tasksSelect,
    });

    const onSubmit = (e: any) => {
        e.preventDefault();
        setIsLoading(true);

        // @ts-ignore
        const formData: IEditTask = getTasksDataObject(e);
        formData && (formData.type = taskTypeId);
        delete formData.projectId;
        formData.blockedBy = { id: selectedBlockedByTaskId };

        const { fieldsToValidate, inputsToValidate } =
            getElementsToValidate(taskConfig);

        if (
            isInvalidField(fieldsToValidate, inputsToValidate, {
                ...formData,
                projectId: projectId,
            })
        ) {
            // here can be a generic toast error message
            // toast.error('Please fill all required fields');
            setIsLoading(false);
        } else {
            return tasksMutation.mutate(formData, {
                onSuccess: () => {
                    toast.success('Task edited successfully');
                    navigate(`/${getGroup()}/tasks/${params.id}`);
                },
                onError: () => {
                    setIsLoading(false);
                },
            });
        }
    };

    return (
        <div className="tasks-edit content-wrapper with-header">
            <form onSubmit={e => onSubmit(e)} noValidate>
                <NavigationHeader detailTitle={data?.name ?? "Task's details"}>
                    <TasksNavigation
                        submitButtonTitle="Save task"
                        isLoading={isLoading}
                        isDisabled={isLoading}
                    />
                </NavigationHeader>

                <div className="row">
                    <div className="col-lg-6">
                        {status === 'success' ? (
                            <FormGenerator
                                settings={taskConfig}
                                defaultData={{
                                    ...taskData,
                                    projectId: projectId,
                                    projectName: taskData?.project?.name,
                                    blockedByTaskId: selectedBlockedByTaskId,
                                    blockedByTaskName:
                                        taskData?.blockedBy?.title,
                                }}
                                formTitle="General details"
                                onFileUploaded={onFileUploaded}
                            />
                        ) : (
                            <Loader />
                        )}
                    </div>
                    {data && (
                        <TasksSidebar
                            assignee={{
                                id: data?.assignee ? data?.assignee?.id : null,
                                valueToShow: data?.assignee
                                    ? `${data?.assignee?.firstName} ${data?.assignee?.lastName}`
                                    : 'Not Assigned',
                            }}
                            commentId={data?.project?.id}
                        />
                    )}
                </div>
            </form>
        </div>
    );
};
