// @ts-nocheck
import { ReactComponent as SortingArrowsIcon } from '@assets/sortingArrows.svg';
import { TableActions } from '@components/Table/TableActions';
import { ColumnDef } from '@tanstack/react-table';
import { handleSort } from '@utils/sorting';
import { formatDate } from '@utils/utils';
import React from 'react';

import { Projects } from './ProjectsTable';


export const projectsTableSetup = (
    handleDelete: any,
    handleEdit: any,
    urlState: any,
    setUrlState: any
): ColumnDef<Projects>[] => {
    return [
        {
            accessorKey: 'id',
            header: 'ID',
            footer: props => props.column.id,
            filterTitle: 'ID',
            size: 90,
        },
        {
            accessorKey: 'name',
            header: () => (
                <button
                    onClick={() => handleSort('name', urlState, setUrlState)}
                >
                    Project's title
                    <SortingArrowsIcon />
                </button>
            ),
            footer: props => props.column.id,
            filterTitle: "Project's title",
        },

        {
            accessorKey: 'company',
            header: () => (
                <button
                    onClick={() =>
                        handleSort('company.name', urlState, setUrlState)
                    }
                >
                    Company
                    <SortingArrowsIcon />
                </button>
            ),
            cell: data => data.cell.getValue()?.name,
            footer: props => props.column.id,
            filterTitle: 'Company',
        },
        {
            accessorKey: 'dueDate',
            header: () => (
                <button
                    onClick={() => handleSort('dueDate', urlState, setUrlState)}
                >
                    Due date
                    <SortingArrowsIcon />
                </button>
            ),
            cell: data => formatDate(data.row.getValue('dueDate')),
            footer: props => props.column.id,
            filterTitle: 'DueDate',
        },

        {
            accessorKey: 'owner.firstName',
            header: () => (
                <button
                    onClick={() =>
                        handleSort('owner.firstName', urlState, setUrlState)
                    }
                >
                    Owner
                    <SortingArrowsIcon />
                </button>
            ),
            footer: props => props.column.id,
            filterTitle: 'Owner',
        },
        {
            accessorKey: 'createdAt',
            header: () => (
                <button
                    onClick={() =>
                        handleSort('createdAt', urlState, setUrlState)
                    }
                >
                    Created
                    <SortingArrowsIcon />
                </button>
            ),
            cell: data => formatDate(data.row.getValue('createdAt')),
            footer: props => props.column.id,
            filterTitle: 'Created',
        },
        {
            accessorKey: 'updatedAt',
            header: () => (
                <button
                    onClick={() =>
                        handleSort('updatedAt', urlState, setUrlState)
                    }
                >
                    Last modified
                    <SortingArrowsIcon />
                </button>
            ),
            cell: data => formatDate(data.row.getValue('updatedAt')),
            footer: props => props.column.id,
            filterTitle: 'Last modified',
        },
        {
            accessorKey: 'projectAge',
            header: () => (
                <button
                    onClick={() =>
                        handleSort('projectAge', urlState, setUrlState)
                    }
                >
                    Project's age
                    <SortingArrowsIcon />
                </button>
            ),
            footer: props => props.column.id,
            filterTitle: 'Age',
        },
        {
            accessorKey: 'status',
            header: () => (
                <button
                    onClick={() => handleSort('status', urlState, setUrlState)}
                >
                    Status
                    <SortingArrowsIcon />
                </button>
            ),
            footer: props => props.column.id,
            filterTitle: 'Status',
        },
        {
            allowAsProps: true,
            accessorKey: 'actions',
            header: 'Actions',
            filterTitle: 'Actions',
            cell: data => {
                return (
                    <TableActions
                        handleDelete={() => handleDelete(data)}
                        handleEdit={() => handleEdit(data)}
                    />
                );
            },
        },
    ];
};
