import { FormGenerator } from '@components/Forms/FormGenerator';
import { NavigationHeader } from '@components/NavigationHeader/NavigationHeader';
import { INewCompany, useCreateCompany } from '@queries/companies.query';
import { useGetUsers } from '@queries/users.query';
import { getGroup } from '@utils/getGroup';
import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import { getElementsToValidate, isInvalidField } from 'src/validations/helpers';

import './Companies.scss';
import { companiesFormConfig } from './CompaniesFormConfig';
import { CompaniesSidebar } from './CompaniesSidebar';
import { CompaniesNavigation } from './header/CompaniesNavigation';

export const getCompanyDataObject = (
    e: any
): {
    name: string;
    address: string;
    local: string;
    zipCode: string;
    city: string;
    vatNumber: string;
    pricePerProject: number;
} => {
    return {
        name: e.target.name.value,
        address: e.target.address.value,
        local: e.target.local.value,
        zipCode: e.target.zipCode.value,
        city: e.target.city.value,
        vatNumber: e.target.vatNumber.value,
        pricePerProject: Number(e.target.pricePerProject.value),
    };
};

export const CompaniesCreate = () => {
    const navigate = useNavigate();
    const createMutation = useCreateCompany();
    const { data: userData } = useGetUsers({ ['filter.group']: 'manager' });

    const usersData = userData?.data?.map((user: any) => {
        return {
            id: user.id,
            valueToShow: user.firstName + ' ' + user.lastName,
        };
    });

    const [isLoading, setIsLoading] = useState(false);
    const [managerId, setManagerId] = useState(null);

    const setManager = (id: string) => {
        setManagerId(null);
    };

    const companyConfig = companiesFormConfig(false, usersData, setManager);

    const onSubmit = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
        setIsLoading(true);

        const companyData = getCompanyDataObject(e);
        const { fieldsToValidate, inputsToValidate } =
            getElementsToValidate(companyConfig);

        if (isInvalidField(fieldsToValidate, inputsToValidate, companyData)) {
            // here can be a generic toast error message
            // toast.error('Please fill all required fields');
            setIsLoading(false);
        } else {
            const formData: INewCompany = getCompanyDataObject(e);

            return createMutation.mutate(formData, {
                onSuccess: () => {
                    toast.success('Company created successfully');
                    navigate(`/${getGroup()}/companies`);
                },
                onError: () => {
                    setIsLoading(false);
                },
            });
        }
    };

    return (
        <div className="companies-create content-wrapper with-header">
            <form onSubmit={e => onSubmit(e)} noValidate>
                <NavigationHeader detailTitle="Creating new company">
                    <CompaniesNavigation
                        submitButtonTitle="Save new company"
                        isLoading={isLoading}
                        isDisabled={isLoading}
                    />
                </NavigationHeader>

                <div className="row">
                    <div className="col-lg-6">
                        <FormGenerator
                            settings={companyConfig}
                            defaultData={{ manager: { id: managerId } }}
                            formTitle="General details"
                        />
                    </div>
                    <CompaniesSidebar />
                </div>
            </form>
        </div>
    );
};
