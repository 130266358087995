import { AhjCreate } from '@pages/jurisdiction/AHJ/AhjCreate';
import { AhjEdit } from '@pages/jurisdiction/AHJ/AhjEdit';
import { CompanyCreate } from '@pages/jurisdiction/Company/CompanyCreate';
import { CompanyEdit } from '@pages/jurisdiction/Company/CompanyEdit';
import { Jurisdiction } from '@pages/jurisdiction/Jurisdiction';
import { JurisdictionCreate } from '@pages/jurisdiction/JurisdictionCreate';
import { JurisdictionCreateRedirect } from '@pages/jurisdiction/JurisdictionCreateRedirect';
import { JurisdictionDetails } from '@pages/jurisdiction/JurisdictionDetails';
import { OtherCreate } from '@pages/jurisdiction/Other/OtherCreate';
import { OtherEdit } from '@pages/jurisdiction/Other/OtherEdit';
import { UtilityCreate } from '@pages/jurisdiction/Utility/UtilityCreate';
import { UtilityEdit } from '@pages/jurisdiction/Utility/UtilityEdit';

export const jurisdictionDesignerRoutes = [
    {
        path: '/designer/jurisdiction',
        Component: Jurisdiction,
    },
    {
        path: '/designer/jurisdiction/:type/:id',
        Component: JurisdictionDetails,
    },
    {
        path: '/designer/jurisdiction/create',
        Component: JurisdictionCreateRedirect,
    },

    {
        path: '/designer/jurisdiction/:type/create',
        Component: JurisdictionCreate,
    },

    // AHJ
    {
        path: '/designer/jurisdiction/ahj/create',
        Component: AhjCreate,
    },
    {
        path: '/designer/jurisdiction/ahj/:id/edit',
        Component: AhjEdit,
    },

    // Utility
    {
        path: '/designer/jurisdiction/utility/create',
        Component: UtilityCreate,
    },
    {
        path: '/designer/jurisdiction/utility/:id/edit',
        Component: UtilityEdit,
    },

    // Company
    {
        path: '/designer/jurisdiction/company/create',
        Component: CompanyCreate,
    },
    {
        path: '/designer/jurisdiction/company/:id/edit',
        Component: CompanyEdit,
    },

    // Others
    {
        path: '/designer/jurisdiction/other/create',
        Component: OtherCreate,
    },
    {
        path: '/designer/jurisdiction/other/:id/edit',
        Component: OtherEdit,
    },
];
