// @ts-nocheck
import { ReactComponent as SortingArrowsIcon } from '@assets/sortingArrows.svg';
import { TableActions } from '@components/Table/TableActions';
import { ColumnDef } from '@tanstack/react-table';
import { handleSort } from '@utils/sorting';
import React from 'react';

import { Users } from './UsersTable';


export const jurisdictionTableSetup = (
    handleEdit: any,
    urlState?: any,
    setUrlState?: any
): ColumnDef<Users>[] => {
    return [
        {
            accessorKey: 'id',
            header: 'ID',
            footer: props => props.column.id,
            filterTitle: 'ID',
        },
        {
            accessorKey: 'name',
            header: () => (
                <button
                    onClick={() => handleSort('name', urlState, setUrlState)}
                >
                    Name
                    <SortingArrowsIcon />
                </button>
            ),
            filterTitle: 'Name',
        },
        {
            accessorKey: 'type',
            header: () => (
                <button
                    onClick={() => handleSort('type', urlState, setUrlState)}
                >
                    Type of requirements
                    <SortingArrowsIcon />
                </button>
            ),
            filterTitle: 'Type of requirements',
        },
        {
            accessorKey: 'additionalComment',
            header: () => (
                <button
                    onClick={() =>
                        handleSort('additionalComment', urlState, setUrlState)
                    }
                >
                    Additional comments
                    <SortingArrowsIcon />
                </button>
            ),
            filterTitle: 'Additional comments',
        },

        {
            allowAsProps: true,
            accessorKey: 'actions',
            header: 'Actions',
            filterTitle: 'Actions',
            cell: data => {
                return <TableActions handleEdit={() => handleEdit(data)} />;
            },
        },
    ];
};
