import { Loader } from '@components/Loader/Loader';
import { useDeleteProject, useGetProjects } from '@queries/projects.query';
import {
    UserStatus,
    useDeleteUser,
    useEditUser,
    useGetUsers,
} from '@queries/users.query';
import { useQueryClient } from '@tanstack/react-query';
import { getGroup } from '@utils/getGroup';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';

import { ProjectsTable } from '../projects/ProjectsTable';
import { projectsTableSetupSidebar } from '../projects/ProjectsTableSidebarConfig';
import { UsersTable } from '../users/UsersTable';
import { usersTableSidebarSetup } from '../users/UsersTableSidebarConfig';
import './Companies.scss';

export const CompaniesSidebar = () => {
    const client = useQueryClient();
    const location = useLocation();
    const navigate = useNavigate();

    const {
        fetchStatus: projectsFetchStatus,
        status: projectsStatus,
        data: projects,
    } = useGetProjects();
    const deleteProjectMutation = useDeleteProject();

    const {
        fetchStatus: usersFetchStatus,
        status: usersStatus,
        data: users,
    } = useGetUsers({
        ['filter.group']: 'manager',
    });
    const deleteUserMutation = useDeleteUser();

    const handleProjectDelete = (data: any) => {
        deleteProjectMutation.mutate(data.row.original.id);
    };

    const handleUserDelete = (data: any) => {
        deleteUserMutation.mutate(data.row.original.id);
    };

    const handleProjectEdit = (data: any) => {
        return navigate(`/${getGroup()}/projects/${data.row.original.id}/edit`);
    };

    const handleUserEdit = (data: any) => {
        return navigate(`/${getGroup()}/users/${data.row.original.id}/edit`);
    };

    const [userId, setUserId] = useState('');
    const [userStatus, setUserStatus] = useState(UserStatus.ACTIVE);

    const usersMutation = useEditUser(userId);

    const handlePause = (id: string, status: UserStatus) => {
        setUserId(id);
        setUserStatus(
            status === UserStatus.ACTIVE
                ? UserStatus.DISABLED
                : UserStatus.ACTIVE
        );
    };

    useEffect(() => {
        if (userId !== '') {
            usersMutation.mutate(
                {
                    status: userStatus,
                },
                {
                    onSuccess: () => {
                        return client.invalidateQueries({
                            queryKey: ['users'],
                        });
                    },
                }
            );
        }
    }, [userId, userStatus]);

    const getProjectsSidebar = () => {
        if (projectsFetchStatus === 'fetching') {
            return <Loader />;
        }

        return (
            projectsStatus === 'success' && (
                <>
                    <h4>Projects</h4>
                    <ProjectsTable
                        className="sidebar"
                        propData={projects.data}
                        tableSetup={projectsTableSetupSidebar(
                            handleProjectDelete,
                            handleProjectEdit
                        )}
                        isDropdownEnabled={false}
                        isFooterEnabled={false}
                    />
                </>
            )
        );
    };

    const getUsersSidebar = () => {
        if (usersFetchStatus === 'fetching') {
            return <Loader />;
        }

        return (
            usersStatus === 'success' && (
                <>
                    <h4>Users</h4>
                    <UsersTable
                        className="sidebar"
                        propData={users.data}
                        tableSetup={usersTableSidebarSetup(
                            handleUserDelete,
                            handleUserEdit,
                            handlePause
                        )}
                        isDropdownEnabled={false}
                        isFooterEnabled={false}
                    />
                </>
            )
        );
    };

    return (
        <div
            className={classNames('sidebar-wrapper col-lg-6', {
                disabled: location.pathname.includes('edit'),
            })}
        >
            {getProjectsSidebar()}
            {getUsersSidebar()}
        </div>
    );
};
