import { FormGenerator } from '@components/Forms/FormGenerator';
import { guidelinesSelectFormConfig } from '@components/Guidelines/guidelinesConfig';
import {
    useGetAllGuidelinesByProjectId,
    useGetAllJurisdictions,
} from '@queries/guidelines.query';
import React, { useEffect } from 'react';

export const GuidelinesSelectEdit = ({ projectId }: { projectId: string }) => {
    const { data: companyGuidelines } = useGetAllJurisdictions('company');
    const { data: otherGuidelines } = useGetAllJurisdictions('other');
    const { data: ahjGuidelines } = useGetAllJurisdictions('ahj');
    const { data: utilityGuidelines } = useGetAllJurisdictions('utility');
    const { data: allGuidelines } = useGetAllGuidelinesByProjectId(projectId);

    const companyGuidelinesData = companyGuidelines?.data?.map(
        (guideline: any) => {
            return {
                id: guideline.id,
                valueToShow: guideline.name,
            };
        }
    );

    const otherGuidelinesData = otherGuidelines?.data?.map((guideline: any) => {
        return {
            id: guideline.id,
            valueToShow: guideline.name,
        };
    });

    const ahjGuidelinesData = ahjGuidelines?.data?.map((guideline: any) => {
        return {
            id: guideline.id,
            valueToShow: guideline.name,
        };
    });

    const utilityGuidelinesData = utilityGuidelines?.data?.map(
        (guideline: any) => {
            return {
                id: guideline.id,
                valueToShow: guideline.name,
            };
        }
    );

    const [selectedCompanyGuideline, setSelectedCompanyGuideline] =
        React.useState<any>(undefined);

    const [selectedOtherGuideline, setSelectedOtherGuideline] =
        React.useState<any>(undefined);

    const [selectedAhjGuideline, setSelectedAhjGuideline] =
        React.useState<any>(undefined);

    const [selectedUtilityGuideline, setSelectedUtilityGuideline] =
        React.useState<any>(undefined);

    const guidelinesConfig = guidelinesSelectFormConfig(
        companyGuidelinesData,
        setSelectedCompanyGuideline,
        otherGuidelinesData,
        setSelectedOtherGuideline,
        ahjGuidelinesData,
        setSelectedAhjGuideline,
        utilityGuidelinesData,
        setSelectedUtilityGuideline
    );

    const companyObject =
        allGuidelines?.length &&
        allGuidelines?.find((guideline: any) => guideline.companyRequirement);
    const otherObject =
        allGuidelines?.length &&
        allGuidelines?.find((guideline: any) => guideline.other);
    const ahjObject =
        allGuidelines?.length &&
        allGuidelines?.find((guideline: any) => guideline.ahj);
    const utilityObject =
        allGuidelines?.length &&
        allGuidelines?.find((guideline: any) => guideline.utility);

    useEffect(() => {
        setSelectedCompanyGuideline(
            companyObject?.companyRequirement?.id ?? undefined
        );
        setSelectedOtherGuideline(otherObject?.other?.id ?? undefined);
        setSelectedAhjGuideline(ahjObject?.ahj?.id ?? undefined);
        setSelectedUtilityGuideline(utilityObject?.utility?.id ?? undefined);
    }, [companyObject, otherObject, ahjObject, utilityObject]);

    return (
        <FormGenerator
            settings={guidelinesConfig}
            defaultData={{
                companyRequirementId: selectedCompanyGuideline,
                otherRequirementId: selectedOtherGuideline,
                ahjRequirementId: selectedAhjGuideline,
                utilityRequirementId: selectedUtilityGuideline,
            }}
        />
    );
};
