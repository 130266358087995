import { DropdownsFilters } from '@components/Table/DropdownsFilters';
import { useGetUsers } from '@queries/users.query';
import React from 'react';

export const DropdownFilterAssignee = ({
    currentUserId,
}: {
    currentUserId?: string;
}) => {
    const { data: usersData } = useGetUsers(undefined, true);

    const assigneeOptions = usersData?.data
        ? usersData.data
              .map((user: any) => {
                  return {
                      label: user.firstName + ' ' + user.lastName,
                      value: user.id,
                      filterOption: {
                          ['filter.assignee']: user.id,
                      },
                  };
              })
              .filter((user: any) => user.value !== currentUserId)
        : [];

    return (
        <DropdownsFilters
            placeholder="Assignee"
            options={[
                {
                    label: 'Assigned to me',
                    value: currentUserId ?? '',
                    filterOption: {
                        ['filter.assignee']: currentUserId,
                    },
                },
                ...assigneeOptions,
            ]}
        />
    );
};
