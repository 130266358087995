import { ReactComponent as PlusIcon } from '@assets/plusIcon.svg';
import { CreateFilesList } from '@components/FilesUpload/CreateFilesList';
import { FilesList } from '@components/FilesUpload/FilesList';
import { FilesListDeliverable } from '@components/FilesUpload/FilesListDeliverable';
import {
    configType,
    globalType,
    validationType,
} from '@components/Forms/FormGenerator';
import { UserContext } from '@pages/RootWithSidebar';
import { useUploadCompanyLogo } from '@queries/companies.query';
import { useAttachDeliverable } from '@queries/deliverables.query';
import { IResourceFileResponse, useCreateFile } from '@queries/files.query';
import { TaskTypes } from '@queries/tasks.query';
import classNames from 'classnames';
import React, {
    ChangeEventHandler,
    useContext,
    useEffect,
    useState,
} from 'react';
import { useLocation, useParams } from 'react-router';
import { toast } from 'react-toastify';

import './FilesUpload.scss';

type FilesUploadProps = {
    config?: configType;
    global?: globalType;
    validation?: validationType;
    disabled?: boolean;
    defaultData?: any;
    onFileUploaded?: (files: IResourceFileResponse[]) => void;
};

export const FilesUpload = ({
    disabled,
    config,
    validation,
    onFileUploaded,
}: FilesUploadProps) => {
    const { user } = useContext(UserContext);

    const onChange = config?.onChange;

    const location = useLocation();
    const isCreatePage = location.pathname.includes('create');

    const params = useParams();
    const id = config?.resourceId ?? params.id ?? '';

    const [selectedFile, setSelectedFile] = useState<File[]>();
    const [isFileUploading, setIsFileUploading] = useState(false);

    const createMutation = useCreateFile();
    const createUploadCompanyLogoMutation = useUploadCompanyLogo();
    const attachDeliverableMutation = useAttachDeliverable();

    useEffect(() => {
        if (config?.files?.length) {
            setSelectedFile(config?.files);
        }
    }, [config?.files?.length]);

    const handleFileInput: ChangeEventHandler<HTMLInputElement> = e => {
        const {
            target: { files },
        } = e;

        if (files?.length) {
            const merged = selectedFile
                ? [...selectedFile, ...Array.from(files)]
                : Array.from(files);

            setSelectedFile(merged);
            if (onChange) onChange(merged);
        }
    };

    const deliverableType = () => {
        switch (config?.name) {
            case 'plans':
                return TaskTypes.DESIGN_PLANS;

            case 'stamps':
                return config?.selectedType ?? TaskTypes.PE_STAMP;

            case 'other':
                return TaskTypes.OTHER;

            default:
                return TaskTypes.OTHER;
        }
    };

    const uploadFile = () => {
        if (selectedFile) {
            setIsFileUploading(true);
            const toastLoading = toast.loading('Uploading file(s)');

            if (config?.isUploadLogo) {
                return createUploadCompanyLogoMutation.mutate(
                    {
                        body: selectedFile[0],
                        id: params.id ?? user.company.id ?? '',
                    },
                    {
                        onSuccess: () => {
                            toast.update(toastLoading, {
                                render: 'File uploaded successfully',
                                type: 'success',
                                isLoading: false,
                                autoClose: 2500,
                            });
                            setIsFileUploading(false);
                        },
                    }
                );
            } else {
                Array.from(selectedFile).forEach(file => {
                    return createMutation.mutate(
                        {
                            body: file,
                            resourceId: id,
                        },
                        {
                            onSuccess: uploadedFiles => {
                                toast.update(toastLoading, {
                                    render: 'File uploaded successfully',
                                    type: 'success',
                                    isLoading: false,
                                    autoClose: 2500,
                                });

                                onFileUploaded && onFileUploaded(uploadedFiles);

                                if (config?.deliverable) {
                                    return attachDeliverableMutation.mutate(
                                        {
                                            projectId: params.id,
                                            fileId: uploadedFiles[0].id,
                                            type: deliverableType(),
                                        },
                                        {
                                            onSuccess: () => {
                                                toast.update(toastLoading, {
                                                    render: 'File uploaded & attached successfully',
                                                    type: 'success',
                                                    isLoading: false,
                                                    autoClose: 2500,
                                                });
                                                setIsFileUploading(false);
                                            },
                                        }
                                    );
                                } else {
                                    setIsFileUploading(false);
                                }
                            },
                        }
                    );
                });
            }
        }
    };

    useEffect(() => {
        if (createMutation.isSuccess || createMutation.isError) {
            setIsFileUploading(false);
        }

        if (selectedFile?.length && !isCreatePage) {
            uploadFile();
        }
    }, [selectedFile]);

    return (
        <div className="files-upload form-group">
            {params.id ? (
                config?.deliverable ? (
                    <FilesListDeliverable
                        name={config?.name}
                        withStatus={config?.deliverable}
                        isOldFilesShown={config?.isOldFilesShown}
                    />
                ) : (
                    <FilesList resourceId={id} title={config?.title} />
                )
            ) : (
                <CreateFilesList
                    files={selectedFile}
                    title={config?.title}
                    required={validation?.required}
                />
            )}

            {!disabled && (
                <div className="file-upload-input">
                    <input type="file" onChange={handleFileInput} id="files" />

                    <div className="upload-button">
                        <PlusIcon
                            className={classNames({
                                uploading: isFileUploading,
                            })}
                        />
                        <div className="upload-description">
                            {isFileUploading ? (
                                <div>Uploading...</div>
                            ) : (
                                <>
                                    <div>Upload new file</div>
                                    <div>
                                        File can not be larger than 100 MB
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};
