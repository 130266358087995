import { Redirect } from '@components/LoginPage/Redirect';
import React from 'react';
import { useRouteError } from 'react-router-dom';

export const ErrorPage = () => {
    const error: any = useRouteError();

    return (
        <div id="error-page">
            <Redirect />
            <h1>Oops!</h1>
            <p>Sorry, an unexpected error has occurred.</p>
            <p>This page is in progress or you just found a broken URL.</p>
            <p>
                <i>{error?.statusText || error?.message}</i>
            </p>
        </div>
    );
};
