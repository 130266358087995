// @ts-nocheck
import { SingleFile } from '@components/FilesUpload/SingleFile';
import { useGetDeliverableByProjectId } from '@queries/deliverables.query';
import React from 'react';
import { useParams } from 'react-router';

type FilesList = {
    name?: string;
    withStatus?: boolean;
    isOldFilesShown?: boolean;
};

export const FilesListDeliverable = ({
    name = 'files',
    withStatus = false,
    isOldFilesShown = true,
}: FilesList) => {
    const params = useParams();
    const projectId = params.id ?? '';

    const { data: deliverablesData } = useGetDeliverableByProjectId(projectId);

    const deliverables = deliverablesData
        ?.map(deliverable => {
            // @ts-ignore
            if (deliverable.type === 'design_plans' && name === 'plans') {
                return deliverable;
            }

            if (
                name === 'stamps' &&
                (deliverable.type === 'pe_stamp' ||
                    deliverable.type === 'ee_stamp' ||
                    deliverable.type === 'wet_stamp')
            ) {
                return deliverable;
            }

            if (deliverable.type === 'other' && name === 'other') {
                return deliverable;
            }

            return null;
        })
        .filter(item => {
            if (isOldFilesShown) {
                return item !== null;
            } else {
                return item !== null && item.status !== 'old';
            }
        })
        .sort((a, b) => {
            if (a.status === 'old' && b.status !== 'old') {
                return 1;
            }
            if (a.status !== 'old' && b.status === 'old') {
                return -1;
            }
            return 0;
        });

    return (
        <div className="files-list">
            {/*<div className="title">{title}</div>*/}
            {deliverables &&
                deliverables?.length > 0 &&
                deliverables?.map(deliverable => {
                    return (
                        <SingleFile
                            key={deliverable.file.id}
                            file={deliverable.file}
                            deliverableId={deliverable.id}
                            status={deliverable.status}
                            withStatus={withStatus}
                        />
                    );
                })}
        </div>
    );
};
