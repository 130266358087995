import { FormGenerator } from '@components/Forms/FormGenerator';
import { guidelinesSelectFormConfig } from '@components/Guidelines/guidelinesConfig';
import { useGetAllJurisdictions } from '@queries/guidelines.query';
import React from 'react';


export const GuidelinesSelect = ({ projectId }: { projectId: string }) => {
    const { data: companyGuidelines } = useGetAllJurisdictions('company');
    const { data: otherGuidelines } = useGetAllJurisdictions('other');
    const { data: ahjGuidelines } = useGetAllJurisdictions('ahj');
    const { data: utilityGuidelines } = useGetAllJurisdictions('utility');

    const companyGuidelinesData = companyGuidelines?.data?.map(
        (guideline: any) => {
            return {
                id: guideline.id,
                valueToShow: guideline.name,
            };
        }
    );

    const otherGuidelinesData = otherGuidelines?.data?.map((guideline: any) => {
        return {
            id: guideline.id,
            valueToShow: guideline.name,
        };
    });

    const ahjGuidelinesData = ahjGuidelines?.data?.map((guideline: any) => {
        return {
            id: guideline.id,
            valueToShow: guideline.name,
        };
    });

    const utilityGuidelinesData = utilityGuidelines?.data?.map(
        (guideline: any) => {
            return {
                id: guideline.id,
                valueToShow: guideline.name,
            };
        }
    );

    const [selectedCompanyGuideline, setSelectedCompanyGuideline] =
        React.useState<any>(undefined);

    const [selectedOtherGuideline, setSelectedOtherGuideline] =
        React.useState<any>(undefined);

    const [selectedAhjGuideline, setSelectedAhjGuideline] =
        React.useState<any>(undefined);

    const [selectedUtilityGuideline, setSelectedUtilityGuideline] =
        React.useState<any>(undefined);

    const guidelinesConfig = guidelinesSelectFormConfig(
        companyGuidelinesData,
        setSelectedCompanyGuideline,
        otherGuidelinesData,
        setSelectedOtherGuideline,
        ahjGuidelinesData,
        setSelectedAhjGuideline,
        utilityGuidelinesData,
        setSelectedUtilityGuideline
    );

    return (
        <FormGenerator
            settings={guidelinesConfig}
            defaultData={{
                companyRequirementId: selectedCompanyGuideline,
                otherRequirementId: selectedOtherGuideline,
                ahjRequirementId: selectedAhjGuideline,
                utilityRequirementId: selectedUtilityGuideline,
            }}
        />
    );
};
