import {
    FieldType,
    SubType,
    Type,
    settingsType,
} from '@components/Forms/FormGenerator';
import { UserGroups } from '@queries/users.query';

export const settingsFormConfig = (
    selectData?: { id: string; valueToShow: string }[],
    onEmailNotificationsChange?: (data: string, type: string) => void,
    group?: string
): settingsType[] => [
    {
        config: {
            fieldType: FieldType.Input,
            type: Type.Text,
            subType: SubType.FullName,
            name: 'firstName',
            title: 'First name',
        },
        global: {
            viewMode: {
                boldLabel: true,
                featuredData: true,
            },
        },
    },
    {
        config: {
            fieldType: FieldType.Input,
            type: Type.Email,
            name: 'email',
            title: 'E-mail',
        },
        global: {
            viewMode: {
                boldLabel: true,
            },
        },
    },
    {
        config: {
            fieldType: FieldType.Hr,
            type: Type.Text,
            name: '',
            title: 'E-mail notifications',
        },
    },
    {
        config: {
            fieldType: FieldType.Select,
            type: Type.Text,
            name: 'taskAssigned',
            title: 'Task assigned',
            selectData: selectData,
            onChange: (data: any) =>
                onEmailNotificationsChange?.(data, 'taskAssigned'),
        },
    },
    {
        config: {
            fieldType: FieldType.Select,
            type: Type.Text,
            name: 'taskClosed',
            title: 'Task closed',
            selectData: selectData,
            onChange: (data: any) =>
                onEmailNotificationsChange?.(data, 'taskClosed'),
        },
    },
    {
        config: {
            fieldType: FieldType.Select,
            type: Type.Text,
            name: 'taskCreated',
            title: 'Task created',
            selectData: selectData,
            onChange: (data: any) =>
                onEmailNotificationsChange?.(data, 'taskCreated'),
        },
    },
    ...(group === UserGroups.MANAGER || group === UserGroups.EMPLOYEE
        ? [
              {
                  config: {
                      fieldType: FieldType.FilesUpload,
                      type: Type.Text,
                      name: 'files',
                      title: 'Company logo',
                      isUploadLogo: true,
                  },
                  global: {
                      viewMode: {
                          boldLabel: true,
                      },
                  },
              } as settingsType,
          ]
        : []),
];
