import { ReactComponent as ProjectsIcon } from '@assets/projectsIcon.svg';
import { ReactComponent as SettingsIcon } from '@assets/settingsIcon.svg';
import { ReactComponent as TasksIcon } from '@assets/tasksIcon.svg';

export const peEngineerMenu = [
    {
        name: 'Projects',
        icon: ProjectsIcon,
        path: '/pe-engineer/projects',
    },
    {
        name: 'Tasks',
        icon: TasksIcon,
        path: '/pe-engineer/tasks',
    },
    // {
    //     name: 'Jurisdiction',
    //     icon: JurisdictionIcon,
    //     path: '/pe-engineer/jurisdiction',
    // },
    {
        name: 'Settings',
        icon: SettingsIcon,
        path: '/pe-engineer/settings/edit',
    },
];
