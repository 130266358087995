import { FormGenerator } from '@components/Forms/FormGenerator';
import { Loader } from '@components/Loader/Loader';
import { NavigationHeader } from '@components/NavigationHeader/NavigationHeader';
import { GuidelinesCreate } from '@pages/jurisdiction/Guidelines/GuidelinesCreate';
import { utilityFormConfig } from '@pages/jurisdiction/Utility/UtilityFormConfig';
import { UtilityNavigation } from '@pages/jurisdiction/Utility/header/UtilityNavigation';
import { IGuideline, useCreateGuideline } from '@queries/guidelines.query';
import {
    JurisdictionTypes,
    useEditUtility,
    useGetJurisdiction,
} from '@queries/jurisdiction.query';
import { getGroup } from '@utils/getGroup';
import { getPostMutationObject } from '@utils/getPostMutationObject';
import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { toast } from 'react-toastify';

import {
    getElementsToValidate,
    isInvalidField,
} from '../../../validations/helpers';

export const UtilityEdit = () => {
    const utilityConfig = utilityFormConfig();
    const params = useParams();
    const navigate = useNavigate();
    const { status, data: utilityData } = useGetJurisdiction(
        params.id ?? '',
        JurisdictionTypes.UTILITY
    );
    const utilityMutation = useEditUtility(params.id ?? '');
    const guideLineMutation = useCreateGuideline();

    const [isLoading, setIsLoading] = useState(false);
    const [guidelinesData, setGuidelinesData] = useState<IGuideline[]>(
        utilityData?.guidelines ?? []
    );

    const onSubmit = (e: any) => {
        e.preventDefault();
        setIsLoading(true);

        const formData: any = getPostMutationObject(e, utilityConfig);
        const newGuidelines = guidelinesData.filter(guide => !guide.id);

        const { fieldsToValidate, inputsToValidate } =
            getElementsToValidate(utilityConfig);

        if (isInvalidField(fieldsToValidate, inputsToValidate, formData)) {
            setIsLoading(false);
        } else {
            if (newGuidelines.length > 0) {
                newGuidelines.forEach(guideline => {
                    guideLineMutation.mutate(
                        {
                            category: guideline.category,
                            note: guideline.note,
                            utilityId: params.id,
                        },
                        {
                            onSuccess: () => {
                                toast.success(
                                    'Guidelines updated successfully'
                                );
                            },
                        }
                    );
                });
            }

            return utilityMutation.mutate(formData, {
                onSuccess: () => {
                    toast.success('Utility updated successfully');
                    return navigate(
                        `/${getGroup()}/jurisdiction/utility/${params.id}`
                    );
                },
                onError: () => {
                    setIsLoading(false);
                },
            });
        }
    };

    return (
        <div className="utility-edit content-wrapper with-header">
            <div className="row">
                <div className="col-lg-6">
                    <form onSubmit={e => onSubmit(e)} noValidate>
                        <NavigationHeader
                            detailTitle={utilityData?.name ?? 'Utility details'}
                        >
                            <UtilityNavigation
                                submitButtonTitle="Save Utility"
                                isDisabled={isLoading}
                                isLoading={isLoading}
                            />
                        </NavigationHeader>

                        {status === 'success' ? (
                            <FormGenerator
                                settings={utilityConfig}
                                defaultData={utilityData}
                                formTitle="General details"
                            />
                        ) : (
                            <Loader />
                        )}
                    </form>
                </div>

                <div className="col-lg-6">
                    <GuidelinesCreate
                        guidelinesData={guidelinesData}
                        setGuidelinesData={setGuidelinesData}
                    />
                </div>
            </div>
        </div>
    );
};
