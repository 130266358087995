import { taskTypes } from '@pages/tasks/TasksCreate';
import { getStatus } from '@utils/statuses';
import { formatDate, isISODateFormat } from '@utils/utils';

export const createCSVData = (propData: any[]) => {
    if (!propData || propData.length === 0) {
        return [];
    }

    const data = JSON.parse(JSON.stringify(propData));
    return data.map((item: any, index: number) => {
        let flattenedItem: any = {
            highlightedColumn: index + 1,
        };
        const flattenObject = (obj: any, prefix = '') => {
            Object.keys(obj).forEach(key => {
                if (typeof obj[key] === 'object' && obj[key] !== null) {
                    flattenObject(obj[key], `${prefix}${key}.`);
                } else {
                    if (isISODateFormat(obj[key])) {
                        obj[key] = formatDate(obj[key]) ?? obj[key];
                    }
                    if (key === 'status') {
                        obj[key] = getStatus(obj[key]);
                    }
                    if (key === 'type') {
                        obj[key] =
                            taskTypes.find((type: any) => type.id === obj[key])
                                ?.valueToShow ?? obj[key];
                    }
                    flattenedItem[`${prefix}${key}`] = obj[key];
                }
            });
        };
        flattenObject(item);
        return flattenedItem;
    });
};

export const createCSVColumns = (propData: any[]) => {
    if (!propData || propData.length === 0) {
        return [];
    }

    const firstItem = propData[0];
    const columns = Object.keys(firstItem).map(key => {
        const displayName = key
            .replace(/([A-Z])/g, ' $1') // insert a space before all capital letters
            .replace(/^./, str => str.toUpperCase()); // capitalize the first letter
        return {
            id: key,
            displayName: displayName,
        };
    });

    columns.unshift({
        id: 'highlightedColumn',
        displayName: 'Projects',
    });

    return columns;
};
