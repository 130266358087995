import { FieldType, Type, settingsType } from '@components/Forms/FormGenerator';

export const verificationFormConfig = (): settingsType[] => [
    {
        config: {
            fieldType: FieldType.Input,
            type: Type.Text,
            name: 'email',
            title: 'E-mail',
        },
        validation: {
            required: true,
            minLength: 3,
            errorMessage: 'E-mail must be at least 3 characters long',
        },
        global: {
            viewMode: {
                featuredData: true,
            },
        },
    },
    {
        config: {
            fieldType: FieldType.Input,
            type: Type.Text,
            name: 'code',
            title: 'Verification code',
        },
        validation: {
            required: true,
            minLength: 6,
            maxLength: 6,
            pattern: 'd*',
            errorMessage: 'Verification code must be 6 characters long',
        },
        global: {
            viewMode: {
                featuredData: true,
            },
        },
    },
];
