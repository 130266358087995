import { FormGenerator } from '@components/Forms/FormGenerator';
import { Loader } from '@components/Loader/Loader';
import { NavigationHeader } from '@components/NavigationHeader/NavigationHeader';
import { Tabs } from '@components/Tabs/Tabs';
import { GuidelinesCreate } from '@pages/jurisdiction/Guidelines/GuidelinesCreate';
import { UtilityNavigation } from '@pages/jurisdiction/Utility/header/UtilityNavigation';
import {
    JurisdictionTypes,
    useDeleteUtility,
    useGetJurisdiction,
} from '@queries/jurisdiction.query';
import { getGroup } from '@utils/getGroup';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { toast } from 'react-toastify';

import '../Jurisdiction.scss';
import { utilityFormConfig } from './UtilityFormConfig';

export const UtilityDetails = () => {
    const params = useParams();
    const navigate = useNavigate();
    const jurisdictionId = params.id ?? '';
    const jurisdictionType = params.type ?? '';
    const { status, data } = useGetJurisdiction(
        jurisdictionId,
        jurisdictionType as JurisdictionTypes
    );
    const [guidelinesData, setGuidelinesData] = useState<any>([]);

    const deleteMutation = useDeleteUtility();

    useEffect(() => {
        setGuidelinesData(data?.guidelines ?? []);
    }, [data?.guidelines]);

    const handleDelete = () => {
        deleteMutation.mutate(jurisdictionId, {
            onSuccess: () => {
                toast.success('Utility deleted successfully');
                return navigate(`/${getGroup()}/jurisdiction`);
            },
        });
    };

    const tabsConfig = [
        {
            title: 'Overview',
            content:
                status === 'success' ? (
                    <FormGenerator
                        settings={utilityFormConfig()}
                        defaultData={data}
                    />
                ) : (
                    <Loader />
                ),
        },
    ];

    return (
        <div className="utility-details content-wrapper with-header">
            <NavigationHeader
                detailTitle={data?.name ?? 'Utility details'}
                onBack={() => navigate(`/${getGroup()}/jurisdiction`)}
            >
                <UtilityNavigation handleDelete={handleDelete} />
            </NavigationHeader>

            <div className="row">
                <div className="col-lg-6">
                    <Tabs config={tabsConfig} />
                </div>
                <div className="col-lg-6">
                    {guidelinesData.length > 0 ? (
                        <GuidelinesCreate
                            guidelinesData={guidelinesData}
                            setGuidelinesData={setGuidelinesData}
                        />
                    ) : (
                        <>
                            <h4>Guidelines</h4>
                            <p>No guidelines</p>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};
