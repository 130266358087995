import useUrlState from '@ahooksjs/use-url-state';
import { ReactComponent as EmptyUsersIcon } from '@assets/emptyUsersIcon.svg';
import { ReactComponent as UsersIcon } from '@assets/usersIcon.svg';
import { DetailTitle } from '@components/DetailTitle/DetailTitle';
import { EmptyPage } from '@components/EmptyPage/EmptyPage';
import { Loader } from '@components/Loader/Loader';
import { Pagination } from '@components/Pagination/Pagination';
import {
    UserStatus,
    useDeleteUser,
    useEditUser,
    useGetUsers,
} from '@queries/users.query';
import { useQueryClient } from '@tanstack/react-query';
import { getGroup } from '@utils/getGroup';
import { isObjectEmpty } from '@utils/utils';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';

import './Users.scss';
import { UsersTable } from './UsersTable';
import { usersTableSetup } from './UsersTableConfig';


export const Users = () => {
    const client = useQueryClient();
    const navigate = useNavigate();

    const [urlState, setUrlState] = useUrlState();
    const isFiltered = !isObjectEmpty(urlState);
    const [userId, setUserId] = useState('');
    const [userStatus, setUserStatus] = useState(UserStatus.ACTIVE);

    const { data: usersData, fetchStatus } = useGetUsers();
    const deleteMutation = useDeleteUser();
    const usersMutation = useEditUser(userId);

    const handleEdit = (data: any) => {
        return navigate(`/${getGroup()}/users/${data.row.getValue('id')}/edit`);
    };

    const handleDelete = (id: string) => {
        deleteMutation.mutate(id);
    };

    const handlePause = (id: string, status: UserStatus) => {
        setUserId(id);
        setUserStatus(status);
    };

    useEffect(() => {
        if (userId !== '') {
            usersMutation.mutate(
                {
                    status: userStatus,
                },
                {
                    onSuccess: () => {
                        toast.success('User status changed successfully');
                        return client.invalidateQueries({
                            queryKey: ['users'],
                        });
                    },
                }
            );
        }
    }, [userId, userStatus]);

    const getUsersComponent = () => {
        if (fetchStatus === 'fetching') {
            return <Loader />;
        }

        if (
            fetchStatus === 'idle' &&
            (usersData?.data?.length > 0 || isFiltered)
        ) {
            return (
                <UsersTable
                    propData={usersData.data}
                    tableSetup={usersTableSetup(
                        handleDelete,
                        handleEdit,
                        handlePause,
                        urlState,
                        setUrlState
                    )}
                />
            );
        }

        if (
            fetchStatus === 'idle' &&
            usersData?.data?.length === 0 &&
            !isFiltered
        ) {
            return (
                <EmptyPage
                    title="Add new manager, designer or engineer"
                    to={`/${getGroup()}/users/create`}
                    buttonName="Create new user"
                    icon={<EmptyUsersIcon />}
                />
            );
        }

        return null;
    };

    return (
        <div className="users-page content-wrapper">
            <DetailTitle title="Users" icon={<UsersIcon />} />

            {getUsersComponent()}

            <Pagination
                currentPage={usersData?.meta.currentPage}
                totalPages={usersData?.meta.totalPages}
                prefix="users"
            />
        </div>
    );
};
