import useUrlState from '@ahooksjs/use-url-state';
import { ReactComponent as EmptyJurisdictionIcon } from '@assets/emptyJurisdictionIcon.svg';
import { ReactComponent as JurisdictionIcon } from '@assets/jurisdictionIcon.svg';
import { DetailTitle } from '@components/DetailTitle/DetailTitle';
import { EmptyPage } from '@components/EmptyPage/EmptyPage';
import { Loader } from '@components/Loader/Loader';
import { Pagination } from '@components/Pagination/Pagination';
import { useGetJurisdictions } from '@queries/jurisdiction.query';
import { getGroup } from '@utils/getGroup';
import { isObjectEmpty } from '@utils/utils';
import React from 'react';
import { useNavigate } from 'react-router';

import './Jurisdiction.scss';
import { JurisdictionTable } from './JurisdictionTable';
import { jurisdictionTableSetup } from './JurisdictionTableConfig';


export const Jurisdiction = () => {
    const group = localStorage.getItem('userGroup');
    const isAdmin = group === 'admin';
    const [urlState, setUrlState] = useUrlState();
    const isFiltered = !isObjectEmpty(urlState);
    const navigate = useNavigate();

    const { data: jurisdictionsData, fetchStatus } = useGetJurisdictions();

    const handleEdit = (data: any) => {
        return navigate(
            `/${getGroup()}/jurisdiction/${data.row.original.type}/${
                data.row.original.id
            }/edit`
        );
    };
    const getProjectsComponent = () => {
        if (fetchStatus === 'fetching') {
            return <Loader />;
        }

        if (
            fetchStatus === 'idle' &&
            (jurisdictionsData?.data?.length > 0 || isFiltered)
        ) {
            return (
                <JurisdictionTable
                    propData={jurisdictionsData.data}
                    tableSetup={jurisdictionTableSetup(
                        handleEdit,
                        urlState,
                        setUrlState
                    )}
                />
            );
        }

        if (
            fetchStatus === 'idle' &&
            jurisdictionsData?.data?.length === 0 &&
            !isFiltered
        ) {
            return (
                <EmptyPage
                    title="There are no rules provided yet"
                    to={
                        isAdmin
                            ? `/${getGroup()}/jurisdiction/create`
                            : undefined
                    }
                    buttonName="Create new jurisdiction"
                    icon={<EmptyJurisdictionIcon />}
                />
            );
        }

        return null;
    };

    return (
        <div className="jurisdiction-page content-wrapper">
            <DetailTitle title="Jurisdictions" icon={<JurisdictionIcon />} />

            {getProjectsComponent()}

            <Pagination
                currentPage={jurisdictionsData?.meta.currentPage}
                totalPages={jurisdictionsData?.meta.totalPages}
                prefix="jurisdiction"
            />
        </div>
    );
};
