import { NavLinkButton } from '@components/NavLink/NavLinkButton';
import React, { ReactNode } from 'react';

import './EmptyPage.scss';

interface IEmptyPageProps {
    title: string;
    icon?: ReactNode;
    buttonName?: string;
    to?: string;
    buttonOnClick?: () => void;
}

export const EmptyPage = ({
    title,
    buttonName,
    to,
    icon,
    buttonOnClick,
}: IEmptyPageProps) => {
    return (
        <div className="empty-page">
            <div className="info">
                {icon}
                <h4>{title}</h4>
                {to && (
                    <NavLinkButton buttonType="btn-dark" to={to}>
                        {buttonName}
                    </NavLinkButton>
                )}
            </div>
        </div>
    );
};
