import useUrlState from '@ahooksjs/use-url-state';
import fetchGBI from '@queries/fetchGBI';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';

import { DefaultBaseURL, getMutationUrlParams } from './urlHelpers';

export enum JurisdictionTypes {
    AHJ = 'ahj',
    COMPANY = 'company',
    UTILITY = 'utility',
    GUIDELINES = 'guidelines',
    OTHER = 'other',
}

export interface INewAHJ {
    name?: string;
    necCodeYear?: string;
    ifcIbcCodeYear?: string;
    otherCodes?: string;
    planSetSize?: string;
    snowLoad?: string;
    windLoad?: string;
    exposureCategory?: string;
    residentialOrCommercialRequirements?: string;
    additionalComment?: string;
    guidelines?: any;
}

export const useGetJurisdictions = () => {
    const [urlState] = useUrlState();
    const group = localStorage.getItem('userGroup');
    const url = group === 'admin' ? DefaultBaseURL : DefaultBaseURL;
    const href = getMutationUrlParams(
        `${url}/jurisdictions`,
        urlState,
        'jurisdiction'
    ).href;

    return useQuery({
        queryKey: ['jurisdictions', href, group],
        queryFn: () => fetchGBI(href),
    });
};

export const useGetJurisdiction = (id: string, type: JurisdictionTypes) => {
    let apiType: string = type;

    switch (type) {
        case JurisdictionTypes.UTILITY:
            apiType = 'utilities';
            break;
        case JurisdictionTypes.COMPANY:
            apiType = 'companies';
            break;
        case JurisdictionTypes.OTHER:
            apiType = 'others';
            break;
    }

    return useQuery({
        queryKey: ['jurisdictions', id],
        queryFn: () =>
            fetchGBI(DefaultBaseURL + `/jurisdictions/${apiType}/${id}`),
    });
};

// AHJ

export const useCreateAHJ = () => {
    const client = useQueryClient();

    return useMutation({
        mutationFn: async (newAHJ: INewAHJ) => {
            const {
                data: { project },
            } = await axios.post(
                DefaultBaseURL + '/jurisdictions/ahj',
                newAHJ,
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem(
                            'token'
                        )}`,
                    },
                }
            );
            return {
                project,
            };
        },
        onSuccess: () => {
            return client.invalidateQueries({ queryKey: ['jurisdictions'] });
        },
    });
};

export const useEditAhj = (id: string) => {
    const client = useQueryClient();

    return useMutation({
        mutationFn: async (jurisdictionNewData: any) => {
            const {
                data: { company },
            } = await axios.patch(
                DefaultBaseURL + '/jurisdictions/ahj/' + id,
                jurisdictionNewData,
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem(
                            'token'
                        )}`,
                    },
                }
            );
            return {
                company,
            };
        },
        onSuccess: () => {
            return client.invalidateQueries({
                queryKey: ['jurisdictions'],
            });
        },
    });
};

export const useDeleteAhj = () => {
    const client = useQueryClient();

    return useMutation({
        mutationFn: async (id: string) => {
            await axios.delete(DefaultBaseURL + `/jurisdictions/ahj/${id}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
            });
        },
        onSuccess: () => {
            return client.invalidateQueries({ queryKey: ['jurisdictions'] });
        },
    });
};

// Utility

export const useCreateUtility = () => {
    const client = useQueryClient();

    return useMutation({
        mutationFn: async (newData: any) => {
            const {
                data: { utility },
            } = await axios.post(
                DefaultBaseURL + '/jurisdictions/utilities',
                newData,
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem(
                            'token'
                        )}`,
                    },
                }
            );
            return {
                utility,
            };
        },
        onSuccess: () => {
            return client.invalidateQueries({ queryKey: ['jurisdictions'] });
        },
    });
};

export const useEditUtility = (id: string) => {
    const client = useQueryClient();

    return useMutation({
        mutationFn: async (newData: any) => {
            const {
                data: { company },
            } = await axios.patch(
                DefaultBaseURL + '/jurisdictions/utilities/' + id,
                newData,
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem(
                            'token'
                        )}`,
                    },
                }
            );
            return {
                company,
            };
        },
        onSuccess: () => {
            return client.invalidateQueries({
                queryKey: ['jurisdictions'],
            });
        },
    });
};

export const useDeleteUtility = () => {
    const client = useQueryClient();

    return useMutation({
        mutationFn: async (id: string) => {
            await axios.delete(
                DefaultBaseURL + `/jurisdictions/utilities/${id}`,
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem(
                            'token'
                        )}`,
                    },
                }
            );
        },
        onSuccess: () => {
            return client.invalidateQueries({ queryKey: ['jurisdictions'] });
        },
    });
};

// Company

export const useCreateCompany = () => {
    const client = useQueryClient();

    return useMutation({
        mutationFn: async (newData: any) => {
            const {
                data: { company },
            } = await axios.post(
                DefaultBaseURL + '/jurisdictions/companies',
                newData,
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem(
                            'token'
                        )}`,
                    },
                }
            );
            return {
                company,
            };
        },
        onSuccess: () => {
            return client.invalidateQueries({ queryKey: ['jurisdictions'] });
        },
    });
};

export const useEditCompany = (id: string) => {
    const client = useQueryClient();

    return useMutation({
        mutationFn: async (newData: any) => {
            const {
                data: { company },
            } = await axios.patch(
                DefaultBaseURL + '/jurisdictions/companies/' + id,
                newData,
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem(
                            'token'
                        )}`,
                    },
                }
            );
            return {
                company,
            };
        },
        onSuccess: () => {
            return client.invalidateQueries({
                queryKey: ['jurisdictions'],
            });
        },
    });
};

export const useDeleteCompany = () => {
    const client = useQueryClient();

    return useMutation({
        mutationFn: async (id: string) => {
            await axios.delete(
                DefaultBaseURL + `/jurisdictions/companies/${id}`,
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem(
                            'token'
                        )}`,
                    },
                }
            );
        },
        onSuccess: () => {
            return client.invalidateQueries({ queryKey: ['jurisdictions'] });
        },
    });
};

// Others

export const useCreateOther = () => {
    const client = useQueryClient();

    return useMutation({
        mutationFn: async (newData: any) => {
            const {
                data: { others },
            } = await axios.post(
                DefaultBaseURL + '/jurisdictions/others',
                newData,
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem(
                            'token'
                        )}`,
                    },
                }
            );
            return {
                others,
            };
        },
        onSuccess: () => {
            return client.invalidateQueries({ queryKey: ['jurisdictions'] });
        },
    });
};

export const useEditOther = (id: string) => {
    const client = useQueryClient();

    return useMutation({
        mutationFn: async (newData: any) => {
            const {
                data: { others },
            } = await axios.patch(
                DefaultBaseURL + '/jurisdictions/others/' + id,
                newData,
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem(
                            'token'
                        )}`,
                    },
                }
            );
            return {
                others,
            };
        },
        onSuccess: () => {
            return client.invalidateQueries({
                queryKey: ['jurisdictions'],
            });
        },
    });
};

export const useDeleteOther = () => {
    const client = useQueryClient();

    return useMutation({
        mutationFn: async (id: string) => {
            await axios.delete(DefaultBaseURL + `/jurisdictions/others/${id}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
            });
        },
        onSuccess: () => {
            return client.invalidateQueries({ queryKey: ['jurisdictions'] });
        },
    });
};
