import React from 'react';

export const TabsContent = ({
    config,
}: {
    config: Array<{ title: string; content: any }>;
}) => {
    const contentElements = config.map((tab, index) => {
        return (
            <div
                key={tab.title}
                className={`tab-pane fade ${index === 0 ? 'show active' : ''}`}
                id={tab.title.toLowerCase().replace(' ', '-')}
                role="tabpanel"
                aria-labelledby={
                    tab.title.toLowerCase().replace(' ', '-') + '-tab'
                }
            >
                {tab.content}
            </div>
        );
    });

    return (
        <div className="tab-content" id="myTabContent">
            {contentElements}
        </div>
    );
};
