import { Projects } from '@pages/projects/Projects';
import { ProjectsDetails } from '@pages/projects/ProjectsDetails';
import { ProjectsEdit } from '@pages/projects/ProjectsEdit';

export const projectsPEEngineerRoutes = [
    {
        path: '/pe-engineer/projects',
        Component: Projects,
    },
    {
        path: '/pe-engineer/projects/:id',
        Component: ProjectsDetails,
    },
    {
        path: '/pe-engineer/projects/:id/edit',
        Component: ProjectsEdit,
    },
];
