// @ts-nocheck
import { TableActions } from '@components/Table/TableActions';
import { ColumnDef } from '@tanstack/react-table';
import { formatDate } from '@utils/utils';
import React from 'react';

import { Tasks } from './TasksTable';


export const tasksTableSidebarSetup = (
    handleDelete: any,
    handleEdit: any
): ColumnDef<Tasks>[] => {
    return [
        {
            accessorKey: 'title',
            header: "Task's title",
            footer: props => props.column.id,
            filterTitle: "Task's title",
        },
        {
            accessorKey: 'dueDate',
            header: 'Due date',
            cell: data => formatDate(data.row.getValue('dueDate')),
            footer: props => props.column.id,
            filterTitle: 'DueDate',
        },
        {
            accessorKey: 'assigneeId',
            header: 'Assigned to',
            footer: props => props.column.id,
            filterTitle: 'Assigned to',
        },
        {
            accessorKey: 'status',
            header: 'Status',
            footer: props => props.column.id,
            filterTitle: 'Status',
            cellType: 'small',
        },
        {
            allowAsProps: true,
            accessorKey: 'actions',
            header: 'Actions',
            filterTitle: 'Actions',
            cell: data => {
                return (
                    <TableActions
                        handleDelete={() => handleDelete(data)}
                        handleEdit={() => handleEdit(data)}
                    />
                );
            },
        },
    ];
};
