import { ReactComponent as LeftChevIcon } from '@assets/left-chev.svg';
import React, { ReactNode } from 'react';
import { useNavigate } from 'react-router';

import './NavigationHeader.scss';

interface INavigationHeaderProps {
    detailTitle?: string;
    onBack?: () => void;
    defaultStatus?: string;
    children?: ReactNode;
}

export const NavigationHeader = ({
    detailTitle,
    onBack,
    children,
}: INavigationHeaderProps) => {
    const navigation = useNavigate();

    const handleBack = () => {
        return onBack ? onBack() : navigation(-1);
    };

    return (
        <div className="navigation-header">
            <div className="left">
                <LeftChevIcon className="back" onClick={handleBack} />
                <h4>{detailTitle}</h4>
            </div>

            <div className="right">{children}</div>
        </div>
    );
};
