import { useGetUserInfo } from '@queries/login.query';
import { UserGroups } from '@queries/users.query';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router';

export const Authenticate = () => {
    const navigate = useNavigate();
    const { isLoading, isError, isSuccess, error, data } = useGetUserInfo();

    useEffect(() => {
        if (isSuccess) {
            localStorage.setItem('userGroup', data?.group);
            localStorage.setItem('cognitoSub', data?.cognitoSub);

            switch (data?.group) {
                case UserGroups.ADMIN:
                    return navigate(`/${data.group}/companies`);

                case UserGroups.MANAGER:
                    return navigate(`/${data.group}/projects`);

                case UserGroups.EMPLOYEE:
                    return navigate(`/${data.group}/projects`);

                case UserGroups.DESIGNER:
                    return navigate(`/${data.group}/projects`);

                case UserGroups.EE_ENGINEER:
                    return navigate(`/ee-engineer/projects`);

                case UserGroups.PE_ENGINEER:
                    return navigate(`/pe-engineer/projects`);

                default:
                    return navigate(`/${data.group}/projects`);
            }
        }
    }, [isLoading]);

    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (isError) {
        return <div>Error: {error.message}</div>;
    }

    return null;
};
