import { Loader } from '@components/Loader/Loader';
import { ProjectsSidebar } from '@pages/projects/sidebar/ProjectsSidebar';
import { useGetTasksByProject } from '@queries/tasks.query';
import React from 'react';
import { useParams } from 'react-router';

export const ProjectsSidebarDetailsEdit = () => {
    const params = useParams();
    const projectId = params.id ?? '';

    const { status: tasksByProjectStatus, data: tasksByProject } =
        useGetTasksByProject(projectId);

    if (tasksByProjectStatus === 'pending') {
        return <Loader />;
    }

    return (
        <ProjectsSidebar tasks={tasksByProject} status={tasksByProjectStatus} />
    );
};
