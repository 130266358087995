import { ReactComponent as ArrowIcon } from '@assets/arrow.svg';
import { ReactComponent as LoginPageIcon } from '@assets/logo.svg';
import { ButtonGBI } from '@components/ButtonGBI/ButtonGBI';
import { SubmitGBI } from '@components/ButtonGBI/SubmitGBI';
import { Login } from '@components/LoginPage/Login';
import React, { useState } from 'react';
import { useNavigate } from 'react-router';

import './LoginPage.scss';

export const LoginPage = () => {
    const navigate = useNavigate();

    const [isLogin, setIsLogin] = useState(false);
    const [loginData, setLoginData] = useState({
        email: '',
        password: '',
    });

    const onForgotPassword = () => {
        navigate(`/forgot-password/?email=${loginData.email}`);
    };

    return (
        <div className="login-page">
            <div className="container-fluid">
                <div className="row">
                    <div className="login-form col-sm-12 col-md-6">
                        <form className="p-4">
                            <h1>Welcome</h1>
                            <h3>Have a great day!</h3>

                            <div className="my-3">
                                <label htmlFor="emailInput">E-mail</label>
                                <input
                                    type="email"
                                    className="form-control"
                                    id="emailInput"
                                    aria-describedby="emailHelp"
                                    placeholder=""
                                    value={loginData.email}
                                    onChange={e =>
                                        setLoginData({
                                            ...loginData,
                                            email: e.target.value,
                                        })
                                    }
                                />
                            </div>

                            <div className="my-3">
                                <label htmlFor="passwordInput">Password</label>
                                <input
                                    type="password"
                                    className="form-control"
                                    id="passwordInput"
                                    placeholder=""
                                    value={loginData.password}
                                    onChange={e =>
                                        setLoginData({
                                            ...loginData,
                                            password: e.target.value,
                                        })
                                    }
                                />
                            </div>

                            <div className="keep-logeged-in form-group mb-3 d-flex">
                                <input
                                    className="me-2"
                                    type="checkbox"
                                    id="keepLoggedIn"
                                />
                                <label htmlFor="keepLoggedIn">
                                    Keep me logged in
                                </label>
                            </div>

                            <SubmitGBI
                                type="button"
                                onClick={() => {
                                    setIsLogin(!isLogin);
                                }}
                                title=""
                                isLoading={isLogin}
                            >
                                {isLogin ? (
                                    'Logging in...'
                                ) : (
                                    <span>
                                        Sign in <ArrowIcon />
                                    </span>
                                )}
                            </SubmitGBI>
                            <ButtonGBI
                                variant="link"
                                onClick={() => onForgotPassword()}
                            >
                                I forgot my password
                            </ButtonGBI>
                            {isLogin && (
                                <Login
                                    email={loginData.email}
                                    password={loginData.password}
                                />
                            )}
                        </form>
                    </div>
                    <div className="background-image col-md-6 p-5">
                        <LoginPageIcon width={300} height={300} />
                    </div>
                </div>
            </div>
        </div>
    );
};
