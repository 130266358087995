// @ts-nocheck
import { ReactComponent as EyeIcon } from '@assets/eye.svg';
import { ButtonGBI } from '@components/ButtonGBI/ButtonGBI';
import { Column } from '@tanstack/table-core';
import classNames from 'classnames';
import React from 'react';

import './TableColumnFilter.scss';

interface ITableColumnFilterProps {
    options?: Column<any>[];
}

export const TableColumnFilter = ({ options }: ITableColumnFilterProps) => {
    const filterOptions = options.map(column => {
        return (
            <li
                key={column.id}
                className={classNames({
                    checked: column.getIsVisible(),
                })}
            >
                <label>
                    <input
                        {...{
                            type: 'checkbox',
                            checked: column.getIsVisible(),
                            onChange: column.getToggleVisibilityHandler(),
                        }}
                    />
                    {column.columnDef.filterTitle || column.id}
                </label>
            </li>
        );
    });

    return (
        <div className="filter-columns dropdown d-flex align-items-center justify-content-center">
            <ButtonGBI
                className="btn-select"
                disabledPadding
                data-bs-toggle="dropdown"
                aria-expanded="false"
            >
                <EyeIcon />
            </ButtonGBI>
            <ul className="dropdown-menu">{filterOptions}</ul>
        </div>
    );
};
