import { useGetLogin } from '@queries/login.query';
import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import { getErrorMessage, queryClient } from 'src/App';


type LoginProps = {
    email: string;
    password: string;
};

export const Login = ({ email, password }: LoginProps) => {
    const navigate = useNavigate();
    const { isLoading, isError, isSuccess, data, error } = useGetLogin({
        email: email.toLowerCase(),
        password,
    });

    useEffect(() => {
        if (isSuccess) {
            localStorage.removeItem('userGroup');
            localStorage.setItem('token', data?.data.idToken);
            localStorage.setItem('refreshToken', data?.data.refreshToken);

            toast('Logged in successfully', { type: 'success' });

            queryClient
                .invalidateQueries({ queryKey: ['userInfo'] })
                .then(() => {
                    navigate('/authenticate');
                });
        }

        if (isError) {
            // @ts-ignore
            toast(getErrorMessage(error), { type: 'error' });
        }
    }, [isLoading]);

    return null;
};
