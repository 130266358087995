import { RequiredIndicator } from '@components/Forms/components/shared/RequiredIndicator';
import { GetData, GetLabel } from '@components/Forms/helpers/GetLabelData';
import React from 'react';

import { configType, globalType, validationType } from '../../FormGenerator';


export const TextareaField = ({
    config: { name, title },
    global,
    validation,
    disabled,
    defaultData,
}: {
    config: configType;
    global?: globalType;
    validation?: validationType;
    disabled: boolean;
    defaultData: any;
}) => {
    if (disabled) {
        return (
            <div className="textarea-field form-group">
                <GetLabel title={title} global={global} />
                <GetData title={defaultData?.[name]} global={global} />
            </div>
        );
    }

    return (
        <div className="textarea-field form-group">
            <label htmlFor={name}>
                {title} <RequiredIndicator isRequired={validation?.required} />
            </label>
            <textarea
                name={name}
                id={name}
                className="form-control"
                disabled={disabled}
                defaultValue={defaultData?.[name]}
            />
        </div>
    );
};
