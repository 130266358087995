import { DeliverableActions } from '@components/Deliverables/DeliverableActions';
import { deliverableFormConfig } from '@components/Deliverables/DeliverableFormConfig';
import { FormGenerator } from '@components/Forms/FormGenerator';
import { TaskTypes, useGetTasksByProject } from '@queries/tasks.query';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';

import './Deliverable.scss';

export const Deliverable = ({ products }: { products?: any[] }) => {
    const params = useParams();
    const projectId = params.id ?? '';
    const availableProducts = products?.map(product => product.name);

    const { status: tasksByProjectStatus, data: tasksByProject } =
        useGetTasksByProject(projectId);

    const stamps = tasksByProject?.data?.filter(
        (task: any) =>
            task.type === TaskTypes.EE_STAMP ||
            task.type === TaskTypes.PE_STAMP ||
            task.type === TaskTypes.WET_STAMP
    );

    const plans = tasksByProject?.data?.filter(
        (task: any) => task.type === TaskTypes.DESIGN_PLANS
    );

    const [isOldFilesShown, setIsOldFilesShown] = useState(false);
    const [stampId, setStampId] = useState(stamps?.[0]?.id);
    const [planId, setPlanId] = useState(plans?.[0]?.id);

    const handleCheckboxChange = (event: any) => {
        setIsOldFilesShown(event.target.checked);
    };

    const stampType =
        stamps?.length > 0 &&
        stamps?.filter((task: any) => task.id === stampId)[0]?.type;

    const stampsData = stamps?.map((stamp: any) => ({
        id: stamp.id,
        valueToShow: stamp.title,
    }));

    const plansData = plans?.map((plan: any) => ({
        id: plan.id,
        valueToShow: plan.title,
    }));

    useEffect(() => {
        if (stamps?.length > 0) {
            setStampId(stamps[0].id);
        }
        if (plans?.length > 0) {
            setPlanId(plans[0].id);
        }
    }, [stamps?.length, plans?.length]);

    return (
        <div className="details edit deliverable-section">
            <DeliverableActions handleCheckboxChange={handleCheckboxChange} />

            <FormGenerator
                withDetailsWrapper={false}
                settings={deliverableFormConfig(
                    availableProducts,
                    isOldFilesShown,
                    planId,
                    stampId,
                    stampType,
                    stampsData,
                    setStampId,
                    plansData,
                    setPlanId,
                    projectId
                )}
                defaultData={{
                    stampTask: stampId,
                    planTask: planId,
                }}
            />
        </div>
    );
};
