import { FilesList } from '@components/FilesUpload/FilesList';
import { GetData, GetLabel } from '@components/Forms/helpers/GetLabelData';
import { GuidelinesDetails } from '@components/Guidelines/GuidelinesDetails';
import { Loader } from '@components/Loader/Loader';
import { NavigationHeader } from '@components/NavigationHeader/NavigationHeader';
import { Tabs } from '@components/Tabs/Tabs';
import { useGetProjects } from '@queries/projects.query';
import {
    TaskStatuses,
    useCloseTask,
    useDeleteTask,
    useGetTask,
} from '@queries/tasks.query';
import { getGroup } from '@utils/getGroup';
import classNames from 'classnames';
import React from 'react';
import { useNavigate, useParams } from 'react-router';
import { toast } from 'react-toastify';

import './Tasks.scss';
import { taskTypes } from './TasksCreate';
import { TasksSidebar } from './TasksSidebar';
import { TasksNavigation } from './header/TasksNavigation';
import moment from "moment/moment";

export const TasksDetails = () => {
    const group = localStorage.getItem('userGroup');
    const isAdmin = group === 'admin';
    const params = useParams();
    const navigate = useNavigate();
    const taskId = params.id ?? '';
    const { status, data } = useGetTask(taskId);
    const closeTaskMutation = useCloseTask();

    const { data: projectsData } = useGetProjects();
    const deleteTaskMutation = useDeleteTask();

    const projectsSelect = projectsData?.data?.map((project: any) => {
        return {
            id: project.id,
            valueToShow: project.name,
        };
    });

    const handleDelete = () => {
        deleteTaskMutation.mutate(taskId, {
            onSuccess: () => {
                navigate(`/${getGroup()}/tasks`);
            },
        });
    };

    const handleStatusChange = () => {
        closeTaskMutation.mutate(
            {
                taskId: taskId,
                status: TaskStatuses.COMPLETED,
            },
            {
                onSuccess: () => {
                    toast('Task closed successfully', { type: 'success' });
                },
            }
        );
    };

    const getFormattedDueDate = () => {
        const date = new Date(data.dueDate);
        return `${date.getUTCFullYear()}/${
            date.getUTCMonth() + 1
        }/${date.getUTCDate()}`;
    }

    const tabsConfig = [
        {
            title: 'Overview',
            content: (
                <form>
                    {status === 'success' ? (
                        <>
                            <div
                                className={classNames('form-generator', {
                                    details: true,
                                })}
                            >
                                <div
                                    className={classNames('field-wrapper', {})}
                                >
                                    <h3>General details</h3>
                                    <div className="textarea-field form-group">
                                        <GetLabel
                                            title="Task's title"
                                            global={{
                                                viewMode: {
                                                    boldLabel: true,
                                                },
                                            }}
                                        />
                                        <GetData
                                            className={'pb-3'}
                                            title={data.title}
                                            global={{
                                                viewMode: {
                                                    boldLabel: true,
                                                    featuredData: true,
                                                },
                                            }}
                                        />
                                        <GetLabel
                                            title="Task's description"
                                            global={{
                                                viewMode: {
                                                    boldLabel: true,
                                                },
                                            }}
                                        />
                                        <GetData
                                            className={'pb-3'}
                                            title={data.description}
                                            global={{
                                                viewMode: {
                                                    boldLabel: true,
                                                    featuredData: true,
                                                },
                                            }}
                                        />
                                        <GetLabel
                                            title="Task's type"
                                            global={{
                                                viewMode: {
                                                    boldLabel: true,
                                                },
                                            }}
                                        />
                                        <GetData
                                            className={'pb-3'}
                                            title={
                                                taskTypes.find(
                                                    type =>
                                                        type.id === data.type
                                                )?.valueToShow ?? ''
                                            }
                                            global={{
                                                viewMode: {
                                                    boldLabel: true,
                                                    featuredData: true,
                                                },
                                            }}
                                        />
                                        {data.blockedBy && (
                                            <>
                                                <GetLabel
                                                    title="Blocked by task"
                                                    global={{
                                                        viewMode: {
                                                            boldLabel: true,
                                                        },
                                                    }}
                                                />
                                                <a
                                                    href={`/${group}/tasks/${data?.blockedBy.id}`}
                                                >
                                                    <GetData
                                                        className={'pb-3'}
                                                        title={
                                                            data.blockedBy.title
                                                        }
                                                        global={{
                                                            viewMode: {
                                                                boldLabel: true,
                                                                featuredData:
                                                                    true,
                                                            },
                                                        }}
                                                    />
                                                </a>
                                            </>
                                        )}
                                        <GetLabel
                                            title="Project"
                                            global={{
                                                viewMode: {
                                                    boldLabel: true,
                                                },
                                            }}
                                        />
                                        <a
                                            href={`/${group}/projects/${data?.project?.id}`}
                                        >
                                            <GetData
                                                className={'pb-3'}
                                                title={data.project.name}
                                                global={{
                                                    viewMode: {
                                                        boldLabel: true,
                                                        featuredData: true,
                                                    },
                                                }}
                                            />
                                        </a>
                                        <GetLabel
                                            title="Due date"
                                            global={{
                                                viewMode: {
                                                    boldLabel: true,
                                                },
                                            }}
                                        />
                                        <GetData
                                            className={'pb-3'}
                                            title={getFormattedDueDate()}
                                            global={{
                                                viewMode: {
                                                    boldLabel: true,
                                                    featuredData: true,
                                                },
                                            }}
                                        />
                                        <div className="files-upload form-group">
                                            <FilesList
                                                resourceId={data.id}
                                                title={'Files'}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    ) : (
                        <Loader />
                    )}
                </form>
            ),
        },
        {
            title: 'Guidelines',
            content: data?.project?.id ? (
                <GuidelinesDetails
                    projectId={data?.project?.id}
                    showTitle={false}
                />
            ) : (
                <div>No guidelines selected</div>
            ),
        },
    ];

    return (
        <div className="tasks-details content-wrapper with-header">
            <div className="row">
                <div className="col-lg-6">
                    <Tabs config={tabsConfig} />

                    <NavigationHeader
                        detailTitle={data?.name ?? "Task's details"}
                    >
                        <TasksNavigation
                            defaultStatus={data?.status}
                            handleDelete={isAdmin ? handleDelete : undefined}
                            handleCloseTask={handleStatusChange}
                        />
                    </NavigationHeader>
                </div>
                {data && (
                    <TasksSidebar
                        assignee={{
                            id: data?.assignee ? data?.assignee?.id : null,
                            valueToShow: data?.assignee
                                ? `${data?.assignee?.firstName} ${data?.assignee?.lastName}`
                                : 'Not Assigned',
                        }}
                        commentId={data?.project?.id}
                    />
                )}
            </div>
        </div>
    );
};
