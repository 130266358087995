import { JurisdictionTypes } from '@queries/jurisdiction.query';
import { getGroup } from '@utils/getGroup';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router';

import './Jurisdiction.scss';

export const JurisdictionCreateRedirect = () => {
    const group = localStorage.getItem('userGroup');
    const isManager = group === 'manager';
    const isDesigner = group === 'designer';
    const params = useParams();
    const navigate = useNavigate();
    const jurisdictionType =
        isManager || isDesigner
            ? JurisdictionTypes.COMPANY
            : params.type ?? 'ahj';

    useEffect(() => {
        navigate(`/${getGroup()}/jurisdiction/${jurisdictionType}/create`);
    }, [jurisdictionType]);
};
