import { Users } from '@pages/users/Users';
import { UsersCreate } from '@pages/users/UsersCreate';
import { UsersDetails } from '@pages/users/UsersDetails';
import { UsersEdit } from '@pages/users/UsersEdit';

export const usersAdminRoutes = [
    {
        path: '/admin/users',
        Component: Users,
    },
    {
        path: '/admin/users/:id',
        Component: UsersDetails,
    },
    {
        path: '/admin/users/create',
        Component: UsersCreate,
    },
    {
        path: '/admin/users/:id/edit',
        Component: UsersEdit,
    },
];
