import { getStatus, getStatusClasses } from '@utils/statuses';
import classNames from 'classnames';
import React from 'react';

import './TableBadge.scss';

interface ITableBadgeProps {
    title: string;
    isSmall?: boolean;
}

export const TableBadge = ({ title, isSmall }: ITableBadgeProps) => {
    const titleToRender = isSmall
        ? getStatus(title).charAt(0)
        : getStatus(title);

    return (
        <div
            className={classNames(
                'table-badge status',
                getStatusClasses(title),
                {
                    'table-badge--small': isSmall,
                }
            )}
        >
            {titleToRender}
        </div>
    );
};
