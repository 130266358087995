import fetchGBI from '@queries/fetchGBI';
import { DefaultBaseURL } from '@queries/urlHelpers';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';

export interface INewComment {
    text: string;
    userId: string;
}

export interface TaskComments extends INewComment {
    taskId: string;
}

export interface ProjectComments extends INewComment {
    projectId: string;
}

export const useCreateComment = () => {
    const client = useQueryClient();

    return useMutation({
        mutationFn: async (newComment: TaskComments | ProjectComments) => {
            const { data } = await axios.post(
                DefaultBaseURL + '/comments',
                newComment,
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem(
                            'token'
                        )}`,
                    },
                }
            );
            return {
                data,
            };
        },
        onSuccess: () => {
            return client.invalidateQueries({ queryKey: ['comments'] });
        },
    });
};

export const useGetCommentsByTaskId = (taskId: string) => {
    return useQuery({
        queryKey: ['comments', taskId],
        queryFn: () => fetchGBI(DefaultBaseURL + `/comments/task/${taskId}`),
    });
};

export const useGetCommentsByProjectId = (taskId: string) => {
    return useQuery({
        queryKey: ['comments', taskId],
        queryFn: () => fetchGBI(DefaultBaseURL + `/comments/project/${taskId}`),
        enabled: !!taskId,
    });
};
