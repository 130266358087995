import { Loader } from '@components/Loader/Loader';
import { OtherDetails } from '@pages/jurisdiction/Other/OtherDetails';
import { JurisdictionTypes } from '@queries/jurisdiction.query';
import React from 'react';
import { useParams } from 'react-router';

import { AhjDetails } from './AHJ/AhjDetails';
import { CompanyDetails } from './Company/CompanyDetails';
import './Jurisdiction.scss';
import { UtilityDetails } from './Utility/UtilityDetails';

export const JurisdictionDetails = () => {
    const params = useParams();
    const jurisdictionType = params.type ?? '';

    switch (jurisdictionType) {
        case JurisdictionTypes.AHJ:
            return <AhjDetails />;

        case JurisdictionTypes.UTILITY:
            return <UtilityDetails />;

        case JurisdictionTypes.COMPANY:
            return <CompanyDetails />;

        case JurisdictionTypes.OTHER:
            return <OtherDetails />;
    }

    return <Loader />;
};
