import { configType, validationType } from '@components/Forms/FormGenerator';
import { SelectedElement } from '@components/Forms/components/searchableDropdown/SearchableDropdown';
import { RequiredIndicator } from '@components/Forms/components/shared/RequiredIndicator';
import fetchGBI from '@queries/fetchGBI';
import { AdminBaseURL, DefaultBaseURL } from '@queries/urlHelpers';
import { useQuery } from '@tanstack/react-query';
import { getGroup } from '@utils/getGroup';
import { useDebounce } from 'ahooks';
import React, { useState } from 'react';

import './SearchableDropdownForForm.scss';

export const SearchableDropdownForForm = ({
    config: { name, title, selectData, onChange, type, url },
    valueToShowKey = 'name',
    dataSourceUrlPath,
    validation,
}: {
    config: configType;
    valueToShowKey?: string;
    dataSourceUrlPath: string;
    validation?: validationType;
}) => {
    const [query, setQuery] = useState('');
    const [isQueryEnabled, setIsQueryEnabled] = useState<boolean>(false);
    const [selectedValue, setSelectedValue] = useState<SelectedElement>({
        id: null,
        valueToShow: 'Select',
    });
    const debouncedQuery = useDebounce(query, { wait: 500 });
    const group = getGroup();

    const { data, isFetching } = useQuery({
        queryKey: [debouncedQuery],
        select: data => {
            return data.data.map((item: any) => ({
                id: item.id,
                valueToShow: item[valueToShowKey],
            }));
        },
        queryFn: () =>
            fetchGBI(
                (group === 'admin' ? AdminBaseURL : DefaultBaseURL) +
                    `/${dataSourceUrlPath}?search=${query}`
            ),
    });

    const handleInputChange = (event: any) => {
        setQuery(event.target.value);
        setIsQueryEnabled(true);
    };

    const handleSelect = (item: SelectedElement) => {
        onChange?.(item.id);
        setQuery(item.valueToShow);
        setSelectedValue({ ...item });
        setIsQueryEnabled(false);
    };

    return (
        <div className="dropdown-field form-group">
            <label htmlFor={title}>
                {title} <RequiredIndicator isRequired={validation?.required} />
            </label>
            <div className="dropdown">
                <button
                    className="form-control dropdown-toggle text-start"
                    type="button"
                    id="dropdownMenuButton"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                >
                    {selectedValue?.valueToShow}
                </button>
                <div
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuButton"
                >
                    <input
                        type="text"
                        onChange={handleInputChange}
                        className="form-control
                            dropdown-header
                              border-0 border-bottom
                              shadow-none mb-2"
                        placeholder="Search..."
                    />
                    {data?.map((result: any) => (
                        <a
                            key={result.id}
                            className="dropdown-item"
                            onClick={() => handleSelect(result)}
                        >
                            {result.valueToShow}
                        </a>
                    ))}
                </div>
            </div>
        </div>
    );
};
