import React from 'react';

import './SeparatedLine.scss';

export const SeparateLine = ({ title }: { title?: string }) => {
    return (
        <div className="separated-line">
            <hr />
            {title && <h6>{title}</h6>}
        </div>
    );
};
