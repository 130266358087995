import { useGetUserInfo } from '@queries/login.query';
import { getGroup } from '@utils/getGroup';
import { useEffect } from 'react';
import { useNavigate } from 'react-router';

export const Redirect = () => {
    const navigate = useNavigate();
    const { data: currentUserData } = useGetUserInfo();

    const regex = /\/(.*?)\/(.*)/g;
    const pathname = window.location.pathname;
    const match = regex.exec(pathname);

    useEffect(() => {
        if (
            currentUserData &&
            getGroup(currentUserData?.group) !== match?.[1]
        ) {
            navigate(`/${getGroup(currentUserData.group)}/${match?.[2]}`);
        }
    }, [pathname, currentUserData?.group]);

    return null;
};
