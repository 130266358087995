import { Redirect } from '@components/LoginPage/Redirect';
import { SidebarMenu } from '@components/SidebarMenu/SidebarMenu';
import { useGetUserInfo } from '@queries/login.query';
import React, { createContext } from 'react';
import { Outlet } from 'react-router-dom';

export const UserContext = createContext({} as any);

export const RootWithSidebar = () => {
    const { data: currentUserData } = useGetUserInfo();

    return (
        <UserContext.Provider
            value={{
                user: currentUserData,
            }}
        >
            <Redirect />
            <div className="root-page">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-3 col-lg-2 sidebar-menu p-3">
                            <SidebarMenu />
                        </div>

                        <div className="detail-wrapper col-9 col-lg-10 p-3">
                            <div id="detail" className="detail p-4">
                                <Outlet />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </UserContext.Provider>
    );
};
