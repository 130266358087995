import { ReactComponent as CompaniesIcon } from '@assets/companiesIcon.svg';
import { ReactComponent as JurisdictionIcon } from '@assets/jurisdictionIcon.svg';
import { ReactComponent as ProjectsIcon } from '@assets/projectsIcon.svg';
import { ReactComponent as SettingsIcon } from '@assets/settingsIcon.svg';
import { ReactComponent as TasksIcon } from '@assets/tasksIcon.svg';
import { ReactComponent as UsersIcon } from '@assets/usersIcon.svg';


export const adminMenu = [
    {
        name: 'Companies',
        icon: CompaniesIcon,
        path: '/admin/companies',
    },
    {
        name: 'Projects',
        icon: ProjectsIcon,
        path: '/admin/projects',
    },
    {
        name: 'Tasks',
        icon: TasksIcon,
        path: '/admin/tasks',
    },
    {
        name: 'Jurisdiction',
        icon: JurisdictionIcon,
        path: '/admin/jurisdiction',
    },
    // Invoices are disabled for now
    // {
    //     name: 'Invoices',
    //     icon: InvoicesIcon,
    //     path: '/admin/invoices',
    // },
    {
        name: 'Users',
        icon: UsersIcon,
        path: '/admin/users',
    },
    {
        name: 'Settings',
        icon: SettingsIcon,
        path: '/admin/settings/edit',
    },
];
