import { useEditCompany } from '@queries/companies.query';
import { useQueryClient } from '@tanstack/react-query';
import { getStatus, getStatusClasses } from '@utils/statuses';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';

import './StatusDropdown.scss';


export const StatusDropdown = ({ defaultValue }: { defaultValue?: any }) => {
    const client = useQueryClient();
    const [status, setStatus] = useState(defaultValue);
    const params = useParams();
    const companiesMutation = useEditCompany(params.id ?? '');

    const statusToRender = getStatus(defaultValue);

    const onSubmit = () => {
        return companiesMutation.mutate(
            {
                status,
            },
            {
                onSuccess: () => {
                    toast.success('Company status changed successfully');
                    return client.invalidateQueries({
                        queryKey: ['companies'],
                    });
                },
            }
        );
    };

    useEffect(() => {
        if (status !== defaultValue) {
            onSubmit();
        }
    }, [status]);

    return (
        <div className="status-change-dropdown">
            <div className="dropdown">
                <button
                    className={classNames(
                        'btn dropdown-toggle',
                        getStatusClasses(defaultValue)
                    )}
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                >
                    {statusToRender}
                </button>
                <ul className="dropdown-menu">
                    <li>
                        <span
                            role="button"
                            tabIndex={0}
                            className="dropdown-item"
                            onClick={() => setStatus('in_active')}
                        >
                            Inactive
                        </span>
                    </li>
                    <li>
                        <span
                            role="button"
                            tabIndex={0}
                            className="dropdown-item"
                            onClick={() => setStatus('active')}
                        >
                            Active
                        </span>
                    </li>
                </ul>
            </div>
        </div>
    );
};
