export const getOppositeDirection = (direction: string) => {
    switch (direction) {
        case 'DESC':
            return 'ASC';
        case 'ASC':
            return 'DESC';

        default:
            return 'DESC';
    }
};

export const handleSort = (
    accessorKey: string,
    urlState: any,
    setUrlState: any
) => {
    const urlStateArray = urlState.sortBy?.split(':');

    let sortDirection =
        urlStateArray !== undefined && getOppositeDirection(urlStateArray[1]);
    let sortingBy = `${accessorKey}:DESC`;

    if (sortDirection) {
        sortingBy = `${accessorKey}:${sortDirection}`;
    }

    return setUrlState({ sortBy: sortingBy });
};
