import { FieldType, Type, settingsType } from '@components/Forms/FormGenerator';

export const guidelinesSelectFormConfig = (
    guidelinesData: any,
    setSelectedGuideline: any,
    otherGuidelinesData: any,
    setSelectedOtherGuideline: any,
    ahjGuidelines: any,
    setSelectedAhjGuideline: any,
    utilityGuidelines: any,
    setSelectedUtilityGuideline: any
): settingsType[] => [
    {
        config: {
            fieldType: FieldType.Select,
            type: Type.Text,
            name: 'companyRequirementId',
            title: 'Company',
            selectData: guidelinesData,
            onChange: (data: any) => {
                setSelectedGuideline?.(data);
            },
        },
        global: {
            viewMode: {
                boldLabel: true,
                featuredData: true,
            },
        },
    },
    {
        config: {
            fieldType: FieldType.Select,
            type: Type.Text,
            name: 'otherRequirementId',
            title: 'Other',
            selectData: otherGuidelinesData,
            onChange: (data: any) => {
                setSelectedOtherGuideline?.(data);
            },
        },
        global: {
            viewMode: {
                boldLabel: true,
                featuredData: true,
            },
        },
    },
    {
        config: {
            fieldType: FieldType.Select,
            type: Type.Text,
            name: 'utilityRequirementId',
            title: 'Utility',
            selectData: utilityGuidelines,
            onChange: (data: any) => {
                setSelectedUtilityGuideline?.(data);
            },
        },
        global: {
            viewMode: {
                boldLabel: true,
                featuredData: true,
            },
        },
    },
    {
        config: {
            fieldType: FieldType.Select,
            type: Type.Text,
            name: 'ahjRequirementId',
            title: 'AHJ',
            selectData: ahjGuidelines,
            onChange: (data: any) => {
                setSelectedAhjGuideline?.(data);
            },
        },
        global: {
            viewMode: {
                boldLabel: true,
                featuredData: true,
            },
        },
    },
];
