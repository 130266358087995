import classNames from 'classnames';
import React from 'react';
import Button from 'react-bootstrap/Button';

export type ButtonVariant =
    | 'primary'
    | 'primary-opposite'
    | 'secondary'
    | 'success'
    | 'danger'
    | 'warning'
    | 'info'
    | 'light'
    | 'dark'
    | 'link'
    | 'inactive'
    | 'menu';

interface IButtonProps {
    variant?: ButtonVariant;
    children: React.ReactNode;
    size?: 'sm' | 'lg';
    onClick?: () => void;
    className?: string;
    disabledPadding?: boolean;
    type?: 'submit' | 'button' | 'reset';
    disabled?: boolean;
}

export const ButtonGBI = ({
    variant = 'primary',
    size = 'sm',
    onClick,
    className,
    children,
    disabledPadding = false,
    ...props
}: IButtonProps) => {
    return (
        <Button
            variant={variant}
            size={size}
            onClick={onClick}
            className={classNames(className, {
                'px-3 py-2': !disabledPadding,
            })}
            {...props}
        >
            {children}
        </Button>
    );
};
