import {
    configType,
    globalType,
    validationType,
} from '@components/Forms/FormGenerator';
import { RequiredIndicator } from '@components/Forms/components/shared/RequiredIndicator';
import { GetData, GetLabel } from '@components/Forms/helpers/GetLabelData';
import classNames from 'classnames';
import moment from 'moment';
import React, { useState } from 'react';

export const InputField = ({
    config: { name, title, type, subType, url },
    validation,
    global,
    disabled,
    defaultData,
}: {
    config: configType;
    validation?: validationType;
    global?: globalType;
    disabled: boolean;
    defaultData: any;
}) => {
    const getValue = (value: any) => {
        return type === 'datetime-local'
            ? moment(value).format('YYYY-MM-DDTkk:mm')
            : value;
    };

    const [value, setValue] = useState(getValue(defaultData?.[name]));
    const viewMode = global?.viewMode;
    const editMode = global?.editMode;
    // View mode === disabled
    if (disabled || editMode?.disabled) {
        // hide field from View mode because option 'hidden' is set to true
        if (global?.viewMode?.hidden) {
            return null;
        }
        let defaultValue = defaultData?.[name];
        if (type === 'datetime-local') {
            const date = new Date(defaultData?.[name]);
            // todo dates to check. maybe move it to separate function
            defaultValue = `${date.getUTCFullYear()}/${
                date.getUTCMonth() + 1
            }/${date.getUTCDate()}`;
        }

        if (type === 'link') {
            return (
                <>
                    <GetLabel title={title} global={global} />
                    <a href={url}>
                        <GetData title={defaultValue} global={global} />
                    </a>
                </>
            );
        }

        if (type === 'number' && subType === 'currency') {
            defaultValue = Number(defaultData?.[name]);
            const nf = new Intl.NumberFormat('en-US');
            defaultValue = nf.format(defaultValue);

            // todo check if we need to store default '$' prefix to be able to replace it quickly based on other factors
            viewMode && (viewMode.prefixData = '$');
        }

        if (subType === 'fullName') {
            defaultValue =
                defaultData?.['firstName'] + ' ' + defaultData?.['lastName'];
        }

        return (
            <div className="input-field form-group">
                <GetLabel title={title} global={global} />
                <GetData title={defaultValue} global={global} />
            </div>
        );
    }

    if (subType === 'fullName') {
        return (
            <div className="input-field form-group">
                <div className="row">
                    <div className="col-lg-6">
                        <label htmlFor="firstName">First name</label>
                        <input
                            type="text"
                            className="form-control"
                            id="firstName"
                            disabled={disabled}
                            defaultValue={defaultData?.firstName}
                        />
                    </div>

                    <div className="col-lg-6">
                        <label htmlFor="lastName">Last name</label>
                        <input
                            type="text"
                            className="form-control"
                            id="lastName"
                            disabled={disabled}
                            defaultValue={defaultData?.lastName}
                        />
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="input-field form-group">
            <label htmlFor={name}>
                {title} <RequiredIndicator isRequired={validation?.required} />
            </label>
            <input
                required={validation?.required}
                type={type}
                className={classNames('form-control')}
                id={name}
                disabled={disabled}
                defaultValue={value}
                onChange={e => {
                    const inputValue = e.target.value;
                    setValue(getValue(inputValue));
                }}
                onInvalid={e => {
                    // This is to prevent the form from displaying the default validation message
                    e.preventDefault();
                }}
                minLength={validation?.minLength}
                maxLength={validation?.maxLength}
                pattern={validation?.pattern}
            />
        </div>
    );
};
