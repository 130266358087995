// @ts-nocheck
import { ReactComponent as EmptyTasksIcon } from '@assets/emptyTasksIcon.svg';
import { ButtonGBI } from '@components/ButtonGBI/ButtonGBI';
import { EmptyPage } from '@components/EmptyPage/EmptyPage';
import { TableBadge } from '@components/Table/TableBadge';
import { TableHeaderActions } from '@components/Table/TableHeaderActions';
import {
    ColumnDef,
    ColumnOrderState,
    flexRender,
    getCoreRowModel,
    useReactTable,
} from '@tanstack/react-table';
import { createCSVColumns, createCSVData } from '@utils/getCSV';
import classNames from 'classnames';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { NavLink } from 'react-router-dom';

import './TanStackTable.scss';

type TanStackTableProps = {
    tableSetup: ColumnDef<any>[];
    propData?: any;
    createButtonTitle?: string;
    isCreateButtonEnabled?: boolean;
    linkTo?: string;
    projectIdForTaskCreation?: string;
    useType?: boolean;
    isDropdownEnabled?: boolean;
    isFooterEnabled?: boolean;
    dropdownFiltersFor?: dropdownFiltersFor;
    className?: string;
    tableHeaderActions?: boolean;
    isMultiselect?: boolean;
    isRowClickable?: boolean;
    CSVColumns?: any;
    isCSVButtonEnabled?: boolean;
};
export const TanStackTable = ({
    tableSetup,
    propData,
    createButtonTitle = 'Create new',
    isCreateButtonEnabled = true,
    linkTo,
    useType = false,
    isDropdownEnabled = true,
    isFooterEnabled = true,
    dropdownFiltersFor,
    className,
    tableHeaderActions = true,
    isMultiselect = false,
    isRowClickable = true,
    CSVColumns,
    isCSVButtonEnabled = false,
    projectIdForTaskCreation,
}: TanStackTableProps) => {
    const navigate = useNavigate();
    const [data, setData] = React.useState(propData);
    const [columns] = React.useState(() => [...tableSetup]);
    useEffect(() => {
        setData(propData);
    }, [propData]);

    const [columnVisibility, setColumnVisibility] = React.useState({
        id: false,
    });
    const [columnOrder, setColumnOrder] = React.useState<ColumnOrderState>([]);

    const table = useReactTable({
        data,
        columns,
        state: {
            columnVisibility,
            columnOrder,
        },
        onColumnVisibilityChange: setColumnVisibility,
        onColumnOrderChange: setColumnOrder,
        getCoreRowModel: getCoreRowModel(),
        debugTable: false,
        debugHeaders: false,
        debugColumns: false,
    });

    const navigateToDetails = (id: string, type?: string) => {
        if (useType && type) {
            return navigate(`${linkTo}/${type}/${id}`);
        }

        if (id.length >= 26) {
            return navigate(`${linkTo}/${id}`);
        }

        return navigate(linkTo);
    };

    // CSV export
    const CSVData = createCSVData(propData);
    const CSVColumnsT = CSVColumns || createCSVColumns(propData);

    return (
        <div
            className={classNames('gbi-table', {
                [className]: className,
            })}
        >
            {tableHeaderActions && (
                <TableHeaderActions
                    columns={table.getAllLeafColumns()}
                    buttonTitle={createButtonTitle}
                    isCreateButtonEnabled={isCreateButtonEnabled}
                    onButtonTo={linkTo + '/create'}
                    projectIdForTaskCreation={projectIdForTaskCreation}
                    isDropdownEnabled={isDropdownEnabled}
                    dropdownFiltersFor={dropdownFiltersFor}
                    isMultiselect={isMultiselect}
                    CSVColumns={CSVColumnsT}
                    CSVData={CSVData}
                    isCSVButtonEnabled={isCSVButtonEnabled}
                />
            )}

            {data?.length > 0 ? (
                <div className="table-wrapper">
                    <table className="table-content table">
                        <thead>
                            {table.getHeaderGroups().map(headerGroup => (
                                <tr key={headerGroup.id}>
                                    {headerGroup.headers.map(header => {
                                        return (
                                            <th
                                                key={header.id}
                                                colSpan={header.colSpan}
                                                style={{
                                                    width:
                                                        header.column.columnDef
                                                            .size + 'px',
                                                    maxWidth:
                                                        header.column.columnDef
                                                            .maxSize + 'px',
                                                    minWidth:
                                                        header.column.columnDef
                                                            .minSize + 'px',
                                                }}
                                            >
                                                {header.isPlaceholder
                                                    ? null
                                                    : flexRender(
                                                          header.column
                                                              .columnDef.header,
                                                          header.getContext()
                                                      )}
                                            </th>
                                        );
                                    })}
                                </tr>
                            ))}
                        </thead>
                        <tbody>
                            {table.getRowModel().rows.map(row => (
                                <tr
                                    key={row.id}
                                    className={classNames({
                                        'is-interactive': isRowClickable,
                                    })}
                                >
                                    {row.getVisibleCells().map(cell => {
                                        if (cell.column.id === 'status') {
                                            const isSmall =
                                                columns.find(
                                                    column =>
                                                        column.accessorKey ===
                                                        'status'
                                                ).cellType === 'small';

                                            return (
                                                <td key={cell.id}>
                                                    <TableBadge
                                                        title={cell.getValue()}
                                                        isSmall={isSmall}
                                                    />
                                                </td>
                                            );
                                        }

                                        return (
                                            <td
                                                key={cell.id}
                                                className={classNames({
                                                    'cursor-pointer':
                                                        isRowClickable,
                                                })}
                                                onClick={() =>
                                                    cell.column.id !==
                                                        'actions' &&
                                                    isRowClickable &&
                                                    navigateToDetails(
                                                        row.original.id,
                                                        row.original.type
                                                    )
                                                }
                                            >
                                                {flexRender(
                                                    cell.column.columnDef.cell,
                                                    cell.getContext()
                                                )}
                                            </td>
                                        );
                                    })}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            ) : (
                <EmptyPage
                    title="No matching found - please try adjust your search and/or filter criteria"
                    icon={<EmptyTasksIcon />}
                />
            )}

            {isFooterEnabled && (
                <div className="table-footer">
                    {isCreateButtonEnabled && (
                        <NavLink
                            className="create-new-button"
                            to={linkTo + '/create'}
                        >
                            <ButtonGBI>{createButtonTitle}</ButtonGBI>
                        </NavLink>
                    )}
                </div>
            )}
        </div>
    );
};
